import { Paper } from "@mui/material"
import PaymentCom from "../../Componants/Payment/Payment"

function Payment () {
    return (
        <Paper sx={{padding: '20px'}}>
        <PaymentCom />
        </Paper>
    )
}

export default Payment