import { Box } from "@mui/material"
import Firstpart from "./firstpart"
import Scopart from "./Scopart"
import Thirdpart from "./Thirdpart"


function ManCode ({name, date, benchmark, referencess, answers, isBenchMark, lang}) {
    return (
        <Box width='100%'>
            <Firstpart  lang={lang} isBenchMark={isBenchMark} name={name} date={date} benchmark={benchmark}  answers={answers} referencess={referencess}/>
            <Scopart    lang={lang} isBenchMark={isBenchMark} name={name} date={date} benchmark={benchmark} answers={answers} referencess={referencess} />
            <Thirdpart  lang={lang} isBenchMark={isBenchMark} name={name} date={date} benchmark={benchmark}  answers={answers} referencess={referencess} />
        </Box>
    )
}

export default ManCode