import { Box, Button, Pagination, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Checkbox, Grid2, DialogActions, DialogContent, Dialog, Menu, MenuItem, Select, Snackbar, TextField, DialogTitle } from "@mui/material"; 
import AddParticipant from "./Forms/AddParticipant";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import AddPartisipantFile from "./Forms/AddPartisipantFile";
import { appContext } from "../../../../../App";
import Swal from "sweetalert2";
import SendIcon from '@mui/icons-material/Send';
import AddIcon from '@mui/icons-material/Add';
import BorderHorizontalIcon from '@mui/icons-material/BorderHorizontal';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import NotificationsIcon from '@mui/icons-material/Notifications';
import * as XLSX from "xlsx";
import BigChips from "../../Component/BigChips/Index";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import ApiContext from "../../Context/context";
function MangeInd({ companyId, companyName }) {
  const {
    orders,
    employees,
    sentEnrollments,
    companies,
    positions,
    packages,
    addOns,
    refetchData
  } = useContext(ApiContext);

    const [open, setOpen] = useState(false);
    const [openFile, setOpenFile] = useState(false);
    const [formOpen, setFormOpen] = useState(false);
    const [userId, setUserId] = useState(null);
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [isHavePackege, setIsHavePackege] = useState();
    const [selectedUser, setSelectedUser] = useState();
    const [allSelected, setAllSelected] = useState(false);

    // Slice the employees data based on the current page
    const currentEmployees = employees
        .filter(user => user.company_id === companyId );

        const handleSelectAll = () => {
          if (allSelected) {
            setSelectedEmployees([]);
          } else {
            const ids = employees
              .filter((user) => companyId === user.company_id )
              .map((user) => user.id);
            setSelectedEmployees(ids);
          }
          setAllSelected(!allSelected);
        };
        
        const handleSelectRow = (id) => {
          
          if (selectedEmployees.includes(id)) {
            setSelectedEmployees(selectedEmployees.filter((empId) => empId !== id));
          } else {
            setSelectedEmployees([...selectedEmployees, id]);
          }
        };


        const onSubmit = async (name, company_id, managed_by, updated_at) => {
          try {
            // Fetch the existing benchmark payload with id 4
            const existingBenchmarkResponse = await axios.get('https://api.twindix.com/benchmark-questions/4', {
              headers: {
                Authorization: "Bearer " + localStorage.token,
                "Content-Type": "application/json",
              },
            });
        
            console.log("Existing Benchmark Response:", existingBenchmarkResponse.data.payload.benchmark_questions);
        
            if (existingBenchmarkResponse.data.payload && Array.isArray(existingBenchmarkResponse.data.payload.benchmark_questions)) {
              // Get the highest id for generating unique ids
              const highestId = existingBenchmarkResponse.data.payload.benchmark_questions.reduce(
                (maxId, question) => Math.max(maxId, question.Reminder.reduce((max, reminder) => Math.max(max, reminder.id), 0)),
                0
              );
        
              // Create the updated payload by preserving the existing data and appending the new one
              const updatedBenchmarkQuestions = existingBenchmarkResponse.data.payload.benchmark_questions.map((question) => {
                return {
                  ...question,
                  Reminder: [
                    ...question.Reminder,
                    ...selectedEmployees.map((empId) => {
                      // Find employee details from selectedEmployees
                      const employee = employees?.find((emp) => emp.id === empId);
                      return {
                        id: highestId + 1, // Generate new id dynamically
                        name: employee ? employee.name : '',
                        userId: empId,
                        company_id : employee ? employee.company_id : '',
                        managed_by : employee ? employee.managed_by : '',
                        updated_at : employee ? employee.updated_at : '',
                        
                      };
                    }),
                  ],
                };
              });
    
        
              // Prepare the updated payload
              const combinedPayload = {
                ...existingBenchmarkResponse.data.payload,
                benchmark_questions: updatedBenchmarkQuestions,
              };
        
              // Send the updated payload to the second endpoint
              const benchmarkResponse = await axios.put('https://api.twindix.com/benchmark-questions/4', combinedPayload, {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                  "Content-Type": "application/json",
                },
              });
        
              console.log("Benchmark Response:", benchmarkResponse.data.payload);
            } else {
              console.error("Benchmark questions payload is missing or not in the expected format.");
            }
        
            // Reset form and close dialog
            setOpen(false);
          } catch (err) {
            console.error("Error occurred while submitting:", err);
          }
        };

    const handleDownload = async () => {
      console.log('textContent')
      // Define your data
        const data = [
          { name: "Abdulrahman Masoud", email: "masoud4@mail.com",phone: '1012303188'
            ,password: "password", company_id: companyId },
        ];
      
        // Convert data to a worksheet
        const worksheet = XLSX.utils.json_to_sheet(data);
      
        // Create a new workbook and append the worksheet
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      
        // Generate the Excel file as a Blob
        const excelBlob = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
      
        // Create a text file as a Blob
        const textContent = `
        ==============================
        Welcome to Twindix Assessments
        ==============================
        
        To successfully upload your participants, please follow these steps:
        
        1- Don't change the file name 'users_import.xlsx'.
        2- The Participant data must be like the sample row provided in the file.
        3- You may type any password you suggest consisting of 8-12 characters with at least one capital letter and 1 digit. You can set the same password for all participants.
        4- Don't change the company_id and position_id fields, as they refer to the company and position from which you downloaded the file.
        5- Fill the entire company_id and position_id fields for all participants with the same values as in the sample row.
        6- Don't change any column headers.
        7- Don't leave any data empty.
        8- Delete the sample row before uploading your file.
        
        ==============================
        مرحباُ بك في اختبارات تويندكس
        ==============================
        
        رجاء اتباع التعليمات التالية بدقة حتى يتم رفع ملف الدعوات بشكل صحيح:
        1- لا تغير اسم ملف الاكسل
        2- يجب ملء بيانات المدعوين بنفس طريقة صف النموذج في الملف
        3- يمكنك اختيار كلمة مرور مناسبة بالحروف اللاتينية تتكون من 8-12 حرفًا، وحرف واحد على الأقل كبير ورقم واحد على الأقل
        4- لا تغير الأرقام في حقول
            company_id 
            position_id
            حيث إنها تحتوي على تعريف الشركة والوظيفة التي قمت بتحميل الملف منها
        5- انسخ كود الشركة والوظيفة في صف النموذج لكافة صفوف المدعوين
            company_id
            position_id
        6- لا تغير عناوين الأعمدة
        7- لا تترك أي حقل خالي من البيانات
        8- احذف صف النموذج قبل رفع الملف
        `;        
        const textBlob = new Blob([textContent], { type: "text/plain" });
      
        // Create a new zip file
        const zip = new JSZip();
      
        // Add the Excel file to the zip
        zip.file("users_import.xlsx", excelBlob);
      
        // Add the text file to the zip
        zip.file("Important guide.txt", textBlob);
      
        // Generate the zip file
        const zipBlob = await zip.generateAsync({ type: "blob" });
      
        // Download the zip file
        saveAs(zipBlob, "files.zip");
      };


    const handleClose = () => {
        setFormOpen(false);
      };
    
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const handleClickSnackbar = () => {
      setOpenSnackbar(true);
    };
  
    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSnackbar(false);
    };

    const inviteUser = async (e) => {
        if (selectedEmployees.length > 1) {
          try {
            const requests = selectedEmployees.map((employee) => {
              const inviteData = {
                user_id: employee, 
                order_id: isHavePackege,
              };
              console.log(inviteData)
      
              return axios.post("https://api.twindix.com/invite", inviteData, {
                headers: {
                  Authorization: "Bearer " + localStorage.token,
                },
              });
            });
      
            const responses = await Promise.all(requests); 
            console.log("All invites sent successfully:", responses);
            handleClose();
          } catch (error) {
            console.error("Error sending invites:", error);
          }
        } else {
          e.preventDefault();
          const inviteData = {
            user_id: userId,
            order_id: isHavePackege ,
          };
      
          try {
            const response = await axios.post("https://api.twindix.com/invite", inviteData, {
              headers: {
                Authorization: "Bearer " + localStorage.token,
              },
            });
            handleClose();
            handleClickSnackbar()
          } catch (error) {
            console.error("Error sending single invite:", error);
          }
        }
      };

const [getSpecEmp, setgetSpecEmp] = useState([]);
const [positionName, setPositionName] = useState("");

const handleGetSpecEmp = async (id) => {
  console.log(localStorage.token);
  try {
    // Fetch specific employee details
    const res = await axios.get(`https://api.twindix.com/employees/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.token}` },
    });

    const employee = res.data.payload;
    setgetSpecEmp(employee);

    // Find the position name based on positionId
    const matchedPosition = positions?.find(
      (position) => position.id === employee.position_id
    );
    setPositionName(
      matchedPosition && matchedPosition.name 
    );

  } catch (error) {
    console.error("Error fetching employee:", error);
  }
};


const selectedOrder = orders?.find((order) =>
  packages?.some(
    (pack) => pack.id === order.orderable_id && pack.exam_id === 1
  )
);

const packageCount =
packages?.find((pack) =>
    orders?.some((order) => pack.id === order.orderable_id && pack.exam_id === 1)
  )?.exams_count || 0;

const numberOfSenEnoll = sentEnrollments.filter((enroll) => enroll.exam_id === 1);
const availableAssessment = packageCount > 0 ? packageCount - numberOfSenEnoll.length : 0;

useEffect(() => {
  if (availableAssessment > 0 && selectedOrder) {
    setIsHavePackege(selectedOrder.id.toString());
  } else {
    // Find matching addon
    const matchingAddon = addOns?.find((addon) =>
      packages?.some(
        (pack) =>
          addon.name === pack.name &&
          addon.exam_id === pack.exam_id &&
          addon.description === pack.description &&
          orders?.some((order) => order.orderable_id === pack.id)
      )
    );

    if (matchingAddon) {
      setIsHavePackege(matchingAddon.name); 
    } else {
      setIsHavePackege(null); 
    }
  }
}, [availableAssessment, selectedOrder, addOns, packages, orders])

const [formData, setFormData] = useState({ name: '', email: '' });
const [openEditForm, setopenEditForm] = useState(false);

const handelCloseEdit = () => {
  setopenEditForm(false)
  setFormData({ name: '', email: '', company_id: '' });
}
const handleSubmit = async () => {
  try {
    const token = localStorage.getItem('token');
      // Update existing employee
      await axios.put(`https://api.twindix.com/employees/${currentEmployee}`, {...formData, company_id :companyId}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      handelCloseEdit()
    refetchData()
  } catch (error) {
    console.error('Error saving employee:', error);
  }
};

      const [currentEmployee, setcurrentEmployee] = useState('');

let isLoggedIn = useContext(appContext);

          
const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
  (acc, role) => {
    if (role.name === 'employee-create') acc.employeeCreate = true;
    if (role.name === 'employee-update') acc.employeeEdit = true;
    if (role.name === 'exam-invite') acc.examInvite = true;
    return acc;
  },
  { employeeCreate: false, examInvite: false, employeeEdit: false }
);
          const hasPermissioncreate = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.employeeCreate) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };
          const hasPermissionupdate = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.employeeEdit) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };
          const hasPermissioninvite = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.examInvite) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };

    return (
        <Stack spacing={3}>
        <BigChips  userName={companyName} finishedAssessments={`${availableAssessment}`} />
            <Stack direction="row" spacing={3}>
                <Button onClick={() => { 
                  hasPermissioncreate() 
                  if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.employeeCreate){
                    setOpen(true);
                  }}} size="large" variant="contained" sx={{ fontSize: '17px', borderRadius: "15px", background: "#3f8e8f" }}>
                    <AddIcon />
                    Add Participant
                </Button>
                <Button onClick={() => setOpenFile(true)} size="large" variant="contained" sx={{ fontSize: '17px', borderRadius: "15px", background: "#3f8e8f" }}>
                    <BorderHorizontalIcon />
                    Upload Bulk Participant
                </Button>
                <Button onClick={handleDownload} size="large" variant="contained" sx={{ fontSize: '17px', borderRadius: "15px", background: "#3f8e8f" }}>
                    <InsertDriveFileIcon />
                    Download Form
                </Button>
            </Stack>
            <Stack direction="row" spacing={3}>
                <Button onClick={() => {
                    handleSelectAll()
                    setFormOpen(true)}} size="small" variant="contained" sx={{ fontSize: '14px', background: "#3f8e8f", borderRadius: "30px" }}>
                    <SendIcon />
                    Send Assessment to All Participant
                </Button>
                <Button onClick={() =>  setFormOpen(true)} size="small" variant="contained" sx={{ fontSize: '14px', background: "#3f8e8f", borderRadius: "30px" }}>
                    <SendIcon />
                    Send Assessment to Selected Participant
                </Button>
                <Button onClick={() => onSubmit(true)} size="small" variant="contained" sx={{ fontSize: '14px', background: "#3f8e8f", borderRadius: "30px" }}>
                    <NotificationsIcon />
                    Send Reminder to All Participant
                </Button>
            </Stack>
            <Box>
                <TableContainer>
                    <Table aria-label="Employees Table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectedEmployees.length === currentEmployees.length}
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell>Id</TableCell>
                                <TableCell>Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>phone</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {currentEmployees.length > 0 ? (
                                currentEmployees.map((user) => {
                                  const isSend = sentEnrollments.some((ele) => ele.user_id === user.id) ? true : false
                                  return (
                                    <TableRow key={user.id}>
                                        <TableCell>
                                            <Checkbox
                                                checked={selectedEmployees.includes(user.id)}
                                                onChange={() => handleSelectRow(user.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{user.id}</TableCell>
                                        <TableCell>{user.name}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.phone ?? 'There is no phone'}</TableCell>
                                                      <TableCell>
                                                        <Stack direction="row" spacing={2}>
                                                            {isSend === false ?
                                                            <>
                                                            <Button
                                                                  variant="contained"
                                                                  sx={{ width: '100px', padding : '10px 0', borderRadius: "14px", background: "#2b6566" }}
                                                                  onClick={() => {
                                                                    hasPermissionupdate()
                                                                    if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.employeeEdit){
                                                                    setopenEditForm(true)
                                                                    setcurrentEmployee(user.id)
                                                                    handleGetSpecEmp(user.id)
                                                                    }
                                                                    }}
                                                                >
                                                                  Edit
                                                                </Button>
                                                                    <Button
                                                                        variant="contained"
                                                                        sx={{ width: '200px', padding : '10px 0', borderRadius: '14px', background: "#2b6566" }}
                                                                        onClick={() => {
                                                                          hasPermissioninvite()
                                                                          if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.examInvite) {
                                                                            setUserId(user.id);
                                                                            setFormOpen(true);
                                                                            setSelectedUser(user)
                                                                          }
                                                                        }}
                                                                    >
                                                                        <SendIcon />
                                                                        Send Assessment
                                                                    </Button>
                                                            </>
                                                            :
                                                            <>
                                                            <Button
                                                                  variant="contained"
                                                                  sx={{ width: '100px', padding : '10px 0', borderRadius: "14px", background: "#2b6566" }}
                                                                  onClick={() => {
                                                                    hasPermissionupdate()
                                                                    if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.employeeEdit){
                                                                    setopenEditForm(true)
                                                                    setcurrentEmployee(user.id)
                                                                    handleGetSpecEmp(user.id)
                                                                    }
                                                                    }}
                                                                >
                                                                  Edit
                                                                </Button>
                                                            <Button
                                                                  variant="contained"
                                                                  sx={{ width: '200px', padding : '10px 0', borderRadius: '14px', background: "#2b6566" }}
                                                                  onClick={() => {
                                                                      onSubmit(true);
                                                                  }}
                                                              >
                                                                  <NotificationsIcon />
                                                                  Send Reminder
                                                              </Button>
                                                              </>
                                                            }
                                                          </Stack>
                                                      </TableCell>

                                    </TableRow>
                                )})
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        There are no employees matching the criteria.
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 2 }}></Box>
                <Dialog
                    maxWidth="md"
                    open={formOpen}
                    onClose={handleClose}
                  >
                    <Box
                      sx={{
                        bgcolor: '#929292',
                        width: '450px',
                      }}
                    >
                      <DialogContent
                                  sx={{
                            maxHeight: "500px", 
                            overflowY: "auto",
                            "&::-webkit-scrollbar": {
                              width: "6px",
                            },
                            "&::-webkit-scrollbar-thumb": {
                              borderRadius: "10px",
                              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                            },
                            "&::-webkit-scrollbar-track": {
                              background: "#929292",
                              borderRadius: "10px",
                            },
                          }}
                      >
                        <Grid2 container direction="column" spacing={4}>
                        <Typography variant="h5" component='h5' fontSize={17} sx={{color :'white'}}>
                        Are you sure you want to send invite to {selectedUser?.name}?
                        </Typography>
                        </Grid2>
                      </DialogContent>
                      <DialogActions>
                        <Button onClick={inviteUser} sx={{background: "linear-gradient(45deg, #2c6264, #5ac4cb)",borderRadius: '10px', margin: 'auto' }}  variant='contained'>
                          send Assessment  
                        </Button>
                      </DialogActions>
                    </Box>
                  </Dialog>
            </Box>
            <AddParticipant  setrefrech={refetchData} company_id={companyId} open={open} setopen={setOpen} />
            <AddPartisipantFile setrefrech={refetchData} open={openFile} setopen={setOpenFile} company_id={companyId} />
            <Snackbar
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}
        message="Assessment sent to selected participants successfully"
      />
            <Dialog  open={openEditForm} onClose={() => {
              setFormData({ name: '', email: '', position_id: '', company_id: '' });  
              setopenEditForm(false)}}>
            <Box sx={{width :'500px', background :'#929292'}}>
        <DialogTitle><Typography sx={{color: 'white'}}>Edit Employee</Typography></DialogTitle>
        <DialogContent>
                <Typography sx={{color: 'white', textDecoration: 'underline'}}>Name</Typography>
                <TextField
                  fullWidth
                  name="name"
                  InputProps={{
                    sx: { color: "white" }, // Ensure white text
                  }}
                  value={formData.name === '' ? getSpecEmp.name : formData.name} // Use formData.name first, fallback to getSpecEmp.name
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                  margin="normal"
                />
                  <Typography sx={{color: 'white', textDecoration: 'underline'}}>Email</Typography>
          
                  <TextField
                    fullWidth
                    name="email"
                    InputProps={{
                      sx: { color: "white" }, // Ensure white text
                    }}
                    value={formData.email === '' ? getSpecEmp.email : formData.email} // Use formData.email first, fallback to getSpecEmp.email
                    onChange={(e) =>
                      setFormData((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                    margin="normal"
                  />

        </DialogContent>
      <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                margin: 'auto',
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              Update Participant
            </Button>
          </DialogActions>
        </Box>
            </Dialog>
        </Stack>
    );
}

export default MangeInd;
