import { Button, Stack, Typography, Checkbox, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Dialog, Box, IconButton, DialogTitle, DialogContent, Grid2, Select, MenuItem, DialogActions, Snackbar } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import DebForm from "./Component/Forms/DebForm";
import ActionsChips from "../Component/ActionsChips";
import BigChips from "../Component/BigChips/Index";
import { appContext } from "../../../../App";
import Swal from "sweetalert2";
import DownloadForOfflineIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ApiContext from "../Context/context";
import CloseIcon from '@mui/icons-material/Close';
import { useSearch } from "../Context/SearchContext";

function AccessTwindixIndivdual({
  setshowBackBtn,
}) {
  const { employees, sentEnrollments, companies } = useContext(ApiContext);
  const { searchQuery } = useSearch();
  const [showUser, setShowUser] = useState(false);
  // const [employees, setEmployees] = useState([]);
  // const [companies, setCompanies] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [loader, setLoader] = useState(false);
  const [companyName, setCompanyName] = useState(false);

  const isLoggedIn = useContext(appContext);

  const [currentPage, setCurrentPage] = useState(1); // Track the current page
  const [rowsPerPage] = useState(10); // Set the number of rows per page

  useEffect(() => {
    if(showUser) {
      setshowBackBtn(false)
    } else {
      setshowBackBtn(true)
    }
  }, [showUser, setshowBackBtn]); 

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleRowSelect = (rowId) => {
    setSelectedRows((prev) =>
      prev.includes(rowId) ? prev?.filter((id) => id !== rowId) : [...prev, rowId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      const filteredEnrollments = sentEnrollments
        .filter((enrol) =>
          employees.some(
            (emp) =>
              emp.company_id === selectedCompany &&
              emp.id === enrol.user_id &&
              enrol.exam_id === 1
          )
        )
        .map((enrol) => enrol.id); 
      
      setSelectedRows(filteredEnrollments); 
      console.log(selectedCompany)
    }
    setSelectAll(!selectAll);
  };
  const downloadReports = async (ids) => {
    setLoader(true);
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/reports-download",
        { enrollment_ids: ids }
      );
      if (response) {
        setLoader(false);
        window.open(response.data.payload.reports_zip_file, "_blank");
      }
    } catch (error) {
      setLoader(false);
      console.error("Error downloading reports:", error);
    }
  };

  const handleDownloadAll = () => {
    const filteredEnrollments = sentEnrollments
      .filter((enrol) =>
        employees.some(
          (emp) =>
            emp.company_id === selectedCompany &&
            emp.id === enrol.user_id &&
            enrol.exam_id === 1 &&
            enrol.finished === 1
        )
      )
      .map((enrol) => enrol.id); // Extract the IDs to download
  
    downloadReports(filteredEnrollments);
  };  
    const finishedAssessments = sentEnrollments?.filter((enro) => {
      const employee = employees.find((emp) => emp.id === enro.user_id);
      return employee?.company_id === selectedCompany && enro.finished == 1;
    }).length?? "0";
    
    const pendingAssessments = sentEnrollments?.filter((enro) => {
      const employee = employees.find((emp) => emp.id === enro.user_id);
      return employee?.company_id === selectedCompany && enro.finished == 0;
    }).length?? "0";
    
  
    const handleBackButton = () => {
      setShowUser(false); 
      setshowBackBtn(false); 
    };

    const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
      (role) => role.name !== 'employee-show' 
    );
  
    useEffect(() => {
      if (isLoggedIn?.userData?.managed_by !== null ) {
        if(hasRoleCreatePermission){
          Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You do not have permission to access this page.',
            timer: 3000,
            showConfirmButton: true, 
            willClose: () => {
              window.location.reload();
            },
          });
        } 
      }
    }, [hasRoleCreatePermission]);

    const [selectLanguage, setSelectLanguage ] = useState(false);
    const [selectedValue, setSelectedValue] = useState('ar');
    const [openDownload, setopenDownload] = useState(false);
const [msg, setmsg] = useState('');
const [downloadDetails, setDownloadDetails ] = useState({});

const handleClickOpen = () => {
  setopenDownload(true);
};

const handleCloseDownload = () => {
  setopenDownload(false);
  setSelectLanguage(false)
};
const username = () => {
  const user = employees.find((ele) => ele.id === downloadDetails.user_id);
  return user ? user?.name?.replaceAll(" ", "-") : "User not found";
};

  const reGenerateReports = async (id, exam_id) => {
    setLoader(true)
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/report-generate",
        {
          "url": `https://assessment.twindix.com/reportPage?lang=${selectedValue}&enro_id=${id}&exam_id=${exam_id}&&name=${username()}`,
          "isLogged": true,
          "regenerate": true,
          "token": localStorage.token,
          "userType": "2",
          "enrollment_id":id
      }
      );
      if (response) {
        setmsg('report is Downloading')
        handleCloseDownload()
        setLoader(false)
        console.log(response.data.payload.reports_zip_file)
        // window.open(response.data.payload.reports_zip_file, "_blank");
      }
    } catch ( response) {
      setmsg('report is generating')
      handleCloseDownload()
      setLoader(false)
    }
  };
    const [openn, setOpenn] = useState();
  
  const handleClick = () => {
    setOpenn(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenn(false);
  };
  const filteredcompanies = companies?.filter((product) =>
    product.name?.toLowerCase().includes(searchQuery?.toLowerCase())
  );
  const filteredsentEnrollments = sentEnrollments?.filter((product) =>
    product.code?.toLowerCase().includes(searchQuery?.toLowerCase())
  );

  return (
    <>
      {loader && (
        <div className="loader-overlay">
          <div className="loader" />
        </div>
      )}
      {showUser ? (
        <>
        <Button onClick={handleBackButton} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back
      </Button>
          <BigChips finishedAssessments={`${finishedAssessments}`} pendingAssessments={`${pendingAssessments}`} userName={companyName} />
          <ActionsChips
            ind={true}
            downloadAllIngReport={handleDownloadAll}
            downloadIngSelectedReport={() => downloadReports(selectedRows)}
          />
          {sentEnrollments.length > 0 ? 
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
        <TableCell width='74px'>
          <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            aria-label="Select all rows"
          />
        </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell></TableCell>
                  <TableCell>Code</TableCell>
                  <TableCell>Created At</TableCell>
                  <TableCell>Actions</TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Table>
              <TableBody>
              {filteredsentEnrollments
                ?.filter((enrol) => 
                  employees.some((emp) => emp.company_id === selectedCompany && emp.id === enrol.user_id && enrol.exam_id === 1)
                )
                .map((enrol) => (
                    <TableRow key={enrol.id}>
                      <TableCell width='74px'>
                        <Checkbox
                          checked={selectedRows.includes(enrol.id)}
                          onChange={() => handleRowSelect(enrol.id)}
                        />
                      </TableCell>
                      <TableCell  sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                      {employees.map((emp) => emp.id === enrol.user_id && emp.name)}
                      </TableCell>
                      <TableCell sx={{width: '120px !important', color: "#5e5e5e", textDecoration: "underline" }}>
                      {employees.map((emp) => emp.id === enrol.user_id && emp.email)}
                      </TableCell>
                      <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                      {enrol.code}
                      </TableCell>
                      <TableCell sx={{ color: "#5e5e5e", textDecoration: "underline" }}>
                        {enrol.created_at.slice(0, 10)}
                      </TableCell>
                      <TableCell>
                        <Stack direction="row" spacing={2}>
                        {enrol.finished === 1 ? 
                          <Button
                            variant="contained"
                            sx={{ background: "#2b6566" }}
                            onClick={() => {
                              if (enrol.finished === 1) {
                        setDownloadDetails(enrol);
                        handleClickOpen();
                      } 
                              }}
                          >
                          <DownloadForOfflineIcon />
                            Download Report
                          </Button>
                        :
                          <Button
                            variant="contained"
                            sx={{ background: "#2b6566" }}
                          >
                            No Report Yet
                          </Button>
                        
                        }
                        </Stack>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          :<Typography sx={{marginLeft: '350px'}}> No Reports Yet </Typography>}
        </>
      ) : (
        companies.length > 0 ? filteredcompanies
    ?.filter((ele) => ele.exam_id === 1) // Filter by exam_id
    .map((company) => {
      const employeeCount = employees?.reduce(
        (count, emp) =>
          emp.company_id === company.id
            ? count +
              sentEnrollments?.filter(
                (enrol) => enrol.user_id === emp.id && enrol.exam_id === 1
              ).length
            : count,
        0
          );
        return (
          <Stack
            key={company.id}
            sx={{ borderBottom: "2px solid #5d5d5d" }}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={1}
            m={3}
          >
            <Typography
              sx={{
                width :'160px',
                cursor: "pointer",
                color: "#5d5d5d",
                textDecoration: "underline",
                fontWeight: "bold",
              }}
              onClick={() => {
                setShowUser(true);
                setSelectedCompany(company.id);
                setCompanyName(company.name)
              }}
            >
              {company.name}
            </Typography>
                            <Typography sx={{ width :'150px', color: "#5e5e5e", textDecoration: "underline" }}>{company.phone}</Typography>
                            <Typography sx={{ width :'220px', color: "#5e5e5e", textDecoration: "underline" }}>{company.mail}</Typography>
                            <Typography sx={{ width :'100px', color: "#5e5e5e", textDecoration: "underline" }}>
                              {company.updated_at.slice(0, 10)}
                            </Typography>
            <Typography sx={{ color: "#3f8e8f", border: '2.5px solid #3f8e8f', borderRadius: '12px', padding: '6px', fontWeight: 'bold' }}>
          {employeeCount} {employeeCount === 1 ? "Reports" : "Reports"}
        </Typography>
          </Stack>
        )}) : 'There Is No Enrollments Yet'
      )}
      <DebForm open={open} setOpen={setOpen} />
      <Dialog
        maxWidth="lg"
        open={openDownload}
        onClose={handleCloseDownload}
      >
        <Box
          sx={{
            bgcolor: '#929292',
            width: '600px',
          }}
        >
        <IconButton onClick={handleCloseDownload}><CloseIcon/></IconButton>
          <DialogTitle>
            <Typography sx={{width: '50%', margin: 'auto', color: 'white', textDecoration: 'underline' }}>
            It may take 2-5 minutes to generate the report in the new language, and it will be set as the default report.
            </Typography>
          </DialogTitle>
          <DialogContent>
          {selectLanguage ? 
          <Grid2 container direction="column" spacing={4}>
          <Grid2 item xs={12}>
        <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
        language:
        </Typography>
        <Select
                  sx={{
            padding: "10px",
            fontSize: "10px",
            borderRadius: "4px",
            width: "100%",
            height: "40px",
            backgroundColor: "white",
          }}
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
          displayEmpty
          >
      <MenuItem value="" disabled>
        Select Language
      </MenuItem>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ar">Arabic</MenuItem>
    </Select>
      </Grid2>
    <Button
                    variant="contained"
                    style={{
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: '10xp'
                        }}
                        onClick={() => {
                          if (!downloadDetails) return; 
                          // window.open(`/reportPage?enro_id=${downloadDetails.id}&exam_id=${downloadDetails.exam_id}`);
                          // downloadReports([downloadDetails.id]);
                          
                          handleCloseDownload()
                          // localStorage.setItem("language", selectedValue);
                          reGenerateReports(downloadDetails.id, downloadDetails.exam_id);
                        }}
                  >
                    Regenerate Report With {selectedValue === 'ar' ? "Arabic" : 'English'} Language
                  </Button>
            </Grid2>
            :
            <Stack justifyContent='space-between' direction='row' width='100%' margin='auto'>
            <Button
                    variant="contained"
                    style={{
                      padding: '6px',
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: 'auto'
                        }}
                        onClick={() => {
                          if (!downloadDetails) return; 
                          
                          downloadReports([downloadDetails.id]);
                          handleCloseDownload()
                        }}
                  >
                    Download Current Report
                  </Button>
          <Button
                    variant="contained"
                    style={{
                          padding: '6px',
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: 'auto'
                        }}
                        onClick={() => {setSelectLanguage(true)}}
                  >
                    Regenerate Another language
                  </Button>
            </Stack>
            }
          </DialogContent>
          <DialogActions> 
          {/* <Button onClick={() => window.open(`/reportPage?lang=${selectedValue}&enro_id=${downloadDetails.id}&exam_id=${downloadDetails.exam_id}&name=${name}`)}>open</Button> */}
          </DialogActions>
        </Box>
      </Dialog>
    </>
  );
}

export default AccessTwindixIndivdual;
