import ReportCom from "../../Componants/Report/ReportCom"
import React from 'react';
function ReportPage () {


    return (
        <>
            <ReportCom />
        </>
    )
}
export default ReportPage;