import { Box, Button, Stack, Typography } from "@mui/material"; 
import AddCompanyForm from "./Component/Forms/AddCompanyForm";
import { useContext, useEffect, useState } from "react";
import MangeInd from "./Component/MangeInd";
import { appContext } from "../../../../App";
import Swal from "sweetalert2";
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ApiContext from "../Context/context";

function MangeTwindixAssessments({setshowBackBtn}) {
  const {
    employees,
    companies,
    refetchData,
  } = useContext(ApiContext);
  console.log(employees)
  const [open, setOpen] = useState(false);
  const [showUsers, setShowUser] = useState(false);
  const [selectedCompanyId, setSelectedCompanyId] = useState(null); // State to track the selected companyId
  const [selectedCompanyName, setSelectedCompanyName] = useState(null); // State to track the selected companyId
  const [showBackBtn, setShowBackBtn] = useState(false); // To manage visibility of the back button

    useEffect(() => {
      if(showUsers) {
        setshowBackBtn(false)
      } else {
        setshowBackBtn(true)
      }
    }, [showUsers, setshowBackBtn]); 

  let isLoggedIn = useContext(appContext);

  const handleBackButton = () => {
    setShowUser(false); 
    setShowBackBtn(false); 
  };

      const hasRoleShowPermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
        (role) => role.name !== 'employee-show' 
      );
    
      useEffect(() => {
        if (isLoggedIn?.userData?.managed_by !== null ) {
          if(hasRoleShowPermission){
            Swal.fire({
              icon: 'error',
              title: 'Access Denied',
              text: 'You do not have permission to access this page.',
              timer: 3000,
              showConfirmButton: true, 
              willClose: () => {
                window.location.reload();
              },
            });
          } 
        }
      }, [hasRoleShowPermission, isLoggedIn]);

      const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
        (acc, role) => {
          if (role.name === 'company-create') acc.companyCreate = true;
          return acc;
        },
        { companyCreate: false}
      );
          const hasPermission = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.companyCreate) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };

  return (
    <Box>

      {showUsers ? (
        <>
        <Button onClick={handleBackButton} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back
      </Button>
        <MangeInd companyName={selectedCompanyName} companyId={selectedCompanyId} />
        </>
      ) : (
        <>
          <Button
            onClick={() => {
              hasPermission(); 
            if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.companyCreate){
              setOpen(true);
            }
            }}
            variant="contained"
            sx={{ color: "white", background: "linear-gradient(45deg, #2c6264, #5ac4cb)", borderRadius: '14px', fontWeight: 'bold', padding: '10px 5px' }}
          >
            <AddIcon />
            Add Company
          </Button>

          {companies?.length > 0 ? companies.filter((ele) => ele.exam_id === 1)
            ?.map((company) => {
              const employeeCount = employees.filter((emp) => emp.company_id === company.id).length;
              return (
                <Stack
                  key={company.id}
                  p={2}
                  borderBottom="1px solid rgb(192, 192, 192);"
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Stack alignItems="center" direction="row">
                    <Typography
                      onClick={() => {
                        setSelectedCompanyName(company.name);
                        setSelectedCompanyId(company.id);
                        setShowUser(true);
                        setShowBackBtn(true); // Show back button when a company is selected
                      }}
                      sx={{
                        width:'200px',
                        cursor: "pointer",
                        color: "#5e5e5e",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {company.name}
                    </Typography>
                  </Stack>
                  <Typography sx={{width:'150px', color: "#5e5e5e", textDecoration: "underline" }}>{company.phone}</Typography>
                  <Typography sx={{width:'120px', color: "#5e5e5e", textDecoration: "underline" }}>{company.mail}</Typography>
                  <Typography sx={{width:'100px', color: "#5e5e5e", textDecoration: "underline" }}>
                    {company.updated_at.slice(0, 10)}
                  </Typography>
                  <Stack direction="row" spacing={2}>
                    <Typography sx={{ color: "#3f8e8f", border: '2.5px solid #3f8e8f', borderRadius: '12px', padding: '6px', fontWeight: 'bold' }}>
                      {employeeCount} {employeeCount === 1 ? "Participant" : "Participant"}
                    </Typography>
                  </Stack>
                </Stack>
              );
            }) : <Typography sx={{marginLeft: '350px'}}>There Is No Companies Yet</Typography>
          }

          <AddCompanyForm setRefetch={refetchData} exam_id={1} open={open} setOpen={setOpen} />
        </>
      )}
    </Box>
  );
}

export default MangeTwindixAssessments;
