import { createContext, useEffect, useState, useCallback } from "react";
import axios from "axios";

const ApiContext = createContext();

export const ApiProvider = ({ children }) => {
  const [exams, setExams] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [sentEnrollments, setSentEnrollments] = useState(null);
  const [enrollments, setEnrollments] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [positions, setPositions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [packages, setPackages] = useState([]);
  const [addOns, setAddOns] = useState([]);
  const [editors, setEditors] = useState([]);
  const [roles, setRoles] = useState([]);
  const [allPermissions, setAllPermissions] = useState([]);
  const [getBenchMarkData, setGetBenchMarkData] = useState([]);

  // Global refetch trigger
  const [refetchTrigger, setRefetchTrigger] = useState(0);

  // Function to trigger refetch
  const refetchData = () => setRefetchTrigger((prev) => prev + 1);

  // Function to fetch all API data
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");

      const [examsRes, enrollmentsRes, sentEnrollmentsRes, companiesRes, benchmarkRes, ordersRes, packagesRes, permissionsRes, rolesRes, editorsRes, addOnsRes] = await Promise.all([
        axios.get("https://api.twindix.com/exams", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/enrollments", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/sent-enrollments", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/companies", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/benchmark-questions/1/benchmarks", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/orders", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/packages"),
        axios.get("https://api.twindix.com/permissions", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/roles", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/editors", { headers: { Authorization: `Bearer ${token}` } }),
        axios.get("https://api.twindix.com/benchmark-questions/2", { headers: { Authorization: `Bearer ${token}` } }),
      ]);

      setExams(examsRes.data.payload.data);
      setEnrollments(enrollmentsRes.data.payload.data);
      setSentEnrollments(sentEnrollmentsRes.data.payload);
      setCompanies(companiesRes.data.payload);
      setGetBenchMarkData(benchmarkRes.data.payload);
      setOrders(ordersRes.data.payload.data);
      setAllPermissions(permissionsRes.data.payload);
      setRoles(rolesRes.data.payload);
      setEditors(editorsRes.data.payload);
      const addOnsVar = addOnsRes.data.payload.benchmark_questions?.[0].packageType;
      const packagesVar = packagesRes.data.payload.data;
      setPackages(packagesVar.filter((pack) => 
        !addOnsVar.some((addon) => addon.name === pack.name)
      ));
      setAddOns(packagesVar.filter((pack) => 
        addOnsVar.some((addon) => addon.name === pack.name)
      ));
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  }, []);
  // Fetch data on mount & whenever refetchTrigger changes
  useEffect(() => {
    fetchData();
  }, [fetchData, refetchTrigger]);

  // Fetch positions when selectedCompany changes
  useEffect(() => {
    if (selectedCompany) {
      axios
        .get(`https://api.twindix.com/companies/${selectedCompany.id}/positions`, {
          headers: { Authorization: `Bearer ${localStorage.token}` },
        })
        .then((res) => setPositions(res.data.payload))
        .catch((error) => console.error("Error fetching positions:", error));
    }
  }, [selectedCompany, refetchTrigger]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        // Fetch Employees
        let allEmployees = [];
        let currentPage = 1;
        let hasMoreData = true;
        while (hasMoreData) {
          const employeesRes = await axios.get(`https://api.twindix.com/employees?page=${currentPage}`, {
            headers: { Authorization: `Bearer ${token}` },
          });

          const newEmployees = employeesRes.data.payload;
          allEmployees = [...allEmployees, ...newEmployees];
          hasMoreData = newEmployees.length === 15;
          currentPage++;
        }
        setEmployees(allEmployees);


        setLoading(false);
      } catch (err) {
        setError(err);
        setLoading(false);
      }
    };

    fetchData();
  }, [refetchTrigger]);

  return (
    <ApiContext.Provider
      value={{
        exams,
        employees,
        enrollments,
        sentEnrollments,
        companies,
        selectedCompany,
        setSelectedCompany,
        positions,
        loading,
        error,
        orders,
        packages,
        addOns,
        editors,
        setEditors,
        roles,
        allPermissions,
        getBenchMarkData,
        refetchData, 
      }}
    >
      {children}
    </ApiContext.Provider>
  );
};

export default ApiContext;
