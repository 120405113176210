import LogoBlu from "../../../FemilerCom/Logo/SLogoBlu"
import LogoWiht from "../../../Media/Logo/Logo-big-w.png"
import fortytwo  from "../../../Media/Twindexreport/forty-two.webp"
import fortythree  from "../../../Media/Twindexreport/forty-three.webp"
import fortyfour from "../../../Media/Twindexreport/forty-four.webp"
import fortyfive from "../../../Media/Twindexreport/forty-five.webp"
import fortysix  from "../../../Media/Twindexreport/forty-six.webp"
import fortyseven from "../../../Media/Twindexreport/forty-seven.webp"
import fortyeight from "../../../Media/Twindexreport/forty-eight.webp"
import fortynine from "../../../Media/Twindexreport/forty-nine.webp"
import { useContext } from "react"
import { repoCon } from "../ReportCom"

function Report5 ({lang})  {
    let repoConn = useContext(repoCon)

    function Charts (upchart, downchart)  {
        let s = 200 - (upchart + downchart);
        upchart = Math.ceil((upchart + Math.ceil(s*upchart / 100)) / 1.97);
        downchart = Math.ceil((downchart + Math.ceil(s*downchart / 100)) / 1.97);
        return { upchart, downchart };
    }

    let R367 = repoConn.references["R367"];
    let R366 = repoConn.references["R366"];
    let R365 = repoConn.references["R365"];
    let R373 = repoConn.references["R373"];
    let R372 = repoConn.references["R372"];
    let R371 = repoConn.references["R371"];
    const result33 = Charts(R367, R373);
    const result34 = Charts(R366, R372);
    const result35 = Charts(R365, R371);
    
    const SR367 = {
        height: `${result33.upchart}%`,
    };
    const SR366 = {
        height: `${result34.upchart}%`,
    };
    const SR365 = {
        height: `${result35.upchart}%`,
    };
    const SR373 = {
        height: `${result33.downchart}%`,
    };
    const SR372 = {
        height: `${result34.downchart}%`,
    };
    const SR371 = {
        height: `${result35.downchart}%`,
    };
    
    return (
        <>
            <div id="Fifth">
            <div className='ManyBox-7 pdf-container-10'>
                <img className='ImgReport' src={fortytwo} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                    <div>{repoConn.referencess && repoConn.referencess[894][lang === 'en' ? 'en content' : 'Ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-1" style={{top: "315px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[895][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[899][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[898][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[897][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["331"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "2px"}}>{repoConn.referencess && repoConn.referencess[903][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[906][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["231"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "2px"}}>{repoConn.referencess && repoConn.referencess[904][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[907][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["131"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "2px"}}>{repoConn.referencess && repoConn.referencess[905][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[908][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[902][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[901][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[900][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "75px"}}>{repoConn.referencess && repoConn.referencess[896][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["131"] == 1 && repoConn.answers["231"] == 1 && repoConn.answers["331"] == 1 ?
                                repoConn.referencess && repoConn.referencess[909][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["131"] == 1 && repoConn.answers["231"] == 1 && repoConn.answers["331"] == 0 ?
                                repoConn.referencess && repoConn.referencess[910][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["131"] == 1 && repoConn.answers["231"] == 0 && repoConn.answers["331"] == 1 ?
                                repoConn.referencess && repoConn.referencess[911][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["131"] == 1 && repoConn.answers["231"] == 0 && repoConn.answers["331"] == 0 ?
                                repoConn.referencess && repoConn.referencess[912][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["131"] == 0 && repoConn.answers["231"] == 1 && repoConn.answers["331"] == 1 ?
                                repoConn.referencess && repoConn.referencess[913][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["131"] == 0 && repoConn.answers["231"] == 1 && repoConn.answers["331"] == 0 ?
                                repoConn.referencess && repoConn.referencess[914][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["131"] == 0 && repoConn.answers["231"] == 0 && repoConn.answers["331"] == 1 ?
                                repoConn.referencess && repoConn.referencess[915][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["131"] == 0 && repoConn.answers["231"] == 0 && repoConn.answers["331"] == 0 ?
                                repoConn.referencess && repoConn.referencess[916][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-2" style={{top : "656px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[917][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[921][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[920][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[919][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["332"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[925][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2.7px"}}>{repoConn.referencess && repoConn.referencess[928][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["232"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[926][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2.7px"}}>{repoConn.referencess && repoConn.referencess[929][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["132"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[927][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2.7px"}}>{repoConn.referencess && repoConn.referencess[930][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[924][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[923][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[922][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "120px"}}>{repoConn.referencess && repoConn.referencess[918][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["132"] == 1 && repoConn.answers["232"] == 1 && repoConn.answers["332"] == 1 ?
                                repoConn.referencess && repoConn.referencess[931][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["132"] == 1 && repoConn.answers["232"] == 1 && repoConn.answers["332"] == 0 ?
                                repoConn.referencess && repoConn.referencess[932][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["132"] == 1 && repoConn.answers["232"] == 0 && repoConn.answers["332"] == 1 ?
                                repoConn.referencess && repoConn.referencess[933][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["132"] == 1 && repoConn.answers["232"] == 0 && repoConn.answers["332"] == 0 ?
                                repoConn.referencess && repoConn.referencess[934][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["132"] == 0 && repoConn.answers["232"] == 1 && repoConn.answers["332"] == 1 ?
                                repoConn.referencess && repoConn.referencess[935][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["132"] == 0 && repoConn.answers["232"] == 1 && repoConn.answers["332"] == 0 ?
                                repoConn.referencess && repoConn.referencess[936][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["132"] == 0 && repoConn.answers["232"] == 0 && repoConn.answers["332"] == 1 ?
                                repoConn.referencess && repoConn.referencess[937][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["132"] == 0 && repoConn.answers["232"] == 0 && repoConn.answers["332"] == 0 ?
                                repoConn.referencess && repoConn.referencess[938][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-3" style={{top :"994px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[939][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[943][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[942][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[941][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["333"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[947][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[950][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["233"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[948][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[951][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["133"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[949][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[952][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[946][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[945][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[944][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "80px"}}>{repoConn.referencess && repoConn.referencess[940][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["133"] == 1 && repoConn.answers["233"] == 1 && repoConn.answers["333"] == 1 ?
                                repoConn.referencess && repoConn.referencess[953][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["133"] == 1 && repoConn.answers["233"] == 1 && repoConn.answers["333"] == 0 ?
                                repoConn.referencess && repoConn.referencess[954][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["133"] == 1 && repoConn.answers["233"] == 0 && repoConn.answers["333"] == 1 ?
                                repoConn.referencess && repoConn.referencess[955][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["133"] == 1 && repoConn.answers["233"] == 0 && repoConn.answers["333"] == 0 ?
                                repoConn.referencess && repoConn.referencess[956][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["133"] == 0 && repoConn.answers["233"] == 1 && repoConn.answers["333"] == 1 ?
                                repoConn.referencess && repoConn.referencess[957][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["133"] == 0 && repoConn.answers["233"] == 1 && repoConn.answers["333"] == 0 ?
                                repoConn.referencess && repoConn.referencess[958][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["133"] == 0 && repoConn.answers["233"] == 0 && repoConn.answers["333"] == 1 ?
                                repoConn.referencess && repoConn.referencess[959][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["133"] == 0 && repoConn.answers["233"] == 0 && repoConn.answers["333"] == 0 ?
                                repoConn.referencess && repoConn.referencess[960][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ManyBox-8 pdf-container-10'>
                <img className='ImgReport' src={fortythree} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-1">
                    <div className="LiftBox" >
                        <span className="TopTitle" >{repoConn.referencess && repoConn.referencess[961][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[965][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[964][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[963][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["334"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "2.3px"}}>{repoConn.referencess && repoConn.referencess[969][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[972][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["234"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "2.3px"}}>{repoConn.referencess && repoConn.referencess[970][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[973][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["134"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top : "2.3px"}}>{repoConn.referencess && repoConn.referencess[971][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[974][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[968][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[967][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[966][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle">{repoConn.referencess && repoConn.referencess[962][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["134"] == 1 && repoConn.answers["234"] == 1 && repoConn.answers["334"] == 1 ?
                                repoConn.referencess && repoConn.referencess[975][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["134"] == 1 && repoConn.answers["234"] == 1 && repoConn.answers["334"] == 0 ?
                                repoConn.referencess && repoConn.referencess[976][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["134"] == 1 && repoConn.answers["234"] == 0 && repoConn.answers["334"] == 1 ?
                                repoConn.referencess && repoConn.referencess[977][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["134"] == 1 && repoConn.answers["234"] == 0 && repoConn.answers["334"] == 0 ?
                                repoConn.referencess && repoConn.referencess[978][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["134"] == 0 && repoConn.answers["234"] == 1 && repoConn.answers["334"] == 1 ?
                                repoConn.referencess && repoConn.referencess[979][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["134"] == 0 && repoConn.answers["234"] == 1 && repoConn.answers["334"] == 0 ?
                                repoConn.referencess && repoConn.referencess[980][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["134"] == 0 && repoConn.answers["234"] == 0 && repoConn.answers["334"] == 1 ?
                                repoConn.referencess && repoConn.referencess[981][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["134"] == 0 && repoConn.answers["234"] == 0 && repoConn.answers["334"] == 0 ?
                                repoConn.referencess && repoConn.referencess[982][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-2 ">
                    <div className="LiftBox" style={{position: "relative", top: "-81px"}}>
                        <span className="TopTitle" style={{position: "reletive",left: "50px"}}>{repoConn.referencess && repoConn.referencess[983][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[987][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[986][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[985][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["335"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[991][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "1.3px"}}>{repoConn.referencess && repoConn.referencess[994][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["235"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[992][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "1.3px"}}>{repoConn.referencess && repoConn.referencess[995][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["135"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[993][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "1.3px"}}>{repoConn.referencess && repoConn.referencess[996][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[990][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[989][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[988][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[984][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["135"] == 1 && repoConn.answers["235"] == 1 && repoConn.answers["335"] == 1 ?
                                repoConn.referencess && repoConn.referencess[997][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["135"] == 1 && repoConn.answers["235"] == 1 && repoConn.answers["335"] == 0 ?
                                repoConn.referencess && repoConn.referencess[998][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["135"] == 1 && repoConn.answers["235"] == 0 && repoConn.answers["335"] == 1 ?
                                repoConn.referencess && repoConn.referencess[999][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["135"] == 1 && repoConn.answers["235"] == 0 && repoConn.answers["335"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1000][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["135"] == 0 && repoConn.answers["235"] == 1 && repoConn.answers["335"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1001][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["135"] == 0 && repoConn.answers["235"] == 1 && repoConn.answers["335"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1002][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["135"] == 0 && repoConn.answers["235"] == 0 && repoConn.answers["335"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1003][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["135"] == 0 && repoConn.answers["235"] == 0 && repoConn.answers["335"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1004][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-3">
                    <div className="LiftBox">
                        <span className="TopTitle" >{repoConn.referencess && repoConn.referencess[1005][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1009][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1008][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1007][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["336"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[1013][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2.5px"}}>{repoConn.referencess && repoConn.referencess[1016][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["236"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[1014][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2.5px"}}>{repoConn.referencess && repoConn.referencess[1017][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["136"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[1015][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2.5px"}}>{repoConn.referencess && repoConn.referencess[1018][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1012][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1011][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1010][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "100px"}}>{repoConn.referencess && repoConn.referencess[1006][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["136"] == 1 && repoConn.answers["236"] == 1 && repoConn.answers["336"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1019][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["136"] == 1 && repoConn.answers["236"] == 1 && repoConn.answers["336"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1020][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["136"] == 1 && repoConn.answers["236"] == 0 && repoConn.answers["336"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1021][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["136"] == 1 && repoConn.answers["236"] == 0 && repoConn.answers["336"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1022][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["136"] == 0 && repoConn.answers["236"] == 1 && repoConn.answers["336"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1023][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["136"] == 0 && repoConn.answers["236"] == 1 && repoConn.answers["336"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1024][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["136"] == 0 && repoConn.answers["236"] == 0 && repoConn.answers["336"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1025][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["136"] == 0 && repoConn.answers["236"] == 0 && repoConn.answers["336"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1026][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ManyBox-9 pdf-container-11'>
                <img className='ImgReport' src={fortyfour} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-1">
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1027][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1031][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1030][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1029][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["337"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1035][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1038][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["237"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1036][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1039][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["137"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1037][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1040][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1033][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1034][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1032][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "70px"}}>{repoConn.referencess && repoConn.referencess[1028][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["137"] == 1 && repoConn.answers["237"] == 1 && repoConn.answers["337"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1041][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["137"] == 1 && repoConn.answers["237"] == 1 && repoConn.answers["337"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1042][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["137"] == 1 && repoConn.answers["237"] == 0 && repoConn.answers["337"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1043][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["137"] == 1 && repoConn.answers["237"] == 0 && repoConn.answers["337"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1044][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["137"] == 0 && repoConn.answers["237"] == 1 && repoConn.answers["337"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1045][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["137"] == 0 && repoConn.answers["237"] == 1 && repoConn.answers["337"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1046][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["137"] == 0 && repoConn.answers["237"] == 0 && repoConn.answers["337"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1047][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["137"] == 0 && repoConn.answers["237"] == 0 && repoConn.answers["337"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1048][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-2 ">
                    <div className="LiftBox" style={{position: "relative", top: "-80px"}}>
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1049][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1053][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1052][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1051][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["338"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[1057][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red">{repoConn.referencess && repoConn.referencess[1060][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["238"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[1058][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red">{repoConn.referencess && repoConn.referencess[1061][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["138"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[1059][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red">{repoConn.referencess && repoConn.referencess[1062][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1056][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1055][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1054][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "50px"}}>{repoConn.referencess && repoConn.referencess[1050][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["138"] == 1 && repoConn.answers["238"] == 1 && repoConn.answers["338"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1063][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["138"] == 1 && repoConn.answers["238"] == 1 && repoConn.answers["338"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1064][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["138"] == 1 && repoConn.answers["238"] == 0 && repoConn.answers["338"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1065][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["138"] == 1 && repoConn.answers["238"] == 0 && repoConn.answers["338"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1066][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["138"] == 0 && repoConn.answers["238"] == 1 && repoConn.answers["338"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1067][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["138"] == 0 && repoConn.answers["238"] == 1 && repoConn.answers["338"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1068][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["138"] == 0 && repoConn.answers["238"] == 0 && repoConn.answers["338"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1069][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["138"] == 0 && repoConn.answers["238"] == 0 && repoConn.answers["338"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1070][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Red-3">
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1071][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1075][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1074][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1073][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["339"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[1079][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1082][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["239"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[1080][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1083][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["139"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Red">{repoConn.referencess && repoConn.referencess[1081][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom  ManyBoxBottomCol-Red" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1084][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1078][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1077][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1076][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "50px"}}>{repoConn.referencess && repoConn.referencess[1072][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["139"] == 1 && repoConn.answers["239"] == 1 && repoConn.answers["339"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1085][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["139"] == 1 && repoConn.answers["239"] == 1 && repoConn.answers["339"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1086][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["139"] == 1 && repoConn.answers["239"] == 0 && repoConn.answers["339"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1087][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["139"] == 1 && repoConn.answers["239"] == 0 && repoConn.answers["339"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1088][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["139"] == 0 && repoConn.answers["239"] == 1 && repoConn.answers["339"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1089][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["139"] == 0 && repoConn.answers["239"] == 1 && repoConn.answers["339"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1090][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["139"] == 0 && repoConn.answers["239"] == 0 && repoConn.answers["339"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1091][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["139"] == 0 && repoConn.answers["239"] == 0 && repoConn.answers["339"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1092][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='BigOneGraph-7 pdf-container-11'>
                <img className='ImgReport' src={fortyfive} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                    <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                        <div>{repoConn.referencess && repoConn.referencess[1093][lang === 'en' ? 'en content' : 'Ar content']}</div>
                    </div>
                    </div>
                    </div>
                <div className="TopTitle">
                <span>{repoConn.referencess && repoConn.referencess[1094][lang === 'en' ? 'en content' : 'Ar content']}</span>
                </div>
                <div className="Graph-1 ">
                    <div className="TopBox">
                        <div></div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[1098][lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[1097][lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[1096][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        </div>
                        <div>
                            <span>{result33.upchart}%</span>
                            <span>{result34.upchart}%</span>
                            <span>{result35.upchart}%</span>
                        </div>
                    </div>
                    <div className="Graph-Top-1">
                        <div className="BigCanvas" style={SR367}></div>
                        <div className="BigCanvas" style={SR366}></div>
                        <div className="BigCanvas" style={SR365}></div>
                    </div>
                    <div className="Graph-Bottom-1">
                        <div className="BigCanvas" style={SR373}></div>
                        <div className="BigCanvas" style={SR372}></div>
                        <div className="BigCanvas" style={SR371}></div>
                    </div>
                    <div className="BottomBox">
                        <div>
                            <span>{result33.downchart}%</span>
                            <span>{result34.downchart}%</span>
                            <span>{result35.downchart}%</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[1101][lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[1100][lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span>{repoConn.referencess && repoConn.referencess[1099][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        </div>
                    </div>
                </div>
                <div className="BottomTitle">
                    <span>{repoConn.referencess && repoConn.referencess[1095][lang === 'en' ? 'en content' : 'Ar content']}</span>
                </div>
            </div>
            <div className='ManyBox-10 pdf-container-11'>
                <img className='ImgReport' src={fortysix} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                <div className='IntroBatrn'>
                    <div className="StrepCountaner">
                        <div>{repoConn.referencess && repoConn.referencess[1102][lang === 'en' ? 'en content' : 'Ar content']}</div>
                    </div>
                </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-1" style={{top: "313px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1103][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1107][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1106][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1105][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["341"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top : "1.8px"}}>{repoConn.referencess && repoConn.referencess[1111][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1114][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["241"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top : "1.8px"}}>{repoConn.referencess && repoConn.referencess[1112][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1115][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["141"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top : "1.8px"}}>{repoConn.referencess && repoConn.referencess[1113][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1116][lang === 'en' ? 'en content' : 'Ar content']}</div>}

                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1110][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1109][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1108][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle">{repoConn.referencess && repoConn.referencess[1104][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["141"] == 1 && repoConn.answers["241"] == 1 && repoConn.answers["341"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1117][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["141"] == 1 && repoConn.answers["241"] == 1 && repoConn.answers["341"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1118][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["141"] == 1 && repoConn.answers["241"] == 0 && repoConn.answers["341"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1119][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["141"] == 1 && repoConn.answers["241"] == 0 && repoConn.answers["341"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1120][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["141"] == 0 && repoConn.answers["241"] == 1 && repoConn.answers["341"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1121][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["141"] == 0 && repoConn.answers["241"] == 1 && repoConn.answers["341"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1122][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["141"] == 0 && repoConn.answers["241"] == 0 && repoConn.answers["341"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1123][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["141"] == 0 && repoConn.answers["241"] == 0 && repoConn.answers["341"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1124][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-2" style={{top: "653px"}}>
                    <div className="LiftBox" style={{position: "relative", top: "-81px"}}>
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1125][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1129][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1128][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1127][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["342"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top : "0px"}}>{repoConn.referencess && repoConn.referencess[1033][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1136][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["242"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top : "0px"}}>{repoConn.referencess && repoConn.referencess[1134][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1137][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["142"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top : "0px"}}>{repoConn.referencess && repoConn.referencess[1135][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1138][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1132][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1131][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1130][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle"style={{position: "reletive",left: "50px"}} >{repoConn.referencess && repoConn.referencess[1126][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["142"] == 1 && repoConn.answers["242"] == 1 && repoConn.answers["342"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1139][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["142"] == 1 && repoConn.answers["242"] == 1 && repoConn.answers["342"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1140][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["142"] == 1 && repoConn.answers["242"] == 0 && repoConn.answers["342"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1141][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["142"] == 1 && repoConn.answers["242"] == 0 && repoConn.answers["342"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1142][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["142"] == 0 && repoConn.answers["242"] == 1 && repoConn.answers["342"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1143][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["142"] == 0 && repoConn.answers["242"] == 1 && repoConn.answers["342"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1144][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["142"] == 0 && repoConn.answers["242"] == 0 && repoConn.answers["342"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1145][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["142"] == 0 && repoConn.answers["242"] == 0 && repoConn.answers["342"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1146][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-3" style={{top: "990px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1147][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1151][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1150][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1149][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["343"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1155][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1158][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["243"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1156][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1159][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["143"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "2px"}}>{repoConn.referencess && repoConn.referencess[1157][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1160][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1154][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1153][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1152][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "140px"}}>{repoConn.referencess && repoConn.referencess[1148][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["143"] == 1 && repoConn.answers["243"] == 1 && repoConn.answers["343"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1161][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["143"] == 1 && repoConn.answers["243"] == 1 && repoConn.answers["343"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1162][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["143"] == 1 && repoConn.answers["243"] == 0 && repoConn.answers["343"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1163][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["143"] == 1 && repoConn.answers["243"] == 0 && repoConn.answers["343"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1164][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["143"] == 0 && repoConn.answers["243"] == 1 && repoConn.answers["343"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1165][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["143"] == 0 && repoConn.answers["243"] == 1 && repoConn.answers["343"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1166][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["143"] == 0 && repoConn.answers["243"] == 0 && repoConn.answers["343"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1167][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["143"] == 0 && repoConn.answers["243"] == 0 && repoConn.answers["343"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1168][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ManyBox-11 pdf-container-12'>
                <img className='ImgReport' src={fortyseven} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-1">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "100px"}}>{repoConn.referencess && repoConn.referencess[1169][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1173][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1172][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1171][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["344"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1177][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[1180][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["244"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1178][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[1181][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["144"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1179][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top: "1px"}}>{repoConn.referencess && repoConn.referencess[1182][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1176][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1175][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1174][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "80px"}}>{repoConn.referencess && repoConn.referencess[1170][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["144"] == 1 && repoConn.answers["244"] == 1 && repoConn.answers["344"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1183][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["144"] == 1 && repoConn.answers["244"] == 1 && repoConn.answers["344"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1184][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["144"] == 1 && repoConn.answers["244"] == 0 && repoConn.answers["344"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1185][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["144"] == 1 && repoConn.answers["244"] == 0 && repoConn.answers["344"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1186][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["144"] == 0 && repoConn.answers["244"] == 1 && repoConn.answers["344"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1187][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["144"] == 0 && repoConn.answers["244"] == 1 && repoConn.answers["344"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1188][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["144"] == 0 && repoConn.answers["244"] == 0 && repoConn.answers["344"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1189][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["144"] == 0 && repoConn.answers["244"] == 0 && repoConn.answers["344"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1190][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-2">
                    <div className="LiftBox"style={{position: "relative", top: "-77px"}}>
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1191][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1195][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1194][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1193][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox" style={{top: "48px"}}>
                        {repoConn.answers["345"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "-3px"}}>{repoConn.referencess && repoConn.referencess[1199][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "-3px"}}>{repoConn.referencess && repoConn.referencess[1202][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["245"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "-3px"}}>{repoConn.referencess && repoConn.referencess[1200][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "-3px"}}>{repoConn.referencess && repoConn.referencess[1203][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["145"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "-3px"}}>{repoConn.referencess && repoConn.referencess[1201][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "-3px"}}>{repoConn.referencess && repoConn.referencess[1204][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1198][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1197][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1196][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "30px"}}>{repoConn.referencess && repoConn.referencess[1192][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["145"] == 1 && repoConn.answers["245"] == 1 && repoConn.answers["345"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1205][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["145"] == 1 && repoConn.answers["245"] == 1 && repoConn.answers["345"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1206][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["145"] == 1 && repoConn.answers["245"] == 0 && repoConn.answers["345"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1207][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["145"] == 1 && repoConn.answers["245"] == 0 && repoConn.answers["345"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1208][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["145"] == 0 && repoConn.answers["245"] == 1 && repoConn.answers["345"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1209][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["145"] == 0 && repoConn.answers["245"] == 1 && repoConn.answers["345"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1210][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["145"] == 0 && repoConn.answers["245"] == 0 && repoConn.answers["345"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1211][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["145"] == 0 && repoConn.answers["245"] == 0 && repoConn.answers["345"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1212][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-3">
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[1213][lang === 'en' ? 'en content' : 'Ar content']}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1217][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1216][lang === 'en' ? 'en content' : 'Ar content']}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1215][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["346"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1221][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "4px"}}>{repoConn.referencess && repoConn.referencess[1224][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["246"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1222][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "4px"}}>{repoConn.referencess && repoConn.referencess[1225][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        {repoConn.answers["146"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green" style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1223][lang === 'en' ? 'en content' : 'Ar content']}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "4px"}}>{repoConn.referencess && repoConn.referencess[1226][lang === 'en' ? 'en content' : 'Ar content']}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1220][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1219][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1218][lang === 'en' ? 'en content' : 'Ar content']}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "110px"}}>{repoConn.referencess && repoConn.referencess[1214][lang === 'en' ? 'en content' : 'Ar content']}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["146"] == 1 && repoConn.answers["246"] == 1 && repoConn.answers["346"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1227][lang === 'en' ? 'en content' : 'Ar content'] : ""}
                                {repoConn.answers["146"] == 1 && repoConn.answers["246"] == 1 && repoConn.answers["346"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1228]["Ar content"] : ""}
                                {repoConn.answers["146"] == 1 && repoConn.answers["246"] == 0 && repoConn.answers["346"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1229]["Ar content"] : ""}
                                {repoConn.answers["146"] == 1 && repoConn.answers["246"] == 0 && repoConn.answers["346"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1230]["Ar content"] : ""}
                                {repoConn.answers["146"] == 0 && repoConn.answers["246"] == 1 && repoConn.answers["346"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1231]["Ar content"] : ""}
                                {repoConn.answers["146"] == 0 && repoConn.answers["246"] == 1 && repoConn.answers["346"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1232]["Ar content"] : ""}
                                {repoConn.answers["146"] == 0 && repoConn.answers["246"] == 0 && repoConn.answers["346"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1233]["Ar content"] : ""}
                                {repoConn.answers["146"] == 0 && repoConn.answers["246"] == 0 && repoConn.answers["346"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1234]["Ar content"] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='ManyBox-12 pdf-container-12'>
                <img className='ImgReport' src={fortyeight} alt="ReportImge" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-1" style={{left: "122px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1235]["Ar content"]}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1239]["Ar content"]}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1238]["Ar content"]}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1237]["Ar content"]}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["347"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1243]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "1.6px"}}>{repoConn.referencess && repoConn.referencess[1246]["Ar content"]}</div>}
                        {repoConn.answers["247"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1244]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "1.6px"}}>{repoConn.referencess && repoConn.referencess[1246]["Ar content"]}</div>}
                        {repoConn.answers["147"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1245]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "1.6px"}}>{repoConn.referencess && repoConn.referencess[1248]["Ar content"]}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1242]["Ar content"]}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1241]["Ar content"]}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1240]["Ar content"]}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "40px"}}>{repoConn.referencess && repoConn.referencess[1236]["Ar content"]}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["147"] == 1 && repoConn.answers["247"] == 1 && repoConn.answers["347"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1249]["Ar content"] : ""}
                                {repoConn.answers["147"] == 1 && repoConn.answers["247"] == 1 && repoConn.answers["347"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1250]["Ar content"] : ""}
                                {repoConn.answers["147"] == 1 && repoConn.answers["247"] == 0 && repoConn.answers["347"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1251]["Ar content"] : ""}
                                {repoConn.answers["147"] == 1 && repoConn.answers["247"] == 0 && repoConn.answers["347"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1252]["Ar content"] : ""}
                                {repoConn.answers["147"] == 0 && repoConn.answers["247"] == 1 && repoConn.answers["347"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1253]["Ar content"] : ""}
                                {repoConn.answers["147"] == 0 && repoConn.answers["247"] == 1 && repoConn.answers["347"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1254]["Ar content"] : ""}
                                {repoConn.answers["147"] == 0 && repoConn.answers["247"] == 0 && repoConn.answers["347"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1255]["Ar content"] : ""}
                                {repoConn.answers["147"] == 0 && repoConn.answers["247"] == 0 && repoConn.answers["347"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1256]["Ar content"] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-2" style={{left: "122px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle">{repoConn.referencess && repoConn.referencess[1257]["Ar content"]}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1261]["Ar content"]}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1260]["Ar content"]}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1259]["Ar content"]}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["348"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1265]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[1268]["Ar content"]}</div>}
                        {repoConn.answers["248"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1266]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[1269]["Ar content"]}</div>}
                        {repoConn.answers["148"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green">{repoConn.referencess && repoConn.referencess[1267]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "1px"}}>{repoConn.referencess && repoConn.referencess[1270]["Ar content"]}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1264]["Ar content"]}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1263]["Ar content"]}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1262]["Ar content"]}</div>
                        </div>
                        <span className="BottumTitle" style={{position: "reletive",left: "80px"}}>{repoConn.referencess && repoConn.referencess[1258]["Ar content"]}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["148"] == 1 && repoConn.answers["248"] == 1 && repoConn.answers["348"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1271]["Ar content"] : ""}
                                {repoConn.answers["148"] == 1 && repoConn.answers["248"] == 1 && repoConn.answers["348"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1272]["Ar content"] : ""}
                                {repoConn.answers["148"] == 1 && repoConn.answers["248"] == 0 && repoConn.answers["348"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1273]["Ar content"] : ""}
                                {repoConn.answers["148"] == 1 && repoConn.answers["248"] == 0 && repoConn.answers["348"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1274]["Ar content"] : ""}
                                {repoConn.answers["148"] == 0 && repoConn.answers["248"] == 1 && repoConn.answers["348"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1275]["Ar content"] : ""}
                                {repoConn.answers["148"] == 0 && repoConn.answers["248"] == 1 && repoConn.answers["348"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1276]["Ar content"] : ""}
                                {repoConn.answers["148"] == 0 && repoConn.answers["248"] == 0 && repoConn.answers["348"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1277]["Ar content"] : ""}
                                {repoConn.answers["148"] == 0 && repoConn.answers["248"] == 0 && repoConn.answers["348"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1278]["Ar content"] : ""}
                            </span>
                        </div>
                    </div>
                </div>
                <div className="ManyBoxesBox ManyBoxPostion-Green-3" style={{left: "122px"}}>
                    <div className="LiftBox">
                        <span className="TopTitle" style={{position: "reletive",left: "50px"}}>{repoConn.referencess && repoConn.referencess[1279]["Ar content"]}</span>
                        <div className="TopTitleBoxs">
                            <div>{repoConn.referencess && repoConn.referencess[1283]["Ar content"]}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1282]["Ar content"]}</div>
                            <div>{repoConn.referencess && repoConn.referencess[1281]["Ar content"]}</div>
                        </div>
                        <div className="CenterBox">
                        {repoConn.answers["349"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green"style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1287]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "4px"}}>{repoConn.referencess && repoConn.referencess[1290]["Ar content"]}</div>}
                        {repoConn.answers["249"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green"style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1288]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "4px"}}>{repoConn.referencess && repoConn.referencess[1291]["Ar content"]}</div>}
                        {repoConn.answers["149"] == 1 ? <div className="ManyBoxsTop ManyBoxTopCol-Green"style={{position: "relative", top: "3px"}}>{repoConn.referencess && repoConn.referencess[1289]["Ar content"]}</div> : <div className="ManyBoxsBottom ManyBoxBottomCol-Green" style={{position: "relative", top : "4px"}}>{repoConn.referencess && repoConn.referencess[1292]["Ar content"]}</div>}
                        </div>
                        <div className="BottumTitleBoxs">
                        <div>{repoConn.referencess && repoConn.referencess[1286]["Ar content"]}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1285]["Ar content"]}</div>
                        <div>{repoConn.referencess && repoConn.referencess[1284]["Ar content"]}</div>
                        </div>
                        <span className="BottumTitle">{repoConn.referencess && repoConn.referencess[1280]["Ar content"]}</span>
                    </div>
                    <div className="RightBox">
                        <div className="BOX">
                            <span>
                            {repoConn.answers["149"] == 1 && repoConn.answers["249"] == 1 && repoConn.answers["349"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1293]["Ar content"] : ""}
                                {repoConn.answers["149"] == 1 && repoConn.answers["249"] == 1 && repoConn.answers["349"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1294]["Ar content"] : ""}
                                {repoConn.answers["149"] == 1 && repoConn.answers["249"] == 0 && repoConn.answers["349"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1295]["Ar content"] : ""}
                                {repoConn.answers["149"] == 1 && repoConn.answers["249"] == 0 && repoConn.answers["349"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1296]["Ar content"] : ""}
                                {repoConn.answers["149"] == 0 && repoConn.answers["249"] == 1 && repoConn.answers["349"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1297]["Ar content"] : ""}
                                {repoConn.answers["149"] == 0 && repoConn.answers["249"] == 1 && repoConn.answers["349"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1298]["Ar content"] : ""}
                                {repoConn.answers["149"] == 0 && repoConn.answers["249"] == 0 && repoConn.answers["349"] == 1 ?
                                repoConn.referencess && repoConn.referencess[1299]["Ar content"] : ""}
                                {repoConn.answers["149"] == 0 && repoConn.answers["249"] == 0 && repoConn.answers["349"] == 0 ?
                                repoConn.referencess && repoConn.referencess[1300]["Ar content"] : ""}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div  className='FinlePage pdf-container-12'>
                <img className='ImgReport' src={fortynine} alt="ReportImge" />
                <div className="FinlePageLogo">
                <div className="Logo">
                    <img className="LogoImg" src={LogoWiht} alt="logo" />
                </div>
                </div>
            </div> 
            </div> 
        </>
    )
}

export default Report5