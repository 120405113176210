import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';


function SearchInput({ searchQuery, setSearchQuery }) {
  return (
    <TextField
      sx={{ borderRadius: '10px' }}
      id="Search"
      label="Search"
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      InputProps={{
        endAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      variant="outlined"
    />
  );
}

export default SearchInput;
