import { Button, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import AddCompanyForm from "./Forms/AddCompanyForm";
import { appContext } from "../../../../../App";
import Swal from "sweetalert2";
import { useSearch } from "../../Context/SearchContext";

function AccesCompanyComponent ({
  sentEnrollments,
  employees,
  companies,
  setshowPostions, setSelectedCompany,setRefetch,
}) {
          const [open, setOpen] = useState(false);
          let isLoggedIn = useContext(appContext);
  const { searchQuery } = useSearch();

          const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
            (acc, role) => {
              if (role.name === 'company-create') acc.companyCreate = true;
              return acc;
            },
            { companyCreate: false}
          );
      
        const hasPermission = () => { 
          if (isLoggedIn?.userData?.managed_by !== null) {
            if (!hasActionPermission.companyCreate) {
              Swal.fire({
                icon: 'error',
                title: 'Access Denied',
                text: 'You do not have permission to access this.',
                timer: 3000,
                showConfirmButton: true,
              });
            }
          }
        };
        const filteredcompanies = companies?.filter((product) =>
          product.name?.toLowerCase().includes(searchQuery?.toLowerCase())
        );
    return (
        <>
            <Button
              onClick={() => {
                hasPermission()
                if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.companyCreate){
                  setOpen(true)
                }
                }}
              variant="contained"
              sx={{
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
            >
            <AddIcon />
              Add Company
            </Button>
            {filteredcompanies?.filter((ele) => ele.exam_id === 65)
            .map((company) => {
              const companyEnrollments = sentEnrollments?.filter((enrollment) => {
                const employee = employees.find((emp) => enrollment.exam_id === 65 && emp.id === enrollment.user_id && emp.company_id === company.id);
                return employee ;
              });
              const enrollmentCount = companyEnrollments?.length;
                return (
                  <Stack
                    key={company.id}
                    p={2}
                    borderBottom="1px solid rgb(192, 192, 192)"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      onClick={() => {
                        setshowPostions(true)
                        setSelectedCompany(company)
                        }}
                      sx={{
                        width :'200px',
                        cursor: "pointer",
                        color: "#5e5e5e",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {company.name}
                    </Typography>
                    <Typography sx={{width :'150px', color: "#5e5e5e", textDecoration: "underline" }}>{company.phone}</Typography>
                    <Typography sx={{width :'220px', color: "#5e5e5e", textDecoration: "underline" }}>{company.mail}</Typography>
                    <Typography sx={{width :'100px', color: "#5e5e5e", textDecoration: "underline" }}>
                      {company.updated_at.slice(0, 10)}
                    </Typography>
                    <Typography sx={{ color: "#3f8e8f", border: '2.5px solid #3f8e8f', borderRadius: '12px', padding: '6px', fontWeight: 'bold' }}>
                      {enrollmentCount} {enrollmentCount === 1 ? "Assessment" : "Assessment"}
                    </Typography>
                </Stack>
                );
              })
        
    }
    <AddCompanyForm setRefetch={setRefetch} exam_id={65} open={open} setOpen={setOpen} />
    </>
    )
}

export default AccesCompanyComponent