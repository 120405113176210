import LogoBlu from "../../../FemilerCom/Logo/SLogoBlu"
import Logowhit from "../../../FemilerCom/Logo/BLogowhit"
import BigLogo from "../../../Media/Logo/Logo-big.png"
import one from "../../../Media/Twindexreport/one.webp"
import two from "../../../Media/Twindexreport/two.webp"
import three from "../../../Media/Twindexreport/three.webp"
import four from "../../../Media/Twindexreport/Four.webp"
import five from "../../../Media/Twindexreport/Five.webp"
import six from "../../../Media/Twindexreport/six.webp"
import seven from "../../../Media/Twindexreport/seven.webp"
import eight from "../../../Media/Twindexreport/Eight.webp"
import nine from "../../../Media/Twindexreport/Nine.webp"
import ten from "../../../Media/Twindexreport/ten.webp"
import { useContext, useEffect, useState } from "react"
import { repoCon } from "../ReportCom"
import Bidtetxt from "./BigText"
import { faL } from "@fortawesome/free-solid-svg-icons"
import { useNavigate } from "react-router"
import { appContext } from "../../../App"
import axios from "axios"
import { useSearchParams } from "react-router-dom"

function Report1 (props,index)  {
    const repoConn = useContext(repoCon)
    let releventAnswers1 = ["111","112","113","114","115", "116", "117", "118","119"];
    let releventAnswers2 = ["211", "212", "213", "214", "215", "216", "217", "218", "219"];
    let releventAnswers3 = ["121","122","123","124","125", "126", "127", "128","129"];
    let releventAnswers4 = ["311", "312", "313", "314", "315", "316", "317", "318", "319"];
    let releventAnswers5 = ["131", "132", "133", "134", "135", "136", "137", "138", "139"];
    let releventAnswers6 = ["141", "142", "143", "144", "145", "146", "147", "148", "149"];
    let releventAnswers7 = ["221", "222", "223", "224", "225", "226", "227", "228", "229"];
    let releventAnswers8 = ["321", "322", "323", "324", "325", "326", "327", "328", "329"];
    let releventAnswers9 = ["331", "332", "333", "334", "335", "336", "337", "338", "339"];
    let releventAnswers10 = ["231", "232", "233", "234", "235", "236", "237", "238", "239"];
    let releventAnswers11 = ["241", "242", "243", "244", "245", "246", "247", "248", "249"];
    let releventAnswers12 = ["341", "342", "343", "344", "345", "346", "347", "348", "349"];


    // let releventAnswers3 = ["311", "312", "313", "314", "315", "316", "317", "318", "319"]
    let commonValuesToAdd = [7, 9, 15, 10, 12, 11, 14, 9, 7];
    let commonValuesToAdd3 = [6, 14, 13, 11, 10, 12, 8, 7, 9];
    let commonValuesToAdd4 = [13, 14, 11, 10, 11, 12, 9, 7, 8];
    let commonValuesToAdd5 = [12, 5, 15, 14, 8, 11, 7, 10, 9];
    
            useEffect(() => {
                

                for (let i = 0; i < releventAnswers1.length; i++) {
                    const answerKey = releventAnswers1[i];
                    const answerKey2 = releventAnswers2[i];
                    const answerKey3 = releventAnswers3[i];
                    const answerKey4 = releventAnswers4[i];
                    const answerKey5 = releventAnswers5[i];
                    const answerKey6 = releventAnswers6[i];
                    const answerKey7 = releventAnswers7[i];
                    const answerKey8 = releventAnswers8[i];
                    const answerKey9 = releventAnswers9[i];
                    const answerKey10 = releventAnswers10[i];
                    const answerKey11 = releventAnswers11[i];
                    const answerKey12 = releventAnswers12[i];
                    const commonValue = commonValuesToAdd[i];
                    const commonValue3 = commonValuesToAdd3[i];
                    const commonValue4 = commonValuesToAdd4[i];
                    const commonValue5 = commonValuesToAdd5[i];
                  
                    if (repoConn.answers[answerKey] == 1) {
                      repoConn.references["R1"] += commonValue;//
                      repoConn.references["R4"] += commonValue;//
                      repoConn.references["R49"] += commonValue;//
                      repoConn.references["R53"] += commonValue; // Add R53 to each one
                      repoConn.references["R245"] += commonValue; // Add R53 to each one
                      repoConn.references["R248"] += commonValue; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey] == 0) {
                      repoConn.references["R7"] += commonValue; //
                      repoConn.references["R10"] += commonValue;
                      repoConn.references["R57"] += commonValue;
                      repoConn.references["R61"] += commonValue; // Add R53 to each one
                      repoConn.references["R251"] += commonValue; // Add R53 to each one
                      repoConn.references["R254"] += commonValue; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey2] == 1) {
                      repoConn.references["R2"] += commonValue;
                      repoConn.references["R5"] += commonValue;
                      repoConn.references["R69"] += commonValue;
                      repoConn.references["R73"] += commonValue; // Add R53 to each one
                      repoConn.references["R246"] += commonValue; // Add R53 to each one
                      repoConn.references["R249"] += commonValue; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey2] == 0) {
                      repoConn.references["R8"] += commonValue;
                      repoConn.references["R11"] += commonValue;
                      repoConn.references["R77"] += commonValue;
                      repoConn.references["R81"] += commonValue; // Add R53 to each one
                      repoConn.references["R252"] += commonValue; // Add R53 to each one
                      repoConn.references["R255"] += commonValue; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey3] == 1) {
                      repoConn.references["R13"] += commonValue3;
                      repoConn.references["R16"] += commonValue3;
                      repoConn.references["R50"] += commonValue3;
                      repoConn.references["R54"] += commonValue3; // Add R53 to each one
                      repoConn.references["R284"] += commonValue3; // Add R53 to each one
                      repoConn.references["R287"] += commonValue3; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey3] == 0) {
                      repoConn.references["R19"] += commonValue3;
                      repoConn.references["R22"] += commonValue3;
                      repoConn.references["R58"] += commonValue3;
                      repoConn.references["R62"] += commonValue3; // Add R53 to each one
                      repoConn.references["R290"] += commonValue3; // Add R53 to each one
                      repoConn.references["R293"] += commonValue3; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey7] == 1) {
                      repoConn.references["R14"] += commonValue3;
                      repoConn.references["R17"] += commonValue3;
                      repoConn.references["R70"] += commonValue3;
                      repoConn.references["R74"] += commonValue3; // Add R53 to each one
                      repoConn.references["R285"] += commonValue3; // Add R53 to each one
                      repoConn.references["R288"] += commonValue3; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey7] == 0) {
                      repoConn.references["R20"] += commonValue3;
                      repoConn.references["R23"] += commonValue3;
                      repoConn.references["R78"] += commonValue3;
                      repoConn.references["R82"] += commonValue3; // Add R53 to each one
                      repoConn.references["R294"] += commonValue3; // Add R53 to each one
                      repoConn.references["R291"] += commonValue3; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey8] == 1) {
                      repoConn.references["R15"] += commonValue3;
                      repoConn.references["R18"] += commonValue3;
                      repoConn.references["R158"] += commonValue3;
                      repoConn.references["R162"] += commonValue3; // Add R53 to each one
                      repoConn.references["R286"] += commonValue3; // Add R53 to each one
                      repoConn.references["R289"] += commonValue3; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey8] == 0) {
                      repoConn.references["R21"] += commonValue3;
                      repoConn.references["R24"] += commonValue3;
                      repoConn.references["R166"] += commonValue3;
                      repoConn.references["R170"] += commonValue3; // Add R53 to each one
                      repoConn.references["R295"] += commonValue3; // Add R53 to each one
                      repoConn.references["R292"] += commonValue3; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey4] == 1) {
                      repoConn.references["R3"] += commonValue;
                      repoConn.references["R6"] += commonValue;
                      repoConn.references["R157"] += commonValue;
                      repoConn.references["R161"] += commonValue; // Add R53 to each one
                      repoConn.references["R247"] += commonValue; // Add R53 to each one
                      repoConn.references["R250"] += commonValue; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey4] == 0) {
                      repoConn.references["R9"] += commonValue;
                      repoConn.references["R12"] += commonValue;
                      repoConn.references["R165"] += commonValue;
                      repoConn.references["R169"] += commonValue; // Add R53 to each one
                      repoConn.references["R253"] += commonValue; // Add R53 to each one
                      repoConn.references["R256"] += commonValue; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey5] == 1) {
                      repoConn.references["R25"] += commonValue4;
                      repoConn.references["R28"] += commonValue4;
                      repoConn.references["R51"] += commonValue4;
                      repoConn.references["R55"] += commonValue4; // Add R53 to each one
                      repoConn.references["R323"] += commonValue4; // Add R53 to each one
                      repoConn.references["R326"] += commonValue4; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey5] == 0) {
                      repoConn.references["R31"] += commonValue4;
                      repoConn.references["R34"] += commonValue4;
                      repoConn.references["R59"] += commonValue4;
                      repoConn.references["R63"] += commonValue4; // Add R53 to each one
                      repoConn.references["R332"] += commonValue4; // Add R53 to each one
                      repoConn.references["R329"] += commonValue4; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey9] == 1) {
                      repoConn.references["R27"] += commonValue4;
                      repoConn.references["R30"] += commonValue4;
                      repoConn.references["R159"] += commonValue4;
                      repoConn.references["R163"] += commonValue4; // Add R53 to each one
                      repoConn.references["R325"] += commonValue4; // Add R53 to each one
                      repoConn.references["R328"] += commonValue4; // Add R53 to each one
                    }
                    
                    if (repoConn.answers[answerKey9] == 0) {
                      repoConn.references["R33"] += commonValue4;
                      repoConn.references["R36"] += commonValue4;
                      repoConn.references["R167"] += commonValue4;
                      repoConn.references["R171"] += commonValue4; // Add R53 to each one
                      repoConn.references["R331"] += commonValue4; // Add R53 to each one
                      repoConn.references["R334"] += commonValue4; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey10] == 1) {
                      repoConn.references["R26"] += commonValue4;
                      repoConn.references["R29"] += commonValue4;
                      repoConn.references["R71"] += commonValue4;
                      repoConn.references["R75"] += commonValue4; // Add R53 to each one
                      repoConn.references["R324"] += commonValue4; // Add R53 to each one
                      repoConn.references["R327"] += commonValue4; // Add R53 to each one
                    }
                    
                    if (repoConn.answers[answerKey10] == 0) {
                      repoConn.references["R32"] += commonValue4;
                      repoConn.references["R35"] += commonValue4;
                      repoConn.references["R79"] += commonValue4;
                      repoConn.references["R83"] += commonValue4; // Add R53 to each one
                      repoConn.references["R330"] += commonValue4; // Add R53 to each one
                      repoConn.references["R333"] += commonValue4; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey6] == 1) {
                      repoConn.references["R37"] += commonValue5;
                      repoConn.references["R40"] += commonValue5;
                      repoConn.references["R52"] += commonValue5;
                      repoConn.references["R56"] += commonValue5; // Add R53 to each one
                      repoConn.references["R362"] += commonValue5; // Add R53 to each one
                      repoConn.references["R365"] += commonValue5; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey6] == 0) {
                      repoConn.references["R43"] += commonValue5;
                      repoConn.references["R46"] += commonValue5;
                      repoConn.references["R60"] += commonValue5;
                      repoConn.references["R64"] += commonValue5; // Add R53 to each one
                      repoConn.references["R371"] += commonValue5; // Add R53 to each one
                      repoConn.references["R368"] += commonValue5; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey11] == 1) {
                      repoConn.references["R38"] += commonValue5;
                      repoConn.references["R41"] += commonValue5;
                      repoConn.references["R72"] += commonValue5;
                      repoConn.references["R76"] += commonValue5; // Add R53 to each one
                      repoConn.references["R363"] += commonValue5; // Add R53 to each one
                      repoConn.references["R366"] += commonValue5; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey11] == 0) {
                      repoConn.references["R44"] += commonValue5;
                      repoConn.references["R47"] += commonValue5;
                      repoConn.references["R80"] += commonValue5;
                      repoConn.references["R84"] += commonValue5; // Add R53 to each one
                      repoConn.references["R372"] += commonValue5; // Add R53 to each one
                      repoConn.references["R369"] += commonValue5; // Add R53 to each one
                    }



                    if (repoConn.answers[answerKey12] == 1) {
                      repoConn.references["R39"] += commonValue5;
                      repoConn.references["R42"] += commonValue5;
                      repoConn.references["R160"] += commonValue5;
                      repoConn.references["R164"] += commonValue5; // Add R53 to each one
                      repoConn.references["R364"] += commonValue5; // Add R53 to each one
                      repoConn.references["R367"] += commonValue5; // Add R53 to each one
                    }
                    if (repoConn.answers[answerKey12] == 0) {
                      repoConn.references["R45"] += commonValue5;
                      repoConn.references["R48"] += commonValue5;
                      repoConn.references["R373"] += commonValue5; // Add R53 to each one
                      repoConn.references["R168"] += commonValue5;
                      repoConn.references["R172"] += commonValue5; // Add R53 to each one
                      repoConn.references["R370"] += commonValue5; // Add R53 to each one
                    }
                  }

                  function sortElementsByClass(containerId, className, appearFirst = true) {
                    const containerElement = document.getElementById(containerId);
                
                    if (!containerElement) {
                        console.error(`Element with ID '${containerId}' not found.`);
                        return;
                    }
                
                    const childElements = Array.from(containerElement.children);
                
                    const withClass = [];
                    const withoutClass = [];
                
                    childElements.forEach((child) => {
                        if (child.classList.contains(className)) {
                            withClass.push(child);
                        } else {
                            withoutClass.push(child);
                        }
                    });
                
                    const sortedChildElements = appearFirst ? withClass.concat(withoutClass) : withoutClass.concat(withClass);
                
                    containerElement.innerHTML = '';
                
                    sortedChildElements.forEach((child) => {
                        containerElement.appendChild(child);
                    });
                }
                
                sortElementsByClass("con1", "BackGroundBlueBold", false);
                sortElementsByClass("con2", "BackGroundBlueSco", true); // Reversed order for elements with class "BackGroundBlueSco"
                
                function giveBorderReduce (containerId1, containerId2) {

                    let getalleleBold = document.getElementsByClassName("BackGroundBlueBold")
                    let getalleleSco = document.getElementsByClassName("BackGroundBlueSco")
                    let putalleletoattaryBold = Array.from(getalleleBold)
                    let putalleletoattarySco = Array.from(getalleleSco)
                    let con1 = document.getElementById("con1")
                    let con2 = document.getElementById("con2")
                    let conarr1 = Array.from(con1.children)
                    let conarr2 = Array.from(con2.children)

                        putalleletoattaryBold.forEach((ele, index) => {
                            if (index === putalleletoattaryBold.length - 2) {
                                let span = ele.querySelector(".ManyPhraseBoxline");
                                if (span) {
                                    ele.removeChild(span);
                                }
                            }
                            ele.classList.remove("ManyPhraseBordertop");
                            if (containerId2 === 'con1' && index === 0) {
                                ele.classList.add("ManyPhraseBordertop");
                            }
                        });
                        putalleletoattarySco.forEach((ele, index) => {
                            if (index === putalleletoattarySco.length - 1) {
                                let span = ele.querySelector(".ManyPhraseBoxlineLast");
                                if (span) {
                                    // console.log(span)
                                    span.classList.add("display")
                                }
                            }
                            if (ele.classList.contains("last")){
                                ele.classList.add("ManyPhraseBorderbottom"); 
                            }
                        });
                        setTimeout(() => {
                            let arr1 = [];
                            let arr2 = new Set();
                            for (let i = 0 ; i < conarr1.length - 1; i++) {
                                arr1.push(conarr1[i].children[0].textContent.trim());
                                for(let j = 0 ; j < conarr2.length; j++) {
                                    arr2.add(conarr2[j].children[0].textContent.trim());
                                }
                            }
                            
                            // console.log(arr1);
                            let arr3 = Array.from(arr2)
                            // console.log(arr3);       
                        }, 1000)
                }
                giveBorderReduce("con2", "con1")
                }, [])


                const [currentUserr, setCurrentUser] = useState(null);
                const navigate = useNavigate();
                const username = useContext(appContext);
                function logout(e) {
                    e.preventDefault();
                    axios.delete("https://api.twindix.com/auth/logout", {headers:{
                        Authorization:"Bearer " + localStorage.token
                    }}).then(res => {
                        // console.log("logged out successfully");
                        localStorage.removeItem("token");
                        localStorage.removeItem("isAdmin");
                        localStorage.removeItem("isLogged");
                        localStorage.removeItem("userType");
                        username.setUserToken(null);
                        username.setLoggedin(null);
                        username.setUserType(null);
                        window.location.reload()
            
                    }).catch(err => console.log(err))
                }
                function Charts (upchart, downchart)  {
                    let s = 200 - (upchart + downchart);
                    upchart = Math.ceil((upchart + Math.ceil(s*upchart / 100)) / 1.97);
                    downchart = Math.ceil((downchart + Math.ceil(s*downchart / 100)) / 1.97);
                    // console.log(upchart , downchart)
                    return { upchart, downchart };
                }

                let R18 = repoConn.references["R18"];
                let R17 = repoConn.references["R17"];
                let R16 = repoConn.references["R16"];
                let R24 = repoConn.references["R24"];
                let R23 = repoConn.references["R23"];
                let R22 = repoConn.references["R22"];
                const result1 = Charts(R18, R24);
                const result2 = Charts(R17, R23);
                const result3 = Charts(R16, R22);
                
                const SR18 = {
                    height: `${result1.upchart}%`,
                };
                const SR17 = {
                    height: `${result2.upchart}%`,
                };
                const SR16 = {
                    height: `${result3.upchart}%`,
                };
                const SR24 = {
                    height: `${result1.downchart}%`,
                };
                const SR23 = {
                    height: `${result2.downchart}%`,
                };
                const SR22 = {
                    height: `${result3.downchart}%`,
                };
/////////
                let R6 = repoConn.references["R6"];
                let R5 = repoConn.references["R5"];
                let R4 = repoConn.references["R4"];

                let R12 = repoConn.references["R12"];
                let R11 = repoConn.references["R11"];
                let R10 = repoConn.references["R10"];
                
                const result4 = Charts(R6, R12);
                const result5 = Charts(R5, R11);
                const result6 = Charts(R4, R10);

                const SR6 = {
                    height: `${result4.upchart}%`,
                };
                const SR5 = {
                    height: `${result5.upchart}%`,
                };
                const SR4 = {
                    height: `${result6.upchart}%`,
                };
                const SR12 = {
                    height: `${result4.downchart}%`,
                };
                const SR11 = {
                    height: `${result5.downchart}%`,
                };
                const SR10 = {
                    height: `${result6.downchart}%`,
                };
                /////
                let R42 = repoConn.references["R42"];
                let R41 = repoConn.references["R41"];
                let R40 = repoConn.references["R40"];

                let R48 = repoConn.references["R48"];
                let R47 = repoConn.references["R47"];
                let R46= repoConn.references["R46"];

                const result7 = Charts(R42, R48);
                const result8 = Charts(R41, R47);
                const result9 = Charts(R40, R46);

                const SR42 = {
                    height: `${result7.upchart}%`,
                };
                const SR41 = {
                    height: `${result8.upchart}%`,
                };
                const SR40 = {
                    height: `${result9.upchart}%`,
                };
                const SR48 = {
                    height: `${result7.downchart}%`,
                };
                const SR47 = {
                    height: `${result8.downchart}%`,
                };
                const SR46 = {
                    height: `${result9.downchart}%`,
                };
                ////
                let R30 = repoConn.references["R30"];
                let R29 = repoConn.references["R29"];
                let R28 = repoConn.references["R28"];
                
                let R36 = repoConn.references["R36"];
                let R35 = repoConn.references["R35"];
                let R34 = repoConn.references["R34"];
                
                const result10 = Charts(R30, R36);
                const result11 = Charts(R29, R35);
                const result12 = Charts(R28, R34);
                
                const SR30 = {
                    height: `${result10.upchart}%`,
                };
                const SR29 = {
                    height: `${result11.upchart}%`,
                };
                const SR28 = {
                    height: `${result12.upchart}%`,
                };
                const SR36 = {
                    height: `${result10.downchart}%`,
                };
                const SR35 = {
                    height: `${result11.downchart}%`,
                };
                const SR34 = {
                    height: `${result12.downchart}%`,
                };
                

                let R56 = repoConn.references["R56"];
                let R55 = repoConn.references["R55"];
                let R54 = repoConn.references["R54"];
                let R53 = repoConn.references["R53"];

                let R64 = repoConn.references["R64"];
                let R63 = repoConn.references["R63"];
                let R62 = repoConn.references["R62"];
                let R61 = repoConn.references["R61"];
                
                // console.log(R56, R64 )
                
                const result13 = Charts(R56, R64);
                const result14 = Charts(R55, R63);
                const result15 = Charts(R54, R62);
                const result16 = Charts(R53, R61);
                
                const SR56 = {
                    height: `${result13.upchart}%`,
                };
                const SR55 = {
                    height: `${result14.upchart}%`,
                };
                const SR54 = {
                    height: `${result15.upchart}%`,
                };
                const SR53 = {
                    height: `${result16.upchart}%`,
                };
                const SR64 = {
                    height: `${result13.downchart}%`,
                };
                const SR63 = {
                    height: `${result14.downchart}%`,
                };
                const SR62 = {
                    height: `${result15.downchart}%`,
                };
                const SR61 = {
                    height: `${result16.downchart}%`,
                };
                

                const [currentDate, setCurrentDate] = useState('');
                
                useEffect(() => {
                    // Function to update the current date
                    const updateDate = () => {
                        const dateObj = new Date();
                        const day = dateObj.getDate();
                        const month = dateObj.getMonth() + 1; // Months are zero-based
                        const year = dateObj.getFullYear();
                        const formattedDate = `${day < 10 ? '0' : ''}${day}/${month < 10 ? '0' : ''}${month}/${year}`;
                        setCurrentDate(formattedDate);
                    };
            
                    // Initial call to update the date
                    updateDate();
                    
                    // Set interval to update the date every day
                    const intervalId = setInterval(updateDate, 86400000); // 24 hours in milliseconds
                    
                    // Clean up the interval on component unmount
                    return () => clearInterval(intervalId);
                }, []);
                const [searchParams] = useSearchParams();
                    const name = searchParams.get("name");
                
    return (
            <>
                <div id="First" >
                <div id='Cover' className='Cover pdf-container-1' style={{width :'101%'}}>
                    <img className='ImgReport' src={one} alt="ReportImge" />
                    <div className='BigCover'>
                        <div className='Tilte'>
                            <span>{repoConn.referencess ? repoConn.referencess[0][props.lang === 'en' ? 'en content' : 'Ar content'] : ""}</span>
                        </div>
                        <div className='div'>
                            <span>{repoConn.referencess ? repoConn.referencess[1][props.lang === 'en' ? 'en content' : 'Ar content'] : ""}</span>
                        </div>
                        <div className='DivLogo'>
                                <div className='Logo'>
                                    <div className="Logo">
                                        <img className="LogoImg" src={BigLogo} alt="logo" />
                                    </div>
                                </div>
                        </div>
                        <div className='PersonDetils'>
                            <span>{name}</span>
                            <span>{props.date}</span>
                        </div>
                    </div>
                </div>
                <div className='Intro pdf-container-1'>
                    <img className='ImgReport' src={two} alt="ReportImge" />
                    <div className='AsidLogo'>
                        <LogoBlu />
                    </div>
                    <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess ? repoConn.referencess[2][props.lang === 'en' ? 'en content' : 'Ar content'] : ""}</div>
                            </div>
                        </div>
                    </div>
                    <div className='IntroText'>
                        <div className='Text'>
                        {repoConn.referencess ? repoConn.referencess[3][props.lang === 'en' ? 'en content' : 'Ar content']: ""}
                        <br/>
                        <br/>
                        {repoConn.referencess ?repoConn.referencess[4][props.lang === 'en' ? 'en content' : 'Ar content']: ""}
                        </div>
                    </div>
                    <div className="IntroTowBox">
                        <div className="TowBoxContaner">
                            <div className="BoxOne"> 
                                    <h2>المؤشرات المقابلة</h2>
                                <div className="NastedBox">
                                    <div>
                                        <span>التلقائي</span>
                                    </div>
                                    <div>
                                        <span>التركيزي</span>
                                    </div>
                                    <div>
                                        <span>الخبراتي</span>
                                    </div>
                                    <div>
                                        <span>الاستنزافي</span>
                                    </div>
                                </div>
                            </div>
                            <div className="BoxTow"> 
                                    <h2>المؤشرات الأساسية</h2>
                                <div className="NastedBox">
                                    <div>
                                        <span>التخطيطي</span>
                                    </div>
                                    <div>
                                        <span>الشمولي</span>
                                    </div>
                                    <div>
                                        <span>العلمي</span>
                                    </div>
                                    <div>
                                        <span>التحققي</span>
                                    </div>
                                </div>
                            </div>
                            </div>
                    </div>
                </div>
                <div className='ManyGraphs-1 pdf-container-2'>
                    <img className='ImgReport' src={five} alt="ReportImge" />
                    <div className='AsidLogo'>
                        <LogoBlu />
                    </div>
                    <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[50][props.lang === 'en' ? 'en content' : 'Ar content']}</div>
                            </div>
                        </div>
                    </div>                 
                    <div className="Graph-1 ">
                        <div className="TopBox">
                            <div> 
                                <span >{repoConn.referencess && repoConn.referencess[56][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[60][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[59][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[58][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{result1.upchart}%</span>
                                <span>{result2.upchart}%</span>
                                <span>{result3.upchart}%</span>
                            </div>
                        </div>
                        <div className="Graph-Top-1">
                            <div className="Canvas" style={SR18}></div>
                            <div className="Canvas" style={SR17}></div>
                            <div className="Canvas" style={SR16}></div>
                        </div>
                        <div className="Graph-Bottom-1">
                            <div className="Canvas" style={SR24}></div>
                            <div className="Canvas" style={SR23}></div>
                            <div className="Canvas" style={SR22}></div>
                        </div>
                        <div className="BottomBox">
                            <div>
                                <span>{result1.downchart}%</span>
                                <span>{result2.downchart}%</span>
                                <span>{result3.downchart}%</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[60][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[59][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[58][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div> 
                                <span >{repoConn.referencess && repoConn.referencess[57][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                        </div>
                    </div>
                    <div className="Graph-2">
                    <div className="TopBox">
                            <div> 
                                <span >{repoConn.referencess && repoConn.referencess[51][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[55][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[54][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[53][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{result4.upchart}%</span>
                                <span>{result5.upchart}%</span>
                                <span>{result6.upchart}%</span>
                            </div>
                        </div>
                        <div className="Graph-Top-1">
                            <div className="Canvas" style={SR6}></div>
                            <div className="Canvas" style={SR5}></div>
                            <div className="Canvas" style={SR4}></div>
                        </div>
                        <div className="Graph-Bottom-1">
                            <div className="Canvas" style={SR12}></div>
                            <div className="Canvas" style={SR11}></div>
                            <div className="Canvas" style={SR10}></div>
                        </div>
                        <div className="BottomBox">
                            <div>
                                <span>{result4.downchart}%</span>
                                <span>{result5.downchart}%</span>
                                <span>{result6.downchart}%</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[55][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[54][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[53][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div> 
                                <span >{repoConn.referencess && repoConn.referencess[52][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                        </div>
                    </div>
                    <div className="Graph-3">
                    <div className="TopBox">
                            <div> 
                                <span >{repoConn.referencess && repoConn.referencess[66][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[65][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[64][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[63][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{result7.upchart}%</span>
                                <span>{result8.upchart}%</span>
                                <span>{result9.upchart}%</span>
                            </div>
                        </div>
                        <div className="Graph-Top-1">
                            <div className="Canvas" style={SR42}></div>
                            <div className="Canvas" style={SR41}></div>
                            <div className="Canvas" style={SR40}></div>
                        </div>
                        <div className="Graph-Bottom-1">
                            <div className="Canvas" style={SR48}></div>
                            <div className="Canvas" style={SR47}></div>
                            <div className="Canvas" style={SR46}></div>
                        </div>
                        <div className="BottomBox">
                            <div>
                                <span>{result7.downchart}%</span>
                                <span>{result8.downchart}%</span>
                                <span>{result9.downchart}%</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[65][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[64][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[63][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div> 
                                <span >{repoConn.referencess && repoConn.referencess[67][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                        </div>
                    </div>
                    <div className="Graph-4">
                    <div className="TopBox">
                            <div> 
                                <span >{repoConn.referencess && repoConn.referencess[61][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[70][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[69][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[68][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{result10.upchart}%</span>
                                <span>{result11.upchart}%</span>
                                <span>{result12.upchart}%</span>
                            </div>
                        </div>
                        <div className="Graph-Top-1">
                            <div className="Canvas" style={SR30}></div>
                            <div className="Canvas" style={SR29}></div>
                            <div className="Canvas" style={SR28}></div>
                        </div>
                        <div className="Graph-Bottom-1">
                            <div className="Canvas" style={SR36}></div>
                            <div className="Canvas" style={SR35}></div>
                            <div className="Canvas" style={SR34}></div>
                        </div>
                        <div className="BottomBox">
                            <div>
                                <span>{result10.downchart}%</span>
                                <span>{result11.downchart}%</span>
                                <span>{result12.downchart}%</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[70][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[69][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[68][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div> 
                                <span >{repoConn.referencess && repoConn.referencess[62][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div  className='SkillsScoCover pdf-container-2'>
                    <img className='ImgReport' src={six} alt="ReportImge" />
                    <div className='AsidLogo'>
                        <Logowhit />
                    </div>
                    <div className="Text">{repoConn.referencess && repoConn.referencess[71][props.lang === 'en' ? 'en content' : 'Ar content']}</div>
                </div>
                <div className='BigOneGraph-1 pdf-container-3'> 
                    <img className='ImgReport' src={seven} alt="ReportImge" />
                    <div className='AsidLogo'>
                        <LogoBlu />
                    </div>
                    <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[72][props.lang === 'en' ? 'en content' : 'Ar content']}</div>
                            </div>
                        </div>
                    </div>             
                    <div className="Graph-1 ">
                        <div className="TopBox">
                            <div></div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[74][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[75][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[76][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[77][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                            <div>
                                <span>{result13.upchart}%</span>
                                <span>{result14.upchart}%</span>
                                <span>{result15.upchart}%</span>
                                <span>{result16.upchart}%</span>
                            </div>
                        </div>
                        <div className="Graph-Top-1">
                            <div className="BigCanvas" style={SR56}></div>
                            <div className="BigCanvas" style={SR55}></div>
                            <div className="BigCanvas" style={SR54}></div>
                            <div className="BigCanvas" style={SR53}></div>
                        </div>
                        <div className="Graph-Bottom-1">
                            <div className="BigCanvas" style={SR64}></div>
                            <div className="BigCanvas" style={SR63}></div>
                            <div className="BigCanvas" style={SR62}></div>
                            <div className="BigCanvas" style={SR61}></div>
                        </div>
                        <div className="BottomBox">
                            <div>
                                <span>{result13.downchart}%</span>
                                <span>{result14.downchart}%</span>
                                <span>{result15.downchart}%</span>
                                <span>{result16.downchart}%</span>
                            </div>
                            <div>
                                <span>{repoConn.referencess && repoConn.referencess[81][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[80][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[79][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                                <span>{repoConn.referencess && repoConn.referencess[78][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='GoodSkillEmpty pdf-container-3'>
                    <img className='ImgReport' src={ten} alt="ReportImge" />
                    <div className='AsidLogo'>
                        <LogoBlu />
                    </div>
                    <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[82][props.lang === 'en' ? 'en content' : 'Ar content']}</div>
                            </div>
                        </div>
                    </div>                 
                    <h1 className='H1Text'>
                    {repoConn.referencess && repoConn.referencess[83][props.lang === 'en' ? 'en content' : 'Ar content']}
                    </h1>
                    <div className='BigText'>
                    {repoConn.answers["111"] == 1 ? repoConn.referencess && repoConn.referencess[84][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[85][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["112"] == 1 ? repoConn.referencess && repoConn.referencess[86][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[87][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["113"] == 1 ? repoConn.referencess && repoConn.referencess[88][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[89][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["114"] == 1 ? repoConn.referencess && repoConn.referencess[90][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[91][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["115"] == 1 ? repoConn.referencess && repoConn.referencess[92][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[93][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["116"] == 1 ? repoConn.referencess && repoConn.referencess[94][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[95][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["117"] == 1 ? repoConn.referencess && repoConn.referencess[96][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[97][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["118"] == 1 ? repoConn.referencess && repoConn.referencess[98][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[99][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["119"] == 1 ? repoConn.referencess && repoConn.referencess[100][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[101][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    </div>
                </div>
                <div className='ManyPhraseInSkills-1 pdf-container-3'>
                    <img className='ImgReport' src={nine} alt="ReportImge" />
                    <div className='AsidLogo'>
                        <LogoBlu />
                    </div>
                    <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[102][props.lang === 'en' ? 'en content' : 'Ar content']}</div>
                            </div>
                        </div>
                    </div>               
                    <div className='SmallIntroBatrn'>{repoConn.referencess && repoConn.referencess[103][props.lang === 'en' ? 'en content' : 'Ar content']}</div>
                    <div className="ManyPhraseBox">
                    <div id="con1" className=" con1">
                        <div className={repoConn.answers["111"] == 1 ?  " BackGroundBlueBold mb-0 PhraseText text-white" : " PhraseText"}>
                            <span style={{top: "28px"}}>{repoConn.referencess && repoConn.referencess[105][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["111"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["112"] == 1 ? " BackGroundBlueBold mb-0 PhraseText text-white" : "  PhraseText"}>
                            <span style={{top: "28px"}}>{repoConn.referencess && repoConn.referencess[106][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["112"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["113"] == 1 ? " BackGroundBlueBold mb-0 PhraseText text-white" : " PhraseText"}>
                            <span style={{top: "25px"}}>{repoConn.referencess && repoConn.referencess[107][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["113"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                            <div className={repoConn.answers["114"] == 1 ? " BackGroundBlueBold mb-0 PhraseText text-white" : " PhraseText"}>
                                <span style={{top: "27px"}}>{repoConn.referencess && repoConn.referencess[108][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["114"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                            <div className={repoConn.answers["115"] == 1 ? " BackGroundBlueBold mb-0 PhraseText text-white" : " PhraseText"}>
                                <span style={{top: "24px"}}>{repoConn.referencess && repoConn.referencess[109][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["115"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                            <div className={repoConn.answers["116"]  == 1? " BackGroundBlueBold mb-0 PhraseText text-white" : " PhraseText"}>
                                <span style={{top: "15px"}}>{repoConn.referencess && repoConn.referencess[110][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["116"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                            <div className={repoConn.answers["117"] == 1 ? " BackGroundBlueBold mb-0 PhraseText text-white" : " PhraseText"}>
                                <span style={{top: "15px"}}>{repoConn.referencess && repoConn.referencess[111][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["117"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                            <div className={repoConn.answers["118"]  == 1 ? " BackGroundBlueBold mb-0 PhraseText text-white" : " PhraseText"}>
                                <span style={{top: "15px"}}>{repoConn.referencess && repoConn.referencess[112][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["118"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                            <div className={repoConn.answers["119"]  == 1 ? " BackGroundBlueBold mb-0 PhraseText text-white" : " PhraseText"}>
                                <span >{repoConn.referencess && repoConn.referencess[113][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["119"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                            <div id="innerCon1" className="innerCon w-100 BackGroundBlueBold m-0"></div>
                        </div>
                            <div id="con2" className=" con2">
                            <div className={repoConn.answers["111"]  == 0 ? "BackGroundBlueSco   PhraseText mb-0 text-white" : " PhraseText bottom"}>
                                <span >{repoConn.referencess && repoConn.referencess[114][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["111"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                            <div className={repoConn.answers["112"]  == 0 ? "BackGroundBlueSco   PhraseText mb-0 text-white" : " PhraseText bottom"}>
                                <span >{repoConn.referencess && repoConn.referencess[115][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["112"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                            <div className={repoConn.answers["113"]  == 0 ? "BackGroundBlueSco   PhraseText mb-0 text-white" : " PhraseText bottom"}>
                                <span >{repoConn.referencess && repoConn.referencess[116][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["113"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                            </div>
                        <div className={repoConn.answers["114"]  == 0 ? "BackGroundBlueSco   PhraseText mb-0 text-white" : " PhraseText bottom"}>
                            <span >{repoConn.referencess && repoConn.referencess[117][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["114"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["115"]  == 0 ? "BackGroundBlueSco   PhraseText mb-0 text-white" : " PhraseText bottom"}>
                            <span >{repoConn.referencess && repoConn.referencess[118][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["115"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["116"]  == 0 ? "BackGroundBlueSco    PhraseText mb-0 text-white" : " PhraseText bottom"}>
                            <span >{repoConn.referencess && repoConn.referencess[119][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["116"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["117"]  == 0 ? "BackGroundBlueSco   PhraseText mb-0 text-white" : " PhraseText bottom"}>
                            <span >{repoConn.referencess && repoConn.referencess[120][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["117"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["118"]  == 0 ? "BackGroundBlueSco   PhraseText mb-0 text-white" : " PhraseText bottom"}>
                            <span >{repoConn.referencess && repoConn.referencess[121][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["118"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["119"]  == 0 ? "BackGroundBlueSco last  PhraseText mb-0  text-white" : " PhraseText bottom"}>
                            <span >{repoConn.referencess && repoConn.referencess[122][props.lang === 'en' ? 'en content' : 'Ar content']}</span>
                            <span className={repoConn.answers["119"] == 0 ? "ManyPhraseBoxlineLast" : "ManyPhraseBoxlineLast display"} ></span>
                        </div>
                            </div>
                    </div>
                    <div className='SmallIntroBatrnBottom'>{repoConn.referencess && repoConn.referencess[104][props.lang === 'en' ? 'en content' : 'Ar content']}</div>

                </div>
                <div className='Empaty-3Report1 pdf-container-3'>
                    <img className='ImgReport' src={ten} alt="Reportimg" />
                    <div className='AsidLogo'>
                        <LogoBlu />
                    </div>
                    <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[123][props.lang === 'en' ? 'en content' : 'Ar content']}</div>
                            </div>
                        </div>
                    </div>                 
                    <h1 className='H1Text'>
                    {repoConn.referencess && repoConn.referencess[124][props.lang === 'en' ? 'en content' : 'Ar content']}
                    </h1>
                    <div className='BigText'>
                    {repoConn.answers["121"] == 1 ? repoConn.referencess && repoConn.referencess[125][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[126][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["122"] == 1 ? repoConn.referencess && repoConn.referencess[127][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[128][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["123"] == 1 ? repoConn.referencess && repoConn.referencess[129][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[130][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["124"] == 1 ? repoConn.referencess && repoConn.referencess[131][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[132][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["125"] == 1 ? repoConn.referencess && repoConn.referencess[133][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[134][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["126"] == 1 ? repoConn.referencess && repoConn.referencess[135][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[136][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["127"] == 1 ? repoConn.referencess && repoConn.referencess[137][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[138][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["128"] == 1 ? repoConn.referencess && repoConn.referencess[139][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[140][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    {repoConn.answers["129"] == 1 ? repoConn.referencess && repoConn.referencess[141][props.lang === 'en' ? 'en content' : 'Ar content'] 
                    : repoConn.referencess && repoConn.referencess[142][props.lang === 'en' ? 'en content' : 'Ar content']}
                    <br />
                    </div>
                </div>
                </div>
            </>
    )
}

export default Report1