import { Box, Stack } from '@mui/material';
import P17 from '../../../Media/ManCode/P17.jpg';
import P18 from '../../../Media/ManCode/P18.jpg';
import P19 from '../../../Media/ManCode/P19.jpg';
import P20 from '../../../Media/ManCode/P20.jpg';
import P21 from '../../../Media/ManCode/P21.jpg';
import P22 from '../../../Media/ManCode/P22.jpg';
import { FirstSection, ForthSection, Header, Page, ScoSection, ThirdSection } from './Share';
import { useManCode3 } from './Data3';

function Thirdpart({benchmark, referencess, answers, lang, name , date}) {

    const {
      sortedBoxes11,
      sortedBoxes12,
      sortedBoxes13,
      sortedBoxes14,
      sortedBoxes15,
      sortedBoxes11bench,
      sortedBoxes12bench,
      sortedBoxes13bench,
      sortedBoxes14bench,
      sortedBoxes15bench
    } = useManCode3({benchmark, referencess, answers})
  return (
    <Box sx={{direction: 'rtl'}}>
<Page srcPage={P18}>
  <Header name={name} date={date} headerTitle={referencess?.[665]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[666]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      firstBoxDis1={referencess?.[667]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      scoBoxType={referencess?.[668]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[669]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[670]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      thirdBoxDis2={referencess?.[671]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes11?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes11bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[672]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[673]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[674]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[675]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[676]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[677]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[678]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[679]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[680]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[681]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[682]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[683]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[684]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[685]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[686]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[687]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[688]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[689]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[690]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[691]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[692]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[693]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[694]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[695]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[696]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[697]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[698]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[699]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[700]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[701]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[702]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[703]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[704]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[705]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[706]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[707]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[708]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[709]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>
<Page srcPage={P19}>
  <Header name={name} date={date} headerTitle={referencess?.[710]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
      firstBoxType={referencess?.[711]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[712]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[713]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[714]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[715]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[716]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes12?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes12bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[717]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[718]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[719]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[720]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[721]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[722]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[723]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[724]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[725]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[726]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[727]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[728]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[729]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[730]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[731]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[732]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[733]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[734]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[735]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[736]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[737]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[738]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[739]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[740]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[741]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[742]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[743]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[744]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[745]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[746]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[747]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[748]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[749]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[750]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[751]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[752]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[753]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[754]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P20}>
  <Header name={name} date={date} headerTitle={referencess?.[755]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
      firstBoxType={referencess?.[756]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[757]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[758]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[759]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[760]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[761]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes13?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes13bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[762]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[763]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[764]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[765]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[766]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[767]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[768]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[769]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[770]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[771]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[772]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[773]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[774]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[775]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[776]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[777]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[778]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[779]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[780]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[781]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[782]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[783]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[784]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[785]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[786]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[787]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[788]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[789]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[790]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[791]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[792]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[793]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[794]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[795]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[796]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[797]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[798]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[799]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P21}>
  <Header name={name} date={date} headerTitle={referencess?.[800]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
      firstBoxType={referencess?.[801]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[802]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[803]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[804]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[805]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[806]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes14?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes14bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[807]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[808]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[809]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[810]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[811]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[812]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[813]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[814]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[815]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[816]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[817]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[818]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[819]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[820]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[821]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[822]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[823]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[824]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[825]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[826]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[827]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[828]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[829]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[830]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[831]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[832]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[833]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[834]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[835]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[836]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[837]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[838]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[839]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[840]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[841]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[842]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[843]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[844]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P22}>
  <Header name={name} date={date} headerTitle={referencess?.[845]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
      firstBoxType={referencess?.[846]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[847]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[848]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[849]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[850]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[851]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes15?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes15bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>

      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[852]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[853]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[854]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[855]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[856]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[857]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[858]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[859]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[860]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[861]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[862]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[863]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[864]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[865]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[866]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[867]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[868]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[869]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[870]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[871]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[872]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[873]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[874]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[875]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[876]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[877]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[878]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[879]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[880]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[881]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[882]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[883]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[884]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[885]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[886]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[887]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[888]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[889]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>


    </Box>
  );
}

export default Thirdpart;
