import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { appContext } from "../../../../App";
import { useNavigate } from "react-router";
import { Box, Button, Checkbox, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import LoadingSpinner from "../../../../FemilerCom/loading/spinner";
import ApiContext from "../Context/context";
import { useSearch } from "../Context/SearchContext";

function MyEnrollments() {
  const {
    enrollments,
  } = useContext(ApiContext);
  const { searchQuery } = useSearch();


  const navigate = useNavigate();

  const [loader, setLoader] = useState(false);
  const [enrollId, setenrollId] = useState();
  const downloadReports = async (ids) => {
    setLoader(true)
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/reports-download",
        {  enrollment_ids: ids } 
      );
      if (response) {
        setLoader(false)
        console.log(response.data.payload.reports_zip_file)
        window.open(response.data.payload.reports_zip_file, "_blank");
      }
    } catch (error) {
      setLoader(false)
      console.error("Error downloading reports:", error);
    }
  };


  let isLoggedIn = useContext(appContext);

  const handleDebriefing = async () => {
    try {
      // Step 1: Get the existing data from the database
      const response = await axios.get('https://api.twindix.com/benchmark-questions/3', {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
  
      const existingData = response.data.payload;
  
      // Ensure debriefing exists and is an array
      const debriefingArray = existingData.benchmark_questions?.debriefing || [];
      
      // Prepare new entries to be added
      const newEntries = debriefingArray.length > 0 
        ? debriefingArray.map(() => ({
            user_id: isLoggedIn?.userData?.id, // Ensure user_id is defined
            wantDebriefing: true,
            enroll_id: enrollId, // Ensure enroll_id is defined
          }))
        : [  // If no data in debriefing, send a custom entry or handle as needed
            {
              user_id: isLoggedIn?.userData?.id, 
              wantDebriefing: true,
              enroll_id: enrollId, 
            }
          ];
  
      // Step 2: Merge the existing debriefing_Req with the new entries
      const updatedRequest = {
        benchmark_questions: {
          debriefing_Req: [
            ...existingData.benchmark_questions.debriefing_Req,  // Existing data in debriefing_Req
            ...newEntries,  // New entries to be added
          ],
        },
      };
  
      // Step 3: Send the updated data to the endpoint
      await axios.put('https://api.twindix.com/benchmark-questions/3', updatedRequest, {
        headers: {
          Authorization: "Bearer " + localStorage.token,
          "Content-Type": "application/json",
        },
      });
  
      console.log('Payload sent successfully!');
    } catch (error) {
      console.error('Error while sending payload', error);
    }
  };
  const filteredcompanies = enrollments?.filter((product) =>
    product.code?.toLowerCase().includes(searchQuery?.toLowerCase())
  );
  const [isDisabled, setIsDisabled] = useState(false);
  return (
    <Box>
    {loader &&  <LoadingSpinner /> }
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Exam Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredcompanies?.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.code}</TableCell>
                <TableCell>
                  {row.finished === 1 ? <Typography color="success">Finished</Typography> : <Typography color="error">Not Finished</Typography>}
                </TableCell>
                <TableCell>{row.exam_id === 1 ? "Individual" : "Man Code"}</TableCell>
                <TableCell>
                  <Button
                  sx={{margin :'4px'}}
                    variant="contained"
                    style={{
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                        }}
                    onClick={() =>
                      row.finished === 1
                        ? 
                        // window.open(`/reportPage?enro_id=${row.id}&exam_id=${row.exam_id}`)
                        downloadReports([row.id])
                        : navigate(`/Qustion/${row.exam_id}/${row.id}`)
                    }
                  >
                    {row.finished === 1 ? "Download Report" : "Take Assessment"}
                  </Button>
                  {row?.is_debriefing && row.finished === 1 ? 
                    <Button
                    sx={{color: "white",margin :'4px', background : isDisabled ? 'gray' :"linear-gradient(45deg, #2c6264, #5ac4cb)" }}
                    variant="contained"
                    onClick={() => {
                        setenrollId(row.id)
                        handleDebriefing()
                        setIsDisabled(true)
                    }}
                    disabled={isDisabled}>
                        Send Debriefing
                  </Button>
                  :
                    null
                  }
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default MyEnrollments;
