import { Stack, Typography, useMediaQuery } from "@mui/material"
import SearchInput from "./SearchInput"


function Header ({headerTilte, searchQuery, setSearchQuery}) {
    const isMobile = useMediaQuery("(max-width:600px)");
    return (
        <Stack spacing={isMobile ? 2 : null} mt={4} mb={4} direction='row' justifyContent='space-between' alignItems='center'>
            <Typography sx={{color: '#448084', textDecoration : 'underline', fontSize: '30px', fontWeight: '600'}}>
                {headerTilte}
            </Typography>
            <SearchInput searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
        </Stack>
    )
}

export default Header