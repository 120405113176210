import React, { useState, useEffect, useContext } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Grid,
  Typography,
  Box,
  Stack,
} from '@mui/material';
import axios from 'axios';
import ApiContext from '../../../Context/context';

export default function EditRole({ open, setOpen, roleId, onRoleUpdate }) {
  const {
    allPermissions
  } = useContext(ApiContext);
  const [permissions, setPermissions] = useState([]);
  const [name, setName] = useState('');
  const [isAllSelected, setIsAllSelected] = useState(false);

  const handleCheckboxChange = (permissionId) => {
    setPermissions((prev) =>
      prev.includes(permissionId)
        ? prev.filter((id) => id !== permissionId) // Remove if unchecked
        : [...prev, permissionId] // Add if checked
    );
  };

  const handleSelectAll = () => {
    if (isAllSelected) {
      setPermissions([]); // Deselect all
    } else {
      setPermissions(allPermissions.map((permission) => permission.id)); // Select all
    }
    setIsAllSelected(!isAllSelected);
  };

  useEffect(() => {
    if (roleId) {
      const fetchRoleDetails = async () => {
        try {
          const response = await axios.get(`https://api.twindix.com/roles/${roleId}`, {
            headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
          });
          const role = response.data.payload;
          setName(role.name || '');
          setPermissions(role.permissions.map((perm) => perm.id) || []);
        } catch (error) {
          console.error('Error fetching role details:', error);
        }
      };

      fetchRoleDetails();
    }
  }, [roleId]);

  useEffect(() => {
    setIsAllSelected(
      permissions.length > 0 && permissions.length === allPermissions.length
    );
  }, [permissions, allPermissions]);

  const handleClose = () => setOpen(false);

  const handleSubmit = async () => {
    const payload = {
      name,
      permission: permissions,
    };

    try {
      const response = await axios.put(`https://api.twindix.com/roles/${roleId}`, payload, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      if (response.status === 200 && onRoleUpdate) {
        onRoleUpdate(response.data.payload);
      }

      setOpen(false);
    } catch (error) {
      console.error('Error updating role:', error);
    }
  };

  return (
    <Dialog maxWidth="md" open={open} onClose={handleClose}>
      <Box sx={{ bgcolor: '#929292' }}>
        <DialogContent>
          <Grid container direction="column" spacing={4}>
            <Grid item xs={12}>
              <Typography sx={{ color: 'white' }} variant="h6">Role Name:</Typography>
              <TextField
                fullWidth
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Insert role name"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography sx={{ color: 'white' }} variant="h6">Permissions:</Typography>
              <Stack spacing={2}>
                <label>
                  <input
                    type="checkbox"
                    checked={isAllSelected}
                    onChange={handleSelectAll}
                    style={{ marginRight: '8px' }}
                  />
                  Select All
                </label>
                <Grid container spacing={2}>
                  {allPermissions.map((permission) => (
                    <Grid item xs={6} key={permission.id} sx={{ color: 'white' }}>
                      <label>
                        <input
                          type="checkbox"
                          value={permission.id}
                          checked={permissions.includes(permission.id)}
                          onChange={() => handleCheckboxChange(permission.id)}
                          style={{ marginRight: '8px' }}
                        />
                        {permission.name}
                      </label>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={handleSubmit}>
            Save Changes
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  );
}
