import { Box, ListItem, Stack, Typography } from '@mui/material';
import P1 from '../../../Media/ManCode/P1.jpg';
import P2 from '../../../Media/ManCode/P2.jpg';
import P2en from '../../../Media/ManCode/P2-en-no.jpg';
import P3 from '../../../Media/ManCode/P3.jpg';
import P4 from '../../../Media/ManCode/P4.jpg';
import P7 from '../../../Media/ManCode/P7.jpg';
import P8 from '../../../Media/ManCode/P8.jpg';
import P9 from '../../../Media/ManCode/P9.jpg';
import req from '../../../Media/ManCode/Req-ar.jpg';
import reqen from '../../../Media/ManCode/P3-Req-Btn-en.jpg';
import p4_Act from '../../../Media/ManCode/P4-Actual.jpg'
import p4_Req from '../../../Media/ManCode/P4-Req.jpg'


import { FirstSection, ForthSection, Header, Page, ScoSection, ThirdSection } from './Share';
import '../CSS/GlopalReport.css'
import { useManCode1 } from './Data1';
import { appContext } from '../../../App';
import { useContext, useEffect, useState } from 'react';

function Firstpart({name, date, benchmark, referencess, answers, isBenchMark, lang}) {


  const radius = 295; // Radius of the circle
  const items = 16; // Total number of child stacks
  const angleStep = (2 * Math.PI) / items; // Angle increment for each child stack

  const {
    firstBox,
    firstBox2,
    firstBox3,
    firstBox4,
    firstBox6,
    firstBox7,
    firstBox8,
    firstBox9,
    firstBox10,
    firstBox11,
    firstBox12,
    firstBox13,
    firstBox14,
    firstBox15,
    firstBox16,
    sortedAnswers,
    sortedBoxes1,
    sortedBoxes2,
    sortedBoxes,
    sortedBoxesbench,
    sortedBoxes1bench,
    sortedBoxes2bench,
  } = useManCode1({benchmark, referencess, answers, isBenchMark, lang})

  return (
    <Box sx={{direction: 'rtl'}}>
        <Page srcPage={P1}>
          <Header name={name} date={date} headerTitle={referencess?.[0]?.[lang === 'en' ? 'en content' : 'Ar content']}          />
        <Stack width='95%' mr="35px" mt="33px" height='81.5%'>
          <Box width='98%'  fontSize='28px' mr="10px" height="230px">{referencess?.[1]?.[lang === 'en' ? 'en content' : 'Ar content']}</Box>
          <Stack  width='100%' mt='39.6px' height='73.5%' spacing={2}>
              <Stack height='calc(100% / 4)' mb={0} direction='row' width='100%' >
                <Stack height='96%' fontWeight='bold' fontSize='40px'  color='white' justifyContent='center' alignItems='center' width='30.5%'>{referencess?.[3]?.[lang === 'en' ? 'en content' : 'Ar content']}</Stack>
                <Stack fontSize='23px' justifyContent='center' alignItems='center' height='96%' width='67%' pr='10px'>{referencess?.[2]?.[lang === 'en' ? 'en content' : 'Ar content']}</Stack>
              </Stack>
              <Stack height='calc(100% / 4)' mb={0} direction='row' width='100%' >
                <Stack height='96%' fontWeight='bold' fontSize='40px'  color='white' justifyContent='center' alignItems='center' width='30.5%'>{referencess?.[5]?.[lang === 'en' ? 'en content' : 'Ar content']}</Stack>
                <Stack   fontSize='23px' justifyContent='center' alignItems='center' height='96%' width='67%' pr='10px'>{referencess?.[4]?.[lang === 'en' ? 'en content' : 'Ar content']}</Stack>
              </Stack>
              <Stack height='calc(100% / 4)'  mb={0} direction='row' width='100%' >
                <Stack height='96%' fontWeight='bold' fontSize='40px'  color='white' justifyContent='center' alignItems='center' width='30.5%'>{referencess?.[7]?.[lang === 'en' ? 'en content' : 'Ar content']}</Stack>
                <Stack  fontSize='23px' justifyContent='center' alignItems='center' height='96%' width='67%' pr='10px'>{referencess?.[6]?.[lang === 'en' ? 'en content' : 'Ar content']}</Stack>
              </Stack>
              <Stack height='calc(100% / 4)' mb={0} direction='row' width='100%' >
                <Stack height='96%' fontWeight='bold' fontSize='40px'  color='white' justifyContent='center' alignItems='center' width='30.5%'>{referencess?.[9]?.[lang === 'en' ? 'en content' : 'Ar content']}</Stack>
                <Stack   fontSize='23px' justifyContent='center' alignItems='center' height='96%' width='67%' pr='10px'>{referencess?.[8]?.[lang === 'en' ? 'en content' : 'Ar content']}</Stack>
              </Stack>
          </Stack>
        </Stack>
        </Page>
      <Page srcPage={lang === 'en' ?  P2en: P2 } no={true}>
      <Header no={true} name={name} date={date} headerTitle={referencess?.[9]?.[lang === 'en' ? 'en content' : 'Ar content']}/>
      <Box
      sx={{
        position: "relative",
        bottom: '23px',
        width: radius * 2,
        height: radius * 2,
        borderRadius: "50%",
        margin: "auto",
      }}
    >
              <Stack
                sx={{
                  position: "absolute",
                  top: radius + radius * Math.sin(0) - (-4),
                  left: radius + radius * Math.cos(0) - 102,
                  rotate: '-80deg',
                  width: 100,
                  height: 80,
                  color: "white",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: 'row'
                }}
              >
                <Box width={10} ml={.9} mt={1}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25211])) +
                        Math.trunc(Number(answers?.[25212])) +
                        Math.trunc(Number(answers?.[25213])) >= 2
                        ? ""
                        : '2px solid #f7e560' , 
                        rotate: '-5deg'    
                  }} position='relative' right='9px' top='-8px' width={10} height="30px" mt={.1} bgcolor={
                    Math.trunc(Number(answers?.[25211])) +
                        Math.trunc(Number(answers?.[25212])) +
                        Math.trunc(Number(answers?.[25213])) >= 2   ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box  position='relative'  right='5px' sx={{border: benchmark[20] === 1 ? '' : '2px solid #f7e560',rotate: '-5deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[20] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25221])) +
                        Math.trunc(Number(answers?.[25222])) +
                        Math.trunc(Number(answers?.[25223])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,
                        rotate: '-1deg'
                  }}  position='relative' right='11px' top='-6px' width={10} height="30px" mt={.1}  bgcolor={
                    Math.trunc(Number(answers?.[25221])) +
                        Math.trunc(Number(answers?.[25222])) +
                        Math.trunc(Number(answers?.[25223])) >= 2  ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' sx={{border: benchmark[21] === 1 ? '' : '2px solid #f7e560',rotate: '0deg'}} width={10} height="30px" mt={.1} bgcolor={benchmark[21] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25232])) +
                        Math.trunc(Number(answers?.[25233])) +
                        Math.trunc(Number(answers?.[25234])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,     
                        rotate: '5deg'
                  }} position='relative' right='11px' top='-3px' width={10} height="30px" mt={.1} bgcolor={
                    Math.trunc(Number(answers?.[25232])) +
                        Math.trunc(Number(answers?.[25233])) +
                        Math.trunc(Number(answers?.[25234])) >= 2  ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='6px' sx={{border: benchmark[22] === 1 ? '' : '2px solid #f7e560',rotate: '3deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[22] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25241])) +
                        Math.trunc(Number(answers?.[25242])) +
                        Math.trunc(Number(answers?.[25243])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,    
                        rotate: '5deg'
                  }} position='relative' right='12px' top='-4px' width={10} height="30px" mt={.1} bgcolor={
                    Math.trunc(Number(answers?.[25241])) +
                        Math.trunc(Number(answers?.[25242])) +
                        Math.trunc(Number(answers?.[25243])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18px' top='4px' sx={{border: benchmark[23] === 1 ? '' : '2px solid #f7e560',rotate: '10deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[23] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box sx={{
                    border:
                      Math.trunc(Number(answers?.[25251])) +
                        Math.trunc(Number(answers?.[25252])) +
                        Math.trunc(Number(answers?.[25253])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,    
                        rotate: '9deg'
                  }} position='relative' right='13px' top='-6px'  width={10} height="30px" mt={.1} bgcolor={
                    Math.trunc(Number(answers?.[25251])) +
                        Math.trunc(Number(answers?.[25252])) +
                        Math.trunc(Number(answers?.[25253])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='0px' sx={{border: benchmark[24] === 1 ? '' : '2px solid #f7e560',rotate: '10deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[24] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(angleStep) - 20,
          left: radius + radius * Math.cos(angleStep) - 115,
          rotate: '-55deg',
          width: 100,
          height: 80,
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[26261])) +
                        Math.trunc(Number(answers?.[26262])) +
                        Math.trunc(Number(answers?.[26263])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,  rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26261])) +
                        Math.trunc(Number(answers?.[26262])) +
                        Math.trunc(Number(answers?.[26263])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{border: benchmark[26] === 1 ? '' : '2px solid #f7e560',rotate: '-6deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[25] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[26281])) +
                        Math.trunc(Number(answers?.[26282])) +
                        Math.trunc(Number(answers?.[26283])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,   rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26281])) +
                        Math.trunc(Number(answers?.[26282])) +
                        Math.trunc(Number(answers?.[26283])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='1px' sx={{border: benchmark[26] === 1 ? '' : '2px solid #f7e560',rotate: '-4deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[26] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[26281])) +
                        Math.trunc(Number(answers?.[26282])) +
                        Math.trunc(Number(answers?.[26283])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,    rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26281])) +
                        Math.trunc(Number(answers?.[26282])) +
                        Math.trunc(Number(answers?.[26283])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='13px' top='7px' sx={{border: benchmark[27] === 1 ? '' : '2px solid #f7e560',rotate: '3deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[27] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[26291])) +
                        Math.trunc(Number(answers?.[26292])) +
                        Math.trunc(Number(answers?.[26293])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '4deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26291])) +
                        Math.trunc(Number(answers?.[26292])) +
                        Math.trunc(Number(answers?.[26293])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17px' top='5px' sx={{border: benchmark[28] === 1 ? '' : '2px solid #f7e560',rotate: '5deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[28] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[26301])) +
                        Math.trunc(Number(answers?.[26302])) +
                        Math.trunc(Number(answers?.[26303])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '7deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[26301])) +
                        Math.trunc(Number(answers?.[26302])) +
                        Math.trunc(Number(answers?.[26303])) >= 2? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='4px' sx={{border: benchmark[29] === 1 ? '' : '2px solid #f7e560',rotate: '10deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[29] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(2 * angleStep) - 46,
          left: radius + radius * Math.cos(2 * angleStep) - 117,
          rotate: '-35deg',
          width: 100,
          height: 80,
          color: "white",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[27311])) +
                        Math.trunc(Number(answers?.[27312])) +
                        Math.trunc(Number(answers?.[27313])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27311])) +
                        Math.trunc(Number(answers?.[27312])) +
                        Math.trunc(Number(answers?.[27313])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{border: benchmark[30] === 1 ? '' : '2px solid #f7e560',rotate: '-6deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[30] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[27321])) +
                        Math.trunc(Number(answers?.[27322])) +
                        Math.trunc(Number(answers?.[27323])) >= 2
                        ? ""
                        : '2px solid #f7e560' , rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27321])) +
                        Math.trunc(Number(answers?.[27322])) +
                        Math.trunc(Number(answers?.[27323])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{border: benchmark[31] === 1 ? '' : '2px solid #f7e560',rotate: '0deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[31] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[27331])) +
                        Math.trunc(Number(answers?.[27332])) +
                        Math.trunc(Number(answers?.[27333])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27331])) +
                        Math.trunc(Number(answers?.[27332])) +
                        Math.trunc(Number(answers?.[27333])) >= 2? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{border: benchmark[32] === 1 ? '' : '2px solid #f7e560',rotate: '3deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[32] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[27341])) +
                        Math.trunc(Number(answers?.[27342])) +
                        Math.trunc(Number(answers?.[27343])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,  rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27341])) +
                        Math.trunc(Number(answers?.[27342])) +
                        Math.trunc(Number(answers?.[27343])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='19px' top='5px' sx={{border: benchmark[33] === 1 ? '' : '2px solid #f7e560',rotate: '7deg', border: benchmark[33] === 1 ? '' :'2px solid #f7e560'}} width={10} height="30px" mt={.1} bgcolor={benchmark[33] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[27351])) +
                        Math.trunc(Number(answers?.[27352])) +
                        Math.trunc(Number(answers?.[27353])) >= 2
                        ? ""
                        : '2px solid #f7e560' ,rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[27351])) +
                        Math.trunc(Number(answers?.[27352])) +
                        Math.trunc(Number(answers?.[27353])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='22px' top='1px' sx={{border: benchmark[34] === 1 ? '' : '2px solid #f7e560',rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={benchmark[34] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                  </Box>
                  </Stack>
                  <Stack
                    sx={{
                      position: "absolute",
                      top: radius + radius * Math.sin(3 * angleStep) - 70,
                      left: radius + radius * Math.cos(3 * angleStep) - 110,
                      rotate: '-10deg',
                      width: 100,
                      height: 80,
                      color: "white",
                      alignItems: "center",
                      justifyContent: "center",
                                flexDirection: 'row'
                    }}
                  >
                              <Box width={10} ml={.9} mt={1}>
                              <Box position='relative' right='10px' top='-10px' sx={{border:
                                  Math.trunc(Number(answers?.[28361])) +
                                    Math.trunc(Number(answers?.[28362])) +
                                    Math.trunc(Number(answers?.[28363])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' , rotate: '-7deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28361])) +
                                    Math.trunc(Number(answers?.[28362])) +
                                    Math.trunc(Number(answers?.[28363])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='6px' top='-4px' sx={{border: benchmark[35] === 1 ? '' : '2px solid #f7e560',rotate: '-8deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[35] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                            <Box width={10} ml={.9} mt={1}>
                              <Box position='relative' right='12px' top='-8px' sx={{border:
                                  Math.trunc(Number(answers?.[28371])) +
                                    Math.trunc(Number(answers?.[28372])) +
                                    Math.trunc(Number(answers?.[28373])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' , rotate: '-4deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28371])) +
                                    Math.trunc(Number(answers?.[28372])) +
                                    Math.trunc(Number(answers?.[28373])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='10px' top='1px' sx={{border: benchmark[36] === 1 ? '' : '2px solid #f7e560',rotate: '-2deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[36] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                            <Box width={10} ml={.9}>
                              <Box position='relative' right='12px' top='-3px' sx={{border:
                                  Math.trunc(Number(answers?.[28381])) +
                                    Math.trunc(Number(answers?.[28382])) +
                                    Math.trunc(Number(answers?.[28383])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' ,rotate: '1deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28381])) +
                                    Math.trunc(Number(answers?.[28382])) +
                                    Math.trunc(Number(answers?.[28383])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='13px' top='5px' sx={{border: benchmark[37] === 1 ? '' : '2px solid #f7e560',rotate: '2deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[37] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                            <Box width={10} ml={.9}>
                              <Box position='relative' right='14px' top='-4px' sx={{border:
                                  Math.trunc(Number(answers?.[28391])) +
                                    Math.trunc(Number(answers?.[28392])) +
                                    Math.trunc(Number(answers?.[28393])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28391])) +
                                    Math.trunc(Number(answers?.[28392])) +
                                    Math.trunc(Number(answers?.[28393])) >= 2? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='17px' top='5px' sx={{border: benchmark[38] === 1 ? '' : '2px solid #f7e560',rotate: '7deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[38] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                            <Box width={10} ml={.9}>
                              <Box position='relative' right='14px' top='-7px' sx={{border:
                                  Math.trunc(Number(answers?.[28401])) +
                                    Math.trunc(Number(answers?.[28402])) +
                                    Math.trunc(Number(answers?.[28403])) >= 2
                                    ? ""
                                    : '2px solid #f7e560' ,rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                                      Math.trunc(Number(answers?.[28401])) +
                                    Math.trunc(Number(answers?.[28402])) +
                                    Math.trunc(Number(answers?.[28403])) >= 2 ? '#f7e560' : ''} borderRadius={3}></Box>
                              <Box position='relative'  right='20.5px' top='2px' sx={{border: benchmark[39] === 1 ? '' : '2px solid #f7e560',rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={benchmark[39] === 1  ? '#f7e560' : ''} borderRadius={3}></Box>
                            </Box>
                  </Stack>
                  <Stack
                    sx={{
                      position: "absolute",
                      top: radius + radius  * Math.sin(4 * angleStep) - 77,
                      left: radius + radius * Math.cos(4 * angleStep) - 68.5,
                      rotate: '10deg',
                      width: 50,
                      height: 50,
                      color: "white",
                      borderRadius: "50%",
                      alignItems: "center",
                      justifyContent: "center",
                      flexDirection: 'row'
                    }}
                  >
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[39411])) +
                        Math.trunc(Number(answers?.[39412])) +
                        Math.trunc(Number(answers?.[39413])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39411])) +
                        Math.trunc(Number(answers?.[39412])) +
                        Math.trunc(Number(answers?.[39413])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='7px' sx={{border: benchmark[40] === 1 ? '' : '2px solid #e33f4c',rotate: '-6deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[40] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[39421])) +
                        Math.trunc(Number(answers?.[39422])) +
                        Math.trunc(Number(answers?.[39423])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '-2deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39421])) +
                        Math.trunc(Number(answers?.[39422])) +
                        Math.trunc(Number(answers?.[39423])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='11.5px' top='1px' sx={{border: benchmark[41] === 1 ? '' : '2px solid #e33f4c',rotate: '-1deg',}} width={10} height="30px" mt={.1} bgcolor={benchmark[41] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[39431])) +
                        Math.trunc(Number(answers?.[39432])) +
                        Math.trunc(Number(answers?.[39433])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39431])) +
                        Math.trunc(Number(answers?.[39432])) +
                        Math.trunc(Number(answers?.[39433])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14.5px' top='5px' sx={{rotate: '3deg',border: benchmark[42] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[42] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[39441])) +
                        Math.trunc(Number(answers?.[39442])) +
                        Math.trunc(Number(answers?.[39443])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '8deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39441])) +
                        Math.trunc(Number(answers?.[39442])) +
                        Math.trunc(Number(answers?.[39443])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18.5px' top='4.5px' sx={{rotate: '8deg', border: benchmark[43] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[43] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[39451])) +
                        Math.trunc(Number(answers?.[39452])) +
                        Math.trunc(Number(answers?.[39453])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '13deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[39451])) +
                        Math.trunc(Number(answers?.[39452])) +
                        Math.trunc(Number(answers?.[39453])) >= 2? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='22px' top='1px' sx={{rotate: '11deg',border: benchmark[44] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[44] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(5 * angleStep) - 89,
          left: radius + radius * Math.cos(5 * angleStep) - 44,
          rotate: '35deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='12.5px' top='-9px' sx={{border:
                      Math.trunc(Number(answers?.[310461])) +
                        Math.trunc(Number(answers?.[310462])) +
                        Math.trunc(Number(answers?.[310463])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '-8deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310461])) +
                        Math.trunc(Number(answers?.[310462])) +
                        Math.trunc(Number(answers?.[310463])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='7px' top='-1px' sx={{rotate: '-7deg',border: benchmark[45] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[45] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='13px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[310471])) +
                        Math.trunc(Number(answers?.[310472])) +
                        Math.trunc(Number(answers?.[310473])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310471])) +
                        Math.trunc(Number(answers?.[310472])) +
                        Math.trunc(Number(answers?.[310473])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10.3px' top='1px' sx={{rotate: '-2deg',border: benchmark[46] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[46] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[310481])) +
                        Math.trunc(Number(answers?.[310482])) +
                        Math.trunc(Number(answers?.[310483])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310481])) +
                        Math.trunc(Number(answers?.[310482])) +
                        Math.trunc(Number(answers?.[310483])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='15px' top='5px' sx={{rotate: '2deg', border: benchmark[47] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[47] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='15px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[310491])) +
                        Math.trunc(Number(answers?.[310492])) +
                        Math.trunc(Number(answers?.[310493])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310491])) +
                        Math.trunc(Number(answers?.[310492])) +
                        Math.trunc(Number(answers?.[310493])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18.5px' top='5px' sx={{rotate: '6deg',border: benchmark[48] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[48] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='16px' top='-5px' sx={{border:
                      Math.trunc(Number(answers?.[310501])) +
                        Math.trunc(Number(answers?.[310502])) +
                        Math.trunc(Number(answers?.[310503])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '10deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[310501])) +
                        Math.trunc(Number(answers?.[310502])) +
                        Math.trunc(Number(answers?.[310503])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='22px' top='2px' sx={{rotate: '11deg', border: benchmark[49] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[49] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(6 * angleStep) - 94,
          left: radius + radius * Math.cos(6 * angleStep) - 19,
          rotate: '57deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[311511])) +
                        Math.trunc(Number(answers?.[311512])) +
                        Math.trunc(Number(answers?.[311513])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311511])) +
                        Math.trunc(Number(answers?.[311512])) +
                        Math.trunc(Number(answers?.[311513])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='5px' sx={{rotate: '-8deg', border: benchmark[50] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[50] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10.5px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[311521])) +
                        Math.trunc(Number(answers?.[311522])) +
                        Math.trunc(Number(answers?.[311523])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,  rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311521])) +
                        Math.trunc(Number(answers?.[311522])) +
                        Math.trunc(Number(answers?.[311523])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='2px' sx={{rotate: '-1.5deg', border: benchmark[51] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[51] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-1px' sx={{border:
                      Math.trunc(Number(answers?.[311531])) +
                        Math.trunc(Number(answers?.[311532])) +
                        Math.trunc(Number(answers?.[311533])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311531])) +
                        Math.trunc(Number(answers?.[311532])) +
                        Math.trunc(Number(answers?.[311533])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='12.5px' top='6px' sx={{rotate: '1deg', border: benchmark[52] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[52] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[311541])) +
                        Math.trunc(Number(answers?.[311542])) +
                        Math.trunc(Number(answers?.[311543])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311541])) +
                        Math.trunc(Number(answers?.[311542])) +
                        Math.trunc(Number(answers?.[311543])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='16px' top='5px' sx={{rotate: '7deg', border: benchmark[53] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[53] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-5px' sx={{border:
                      Math.trunc(Number(answers?.[311551])) +
                        Math.trunc(Number(answers?.[311552])) +
                        Math.trunc(Number(answers?.[311553])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '10deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[311551])) +
                        Math.trunc(Number(answers?.[311552])) +
                        Math.trunc(Number(answers?.[311553])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='20px' top='3px' sx={{rotate: '11deg', border: benchmark[54] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[54] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(7 * angleStep) - 86,
          left: radius + radius * Math.cos(7 * angleStep) - (-7),
          rotate: '79deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-8px' sx={{ border:
                      Math.trunc(Number(answers?.[312561])) +
                        Math.trunc(Number(answers?.[312562])) +
                        Math.trunc(Number(answers?.[312563])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '-7deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312561])) +
                        Math.trunc(Number(answers?.[312562])) +
                        Math.trunc(Number(answers?.[312563])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg', border: benchmark[55] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[55] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[312571])) +
                        Math.trunc(Number(answers?.[312572])) +
                        Math.trunc(Number(answers?.[312573])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312571])) +
                        Math.trunc(Number(answers?.[312572])) +
                        Math.trunc(Number(answers?.[312573])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{rotate: '-1deg', border: benchmark[56] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[56] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[312581])) +
                        Math.trunc(Number(answers?.[312582])) +
                        Math.trunc(Number(answers?.[312583])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '2deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312581])) +
                        Math.trunc(Number(answers?.[312582])) +
                        Math.trunc(Number(answers?.[312583])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '2deg',  border: benchmark[57] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[57] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[312591])) +
                        Math.trunc(Number(answers?.[312592])) +
                        Math.trunc(Number(answers?.[312593])) >= 2
                        ? ""
                        : '2px solid #e33f4c' ,rotate: '7deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312591])) +
                        Math.trunc(Number(answers?.[312592])) +
                        Math.trunc(Number(answers?.[312593])) >= 2? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17px' top='5px' sx={{rotate: '6deg', border: benchmark[58] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[58] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-5.5px' sx={{border:
                      Math.trunc(Number(answers?.[312601])) +
                        Math.trunc(Number(answers?.[312602])) +
                        Math.trunc(Number(answers?.[312603])) >= 2
                        ? ""
                        : '2px solid #e33f4c' , rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[312601])) +
                        Math.trunc(Number(answers?.[312602])) +
                        Math.trunc(Number(answers?.[312603])) >= 2 ? '#e33f4c' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='2px' sx={{rotate: '11deg', border: benchmark[59] === 1 ? '' : '2px solid #e33f4c',}} width={10} height="30px" mt={.1} bgcolor={benchmark[59] === 1  ? '#e33f4c' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(8 * angleStep) - 69,
          left: radius + radius * Math.cos(8 * angleStep) - (-27),
          rotate: '101deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[413611])) +
                        Math.trunc(Number(answers?.[413612])) +
                        Math.trunc(Number(answers?.[413613])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413611])) +
                        Math.trunc(Number(answers?.[413612])) +
                        Math.trunc(Number(answers?.[413613])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg',border: benchmark[60] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[60] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[413621])) +
                        Math.trunc(Number(answers?.[413622])) +
                        Math.trunc(Number(answers?.[413623])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413621])) +
                        Math.trunc(Number(answers?.[413622])) +
                        Math.trunc(Number(answers?.[413623])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{rotate: '0deg', border: benchmark[61] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[61] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[413631])) +
                        Math.trunc(Number(answers?.[413632])) +
                        Math.trunc(Number(answers?.[413633])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413631])) +
                        Math.trunc(Number(answers?.[413632])) +
                        Math.trunc(Number(answers?.[413633])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg', border: benchmark[62] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[62] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[413641])) +
                        Math.trunc(Number(answers?.[413642])) +
                        Math.trunc(Number(answers?.[413643])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413641])) +
                        Math.trunc(Number(answers?.[413642])) +
                        Math.trunc(Number(answers?.[413643])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='19px' top='5px' sx={{rotate: '7deg', border: benchmark[63] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[63] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[413651])) +
                        Math.trunc(Number(answers?.[413652])) +
                        Math.trunc(Number(answers?.[413653])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[413651])) +
                        Math.trunc(Number(answers?.[413652])) +
                        Math.trunc(Number(answers?.[413653])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='22px' top='1px' sx={{rotate: '11deg',border: benchmark[64] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[64] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(9 *angleStep) - 46,
          left: radius + radius * Math.cos(9 *angleStep) - (-40),
          rotate: '123deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[414661])) +
                        Math.trunc(Number(answers?.[414662])) +
                        Math.trunc(Number(answers?.[414663])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414661])) +
                        Math.trunc(Number(answers?.[414662])) +
                        Math.trunc(Number(answers?.[414663])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg', border: benchmark[65] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[65] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[414671])) +
                        Math.trunc(Number(answers?.[414672])) +
                        Math.trunc(Number(answers?.[414673])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '-2deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414671])) +
                        Math.trunc(Number(answers?.[414672])) +
                        Math.trunc(Number(answers?.[414673])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='2px' sx={{rotate: '0deg',border: benchmark[66] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[66] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[414681])) +
                        Math.trunc(Number(answers?.[414682])) +
                        Math.trunc(Number(answers?.[414683])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414681])) +
                        Math.trunc(Number(answers?.[414682])) +
                        Math.trunc(Number(answers?.[414683])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg', border: benchmark[67] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[67] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[414691])) +
                        Math.trunc(Number(answers?.[414692])) +
                        Math.trunc(Number(answers?.[414693])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '7deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414691])) +
                        Math.trunc(Number(answers?.[414692])) +
                        Math.trunc(Number(answers?.[414693])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17px' top='4px' sx={{rotate: '9deg',border: benchmark[68] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[68] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-5.5px' sx={{border:
                      Math.trunc(Number(answers?.[414701])) +
                        Math.trunc(Number(answers?.[414702])) +
                        Math.trunc(Number(answers?.[414703])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[414701])) +
                        Math.trunc(Number(answers?.[414702])) +
                        Math.trunc(Number(answers?.[414703])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='1px' sx={{rotate: '11deg', border: benchmark[69] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[69] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(10 * angleStep) -  20,
          left: radius + radius * Math.cos(10 * angleStep) - (-43),
          rotate: '148deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-10px' sx={{border:
                      Math.trunc(Number(answers?.[415711])) +
                        Math.trunc(Number(answers?.[415712])) +
                        Math.trunc(Number(answers?.[415713])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415711])) +
                        Math.trunc(Number(answers?.[415712])) +
                        Math.trunc(Number(answers?.[415713])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='5px' top="-1px" sx={{rotate: '-8deg',border: benchmark[70] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[70] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[415721])) +
                        Math.trunc(Number(answers?.[415722])) +
                        Math.trunc(Number(answers?.[415723])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415721])) +
                        Math.trunc(Number(answers?.[415722])) +
                        Math.trunc(Number(answers?.[415723])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='1px' sx={{rotate: '-2deg', border: benchmark[71] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[71] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12.5px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[415731])) +
                        Math.trunc(Number(answers?.[415732])) +
                        Math.trunc(Number(answers?.[415733])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '1deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415731])) +
                        Math.trunc(Number(answers?.[415732])) +
                        Math.trunc(Number(answers?.[415733])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='12.5px' top='6px' sx={{rotate: '0deg', border: benchmark[72] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[72] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[415741])) +
                        Math.trunc(Number(answers?.[415742])) +
                        Math.trunc(Number(answers?.[415743])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415741])) +
                        Math.trunc(Number(answers?.[415742])) +
                        Math.trunc(Number(answers?.[415743])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='16px' top='5px' sx={{rotate: '7deg', border: benchmark[73] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[73] === 1  ? '#5fce68' : ''}  borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-5px' sx={{border:
                      Math.trunc(Number(answers?.[415751])) +
                        Math.trunc(Number(answers?.[415752])) +
                        Math.trunc(Number(answers?.[415753])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[415751])) +
                        Math.trunc(Number(answers?.[415752])) +
                        Math.trunc(Number(answers?.[415753])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='20.5px' top='3px' sx={{rotate: '11deg', border: benchmark[74] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[74] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(11 * angleStep) - (-5),
          left: radius + radius * Math.cos(11 * angleStep) - (-36),
          rotate: '167deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                  <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[416761])) +
                        Math.trunc(Number(answers?.[416762])) +
                        Math.trunc(Number(answers?.[416763])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416761])) +
                        Math.trunc(Number(answers?.[416762])) +
                        Math.trunc(Number(answers?.[416763])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg',border: benchmark[75] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[75] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10.5px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[416771])) +
                        Math.trunc(Number(answers?.[416772])) +
                        Math.trunc(Number(answers?.[416773])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '-1deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416771])) +
                        Math.trunc(Number(answers?.[416772])) +
                        Math.trunc(Number(answers?.[416773])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{rotate: '0deg',border: benchmark[76] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[76] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[416781])) +
                        Math.trunc(Number(answers?.[416782])) +
                        Math.trunc(Number(answers?.[416783])) >= 2
                        ? ""
                        : '2px solid #5fce68' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416781])) +
                        Math.trunc(Number(answers?.[416782])) +
                        Math.trunc(Number(answers?.[416783])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg', border: benchmark[77] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[77] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[416791])) +
                        Math.trunc(Number(answers?.[416792])) +
                        Math.trunc(Number(answers?.[416793])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416791])) +
                        Math.trunc(Number(answers?.[416792])) +
                        Math.trunc(Number(answers?.[416793])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17.5px' top='4px' sx={{rotate: '9deg', border: benchmark[78] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[78] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12.5px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[416801])) +
                        Math.trunc(Number(answers?.[416802])) +
                        Math.trunc(Number(answers?.[416803])) >= 2
                        ? ""
                        : '2px solid #5fce68' , rotate: '12deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[416801])) +
                        Math.trunc(Number(answers?.[416802])) +
                        Math.trunc(Number(answers?.[416803])) >= 2 ? '#5fce68' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='-1px' sx={{rotate: '12deg', border: benchmark[79] === 1 ? '' : '2px solid #5fce68',}} width={10} height="30px" mt={.1} bgcolor={benchmark[79] === 1  ? '#5fce68' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(12 * angleStep) - (-27),
          left: radius + radius * Math.cos(12 * angleStep) - (-19),
          rotate: '190deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[1111])) +
                        Math.trunc(Number(answers?.[1112])) +
                        Math.trunc(Number(answers?.[1113])) >= 2
                        ? ""
                        : '2px solid #25225b' ,  rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1111])) +
                        Math.trunc(Number(answers?.[1112])) +
                        Math.trunc(Number(answers?.[1113])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg',border: benchmark[0] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[0] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10.5px' top='-6px' sx={{background:
                      Math.trunc(Number(answers?.[1121])) +
                        Math.trunc(Number(answers?.[1122])) +
                        Math.trunc(Number(answers?.[1123])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-1deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1121])) +
                        Math.trunc(Number(answers?.[1122])) +
                        Math.trunc(Number(answers?.[1123])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10.5px' top='2px' sx={{rotate: '-1deg', border: benchmark[1] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[1] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-2px' sx={{border:
                      Math.trunc(Number(answers?.[1131])) +
                        Math.trunc(Number(answers?.[1132])) +
                        Math.trunc(Number(answers?.[1133])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1131])) +
                        Math.trunc(Number(answers?.[1132])) +
                        Math.trunc(Number(answers?.[1133])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg',border: benchmark[2] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[2] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[1141])) +
                        Math.trunc(Number(answers?.[1142])) +
                        Math.trunc(Number(answers?.[1143])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '8deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1141])) +
                        Math.trunc(Number(answers?.[1142])) +
                        Math.trunc(Number(answers?.[1143])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17.5px' top='5px' sx={{rotate: '7deg',border: benchmark[3] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[3] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12.5px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[1151])) +
                        Math.trunc(Number(answers?.[1152])) +
                        Math.trunc(Number(answers?.[1153])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '12deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1151])) +
                        Math.trunc(Number(answers?.[1152])) +
                        Math.trunc(Number(answers?.[1153])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='1px' sx={{rotate: '11deg',border: benchmark[4] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[4] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(13 *angleStep) - (-39),
          left: radius + radius * Math.cos(13 *angleStep) - (3.5),
          rotate: '212deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
                    flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[1261])) +
                        Math.trunc(Number(answers?.[1262])) +
                        Math.trunc(Number(answers?.[1263])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1261])) +
                        Math.trunc(Number(answers?.[1262])) +
                        Math.trunc(Number(answers?.[1263])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg',border: benchmark[5] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[5] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10.3px' top='-6px' sx={{ border:
                      Math.trunc(Number(answers?.[1271])) +
                        Math.trunc(Number(answers?.[1272])) +
                        Math.trunc(Number(answers?.[1273])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-1deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1271])) +
                        Math.trunc(Number(answers?.[1272])) +
                        Math.trunc(Number(answers?.[1273])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9.5px' top='1px' sx={{rotate: '0deg',border: benchmark[6] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[6] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='11px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[1281])) +
                        Math.trunc(Number(answers?.[1282])) +
                        Math.trunc(Number(answers?.[1283])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1281])) +
                        Math.trunc(Number(answers?.[1282])) +
                        Math.trunc(Number(answers?.[1283])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg', border: benchmark[7] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[7] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[1291])) +
                        Math.trunc(Number(answers?.[1292])) +
                        Math.trunc(Number(answers?.[1293])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '8deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[1291])) +
                        Math.trunc(Number(answers?.[1292])) +
                        Math.trunc(Number(answers?.[1293])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18px' top='3px' sx={{rotate: '10deg',border: benchmark[8] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[8] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12.5px' top='-7px' sx={{border:
                      Math.trunc(Number(answers?.[12101])) +
                        Math.trunc(Number(answers?.[12102])) +
                        Math.trunc(Number(answers?.[12103])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '11deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[12101])) +
                        Math.trunc(Number(answers?.[12102])) +
                        Math.trunc(Number(answers?.[12103])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='-1px' sx={{rotate: '11deg',border: benchmark[9] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[9] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(14 * angleStep) - (-42),
          left: radius + radius * Math.cos(14 * angleStep) - (30),
          rotate: '235deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[13111])) +
                        Math.trunc(Number(answers?.[13112])) +
                        Math.trunc(Number(answers?.[13113])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13111])) +
                        Math.trunc(Number(answers?.[13112])) +
                        Math.trunc(Number(answers?.[13113])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='6px' sx={{rotate: '-6deg', border: benchmark[10] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[10] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='11px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[13121])) +
                        Math.trunc(Number(answers?.[13122])) +
                        Math.trunc(Number(answers?.[13123])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '-3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13121])) +
                        Math.trunc(Number(answers?.[13122])) +
                        Math.trunc(Number(answers?.[13123])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='10px' top='1px' sx={{rotate: '0deg', border: benchmark[11] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[11] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='12px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[13131])) +
                        Math.trunc(Number(answers?.[13132])) +
                        Math.trunc(Number(answers?.[13133])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '3deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13131])) +
                        Math.trunc(Number(answers?.[13132])) +
                        Math.trunc(Number(answers?.[13133])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='14px' top='5px' sx={{rotate: '3deg',border: benchmark[12] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[12] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='14px' top='-4px' sx={{border:
                      Math.trunc(Number(answers?.[13141])) +
                        Math.trunc(Number(answers?.[13142])) +
                        Math.trunc(Number(answers?.[13143])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '6deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13141])) +
                        Math.trunc(Number(answers?.[13142])) +
                        Math.trunc(Number(answers?.[13143])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='18px' top='3px' sx={{rotate: '9deg', border: benchmark[13] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[13] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='13px' top='-7px' sx={{ border:
                      Math.trunc(Number(answers?.[13151])) +
                        Math.trunc(Number(answers?.[13152])) +
                        Math.trunc(Number(answers?.[13153])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '12deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[13151])) +
                        Math.trunc(Number(answers?.[13152])) +
                        Math.trunc(Number(answers?.[13153])) >= 2? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='21px' top='0px' sx={{rotate: '12deg', border: benchmark[14] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[14] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
      <Stack
        sx={{
          position: "absolute",
          top: radius + radius * Math.sin(0) - (77),
          left: radius + radius * Math.cos(0) - 78,
          rotate: '256deg',
          width: 50,
          height: 50,
          color: "white",
          borderRadius: "50%",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: 'row'
        }}
      >
                        <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='10px' top='-8px' sx={{border:
                      Math.trunc(Number(answers?.[14161])) +
                        Math.trunc(Number(answers?.[14162])) +
                        Math.trunc(Number(answers?.[14163])) >= 2
                        ? ""
                        : '2px solid #25225b' ,    rotate: '-5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14161])) +
                        Math.trunc(Number(answers?.[14162])) +
                        Math.trunc(Number(answers?.[14163])) >= 2? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='5px' sx={{rotate: '-5deg', border: benchmark[15] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[15] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9} mt={1}>
                  <Box position='relative' right='8.5px' top='-6px' sx={{border:
                      Math.trunc(Number(answers?.[14171])) +
                        Math.trunc(Number(answers?.[14172])) +
                        Math.trunc(Number(answers?.[14173])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '0deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14171])) +
                        Math.trunc(Number(answers?.[14172])) +
                        Math.trunc(Number(answers?.[14173])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='9px' top='1px' sx={{rotate: '0deg', border: benchmark[16] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[16] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='10px' top='-3px' sx={{border:
                      Math.trunc(Number(answers?.[14181])) +
                        Math.trunc(Number(answers?.[14182])) +
                        Math.trunc(Number(answers?.[14183])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '5deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14181])) +
                        Math.trunc(Number(answers?.[14182])) +
                        Math.trunc(Number(answers?.[14183])) >= 2? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='13.5px' top='4.5px' sx={{rotate: '4deg', border: benchmark[17] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[17] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='11px' top='-5px' sx={{border:
                      Math.trunc(Number(answers?.[14191])) +
                        Math.trunc(Number(answers?.[14192])) +
                        Math.trunc(Number(answers?.[14193])) >= 2
                        ? ""
                        : '2px solid #25225b' ,rotate: '9deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14191])) +
                        Math.trunc(Number(answers?.[14192])) +
                        Math.trunc(Number(answers?.[14193])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='17px' top='3px' sx={{rotate: '9deg', border: benchmark[18] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[18] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
                <Box width={10} ml={.9}>
                  <Box position='relative' right='11px' top='-9px' sx={{border:
                      Math.trunc(Number(answers?.[14201])) +
                        Math.trunc(Number(answers?.[14202])) +
                        Math.trunc(Number(answers?.[14203])) >= 2
                        ? "block"
                        : '2px solid #25225b' ,rotate: '12deg'}} width={10} height="30px" mt={.1} bgcolor={
                          Math.trunc(Number(answers?.[14201])) +
                        Math.trunc(Number(answers?.[14202])) +
                        Math.trunc(Number(answers?.[14203])) >= 2 ? '#25225b' : ''} borderRadius={3}></Box>
                  <Box position='relative'  right='20.5px' top='-1px' sx={{rotate: '14deg',border: benchmark[19] === 1 ? '' : '2px solid #25225b',}} width={10} height="30px" mt={.1} bgcolor={benchmark[19] === 1  ? '#25225b' : ''} borderRadius={3}></Box>
                </Box>
      </Stack>
    </Box>
      </Page>
      <Page srcPage={P3}>
        <Header name={name} date={date} headerTitle={referencess?.[32]?.[lang === 'en' ? 'en content' : 'Ar content']} />
        <Stack height='62%' sx={{position: 'relative',top: '-15px', right: '36px', width :'93%'}}>
          <Stack height='calc(100% / 4)' mt='27px' direction='row-reverse' width='100%' justifyContent='space-between'>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
            <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
            
          <Stack spacing={2} width='60%'>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(38,34,95)" : 'white';
                const color = sum >= 2 ? 'white' : '#323283';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                      border: '2px solid #323283',
                      mt : "4px !important",
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
          </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
            <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox2[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='60%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox2[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(42,42,114)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #323283',
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                      mt : "4px !important"
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox3[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox3[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(50,50,136)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #323283',
                      mt : "4px !important",
                          mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          borderRadius: 5,
                          textAlign: 'center',
                          height: '40px',
                          fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox3[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='60%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox4[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(61,61,159)" : 'white';
                    const color = sum >= 2 ? 'white' : '#323283';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                          border: '2px solid #323283',
                          mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
        <Stack height='calc(100% / 4)' mt='69px' direction='row-reverse' width='100%' justifyContent='space-between'>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
      {/* Render the benchmarks */}
      <Stack spacing={1.6} width='35%'>
        {sortedAnswers.map((item, index) => (
          <Box
            key={`benchmark-${index}`}
            sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                      position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
             }}
          >
            <img style={{ width: '100%', height: '79%'}} src={lang === 'en' ? reqen : req} alt="req" />
          </Box>
        ))}
      </Stack>

      {/* Render the answers with corresponding benchmarks */}
      <Stack spacing={2} width='57%'>
        {sortedAnswers.map((item, index) => {
          // Calculate the sum of the values for the background color
          const sum = Object.values(item.values)
            .map(value => Math.trunc(Number(value)))
            .reduce((acc, val) => acc + val, 0);

          const background = sum >= 2 ? "rgb(255,240,1)" : 'white';
          const color = sum >= 2 ? 'black' : 'black';

          return (
            <Box
              key={`answer-${index}`}
              sx={{
                background,
                color,
                                      border: '2px solid #f7e560',
                      mt : "4px !important",
                alignContent: 'center',
                position: 'relative',
                top: '4px',
                right: '4.5px',
                width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
              }}
            >
              {item.ref}
            </Box>
          );
        })}
      </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox6[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden', 
                                                            position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%'}} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox6[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(251,232,34)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #f7e560',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox7[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox7[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,228,62)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #f7e560',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox8[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox8[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,224,95)" : 'white';
                    const color = sum >= 2 ? 'black' : 'black';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #f7e560',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
          <Stack height='calc(100% / 4)' mt='70px' direction='row-reverse' width='100%' justifyContent='space-between'>
            <Stack direction="row" justifyContent="space-between" width='calc(91% / 4)' height='100%'>
          <Stack spacing={1.6} width='35%'>
            {/* Sort the answers array and render the benchmark */}
            {firstBox9[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => (
                <Box
                  key={`benchmark-${index}`}
                  sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                   }}
                >
                  <img style={{ width: '100%', height: '79%'}} src={lang === 'en' ? reqen : req} alt="req" />
                </Box>
              ))}
          </Stack>
            
          <Stack spacing={2} width='57%'>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox9[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(241,28,46)" : 'white';
                const color = sum >= 2 ? 'white' : '#e33f4c';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                                            border: '2px solid #e33f4c',
                      mt : "4px !important",
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
          </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox10[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox10[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(238,45,69)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #e33f4c',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox11[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                      position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%'}} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox11[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(247,38,69)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #e33f4c',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox12[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                            position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox12[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(251,69,84)" : 'white';
                    const color = sum >= 2 ? 'white' : '#e33f4c';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #e33f4c',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
          <Stack height='calc(100% / 4)' mt='70px' direction='row-reverse'  width='100%' justifyContent='space-between'>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
            <Stack spacing={1.6} width='35%'>
            {/* Sort the answers array and render the benchmark */}
            {firstBox13[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => (
                <Box
                  key={`benchmark-${index}`}
                  sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                                              position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                   }}
                >
                  <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                </Box>
              ))}
            </Stack>
            <Stack spacing={2} width='57%'>
            {/* Sort the answers array and render the answers with their corresponding benchmarks */}
            {firstBox13[0].answers
              .sort((a, b) => {
                // Calculate the sum of values for both `a` and `b`
                const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
              
                // Sort by the sum of the values first
                return sumA - sumB;
              })
              .map((item, index) => {
                // Calculate the sum of the values for the background color
                const sum = Object.values(item.values)
                  .map(value => Math.trunc(Number(value)))
                  .reduce((acc, val) => acc + val, 0);
              
                const background = sum >= 2 ? "rgb(0,181,84)" : 'white';
                const color = sum >= 2 ? 'white' : '#5fce68';
              
                return (
                  <Box
                    key={`answer-${index}`}
                    sx={{
                      background,
                      color,
                      border: '2px solid #5fce68',
                      mt : "4px !important",
                      alignContent: 'center',
                      position: 'relative',
                      top: '4px',
                      right: '4.5px',
                      width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                    }}
                  >
                    {item.ref}
                  </Box>
                );
              })}
            </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox14[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                                                  position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox14[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`, ensuring we handle undefined values
                    const sumA = Object.values(a.values).reduce((acc, val) => acc + (Number(val) || 0), 0);
                    const sumB = Object.values(b.values).reduce((acc, val) => acc + (Number(val) || 0), 0);

                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value) || 0)) // Handle undefined values safely
                      .reduce((acc, val) => acc + val, 0);

                    const background = sum >= 2 ? "rgb(0,193,78)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #5fce68',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox15[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                                                  position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%' }} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox15[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(0,209,89)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #5fce68',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
            <Stack direction="row" justifyContent='space-between' width='calc(91% / 4)' height='100%'>
              <Stack spacing={1.6} width='35%'>
                {/* Sort the answers array and render the benchmark */}
                {firstBox16[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => (
                    <Box
                      key={`benchmark-${index}`}
                      sx={{ visibility: item.bench === 1 ? 'visible' : 'hidden',
                                                                                                                  position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                          height: '40px',
                          fontSize: '12px',
                       }}
                    >
                      <img style={{ width: '100%', height: '79%',}} src={lang === 'en' ? reqen : req} alt="req" />
                    </Box>
                  ))}
              </Stack>
                
              <Stack spacing={2} width='57%'>
                {/* Sort the answers array and render the answers with their corresponding benchmarks */}
                {firstBox16[0].answers
                  .sort((a, b) => {
                    // Calculate the sum of values for both `a` and `b`
                    const sumA = Number(a.values[0]) + Number(a.values[1]) + Number(a.values[2]);
                    const sumB = Number(b.values[0]) + Number(b.values[1]) + Number(b.values[2]);
                  
                    // Sort by the sum of the values first
                    return sumA - sumB;
                  })
                  .map((item, index) => {
                    // Calculate the sum of the values for the background color
                    const sum = Object.values(item.values)
                      .map(value => Math.trunc(Number(value)))
                      .reduce((acc, val) => acc + val, 0);
                  
                    const background = sum >= 2 ? "rgb(0,222,99)" : 'white';
                    const color = sum >= 2 ? 'white' : '#5fce68';
                  
                    return (
                      <Box
                        key={`answer-${index}`}
                        sx={{
                          background,
                          color,
                                                border: '2px solid #5fce68',
                      mt : "4px !important",
                          alignContent: 'center',
                          position: 'relative',
                          top: '4px',
                          right: '4.5px',
                          width: '100%',
                      borderRadius: 5,
                      textAlign: 'center',
                      height: '40px',
                      fontSize: '12px',
                        }}
                      >
                        {item.ref}
                      </Box>
                    );
                  })}
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Page>
      <Page srcPage={P4}>
      <Header name={name} date={date}  headerTitle={referencess?.[129]?.[lang === 'en' ? 'en content' : 'Ar content']}/>
        <Stack width='93.4%' height='77%' marginTop='73px' marginRight='37px'>
          <Stack direction='row' alignItems='center' width='100%' height='124px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{ marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
              <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[131]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              <Typography  width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[130]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
            </Stack>
            <Box  alignContent='center'>
              <Box display={
                Math.trunc(Number(answers?.[1911])) +
                Math.trunc(Number(answers?.[1912])) +
                Math.trunc(Number(answers?.[1913])) >= 2 
              ? 'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[80] === 0 ? 'block' : 'none' : null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1911])) +
                Math.trunc(Number(answers?.[1912])) +
                Math.trunc(Number(answers?.[1913])) < 2
              ? 'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[80] === 1 ? 'block' : 'none' : null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='85%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[132]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
            <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[133]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
          </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='110px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[135]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[134]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1921])) +
                Math.trunc(Number(answers?.[1922])) +
                Math.trunc(Number(answers?.[1923])) >= 2
              ? 'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[81] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1921])) +
                Math.trunc(Number(answers?.[1922])) +
                Math.trunc(Number(answers?.[1923])) < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[81] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[136]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[137]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='100px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[139]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[138]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1931]))  +
                Math.trunc(Number(answers?.[1932]))  +
                Math.trunc(Number(answers?.[1933])) >= 2 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[82] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1931])) +
                Math.trunc(Number(answers?.[1932]))  +
                Math.trunc(Number(answers?.[1933]))  < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[82] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[140]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[141]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='105px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[143]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[142]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1941]))  +
                Math.trunc(Number(answers?.[1942])) +
                Math.trunc(Number(answers?.[1943])) >= 2 
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[83] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1941]))  +
                Math.trunc(Number(answers?.[1942]))  +
                Math.trunc(Number(answers?.[1943])) < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[83] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[144]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[145]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='103px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{marginTop: '-10px',fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[147]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[146]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1951]))  +
                Math.trunc(Number(answers?.[1952]))  +
                Math.trunc(Number(answers?.[1953]))  >= 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[84] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1951])) +
                Math.trunc(Number(answers?.[1952])) +
                Math.trunc(Number(answers?.[1953])) < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[84] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[148]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[149]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='101px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[151]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[150]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1961])) +
                Math.trunc(Number(answers?.[1962])) +
                Math.trunc(Number(answers?.[1963])) >= 2
              ?  'none' : 'block'}  width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[85] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1961])) +
                Math.trunc(Number(answers?.[1962])) +
                Math.trunc(Number(answers?.[1963])) < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[85] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[152]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[153]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='102px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[155]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[154]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1971]))  +
                Math.trunc(Number(answers?.[1972])) +
                Math.trunc(Number(answers?.[1973])) >= 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[86] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1971]))  +
                Math.trunc(Number(answers?.[1972])) +
                Math.trunc(Number(answers?.[1973])) < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[86] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[156]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[157]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='109px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[159]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[158]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1981])) +
                Math.trunc(Number(answers?.[1982])) +
                Math.trunc(Number(answers?.[1983])) >= 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[87] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1981])) +
                Math.trunc(Number(answers?.[1982])) +
                Math.trunc(Number(answers?.[1983])) < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[87] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[160]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[161]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='104px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[163]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[162]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1991]))  +
                Math.trunc(Number(answers?.[1992])) +
                Math.trunc(Number(answers?.[1993])) >= 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[88] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[1991]))  +
                Math.trunc(Number(answers?.[1992])) +
                Math.trunc(Number(answers?.[1993])) < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[88] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[164]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[165]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
          <Stack direction='row' alignItems='center' width='100%' height='118px'>
          <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Stack sx={{marginRight: '10px', marginLeft: '10px'}} width='86%' direction='row' justifyContent='space-around' alignItems='center'>
            <Typography  width='127%' text-align="center" height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[167]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
            <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[166]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>  
          </Stack>
              <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[19101]))  +
                Math.trunc(Number(answers?.[19102]))  +
                Math.trunc(Number(answers?.[19103]))  >= 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[89] === 0 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            </Stack>
            <Stack width='50%' justifyContent='space-around' sx={{marginTop: '0'}} direction='row' >
            <Box  alignContent='center'>
              <Box display={Math.trunc(Number(answers?.[19101]))  +
                Math.trunc(Number(answers?.[19102])) +
                Math.trunc(Number(answers?.[19103])) < 2
              ?  'none' : 'block'} width={50}><img style={{width: '100%', height: '100%'}} src={p4_Act} alt='ssr' /></Box>
              <Box display={benchmark.length > 1 ? benchmark[89] === 1 ? 'block' : 'none': null} width={50} sx={{marginTop: '-10px'}}><img style={{width: '100%', height: '100%'}} src={p4_Req} alt='ssr' /></Box>
            </Box>
            <Stack width='85%' direction='row' sx={{marginRight: '10px'}} justifyContent='space-around' alignItems='center'>
                <Typography width='25%' fontSize={15} sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[168]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
                <Typography width='127%' height='85%' fontSize='18px' sx={{fontFamily: 'var(--Bold-Font-Family)', color : '#286061'}}>{referencess?.[169]?.[lang === 'en' ? 'en content' : 'Ar content']}</Typography>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Page>
      <Page srcPage={P7}>
        <Header name={name} date={date} headerTitle={referencess?.[170]?.[lang === 'en' ? 'en content' : 'Ar content']} />
        <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[171]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      firstBoxDis1={referencess?.[172]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      scoBoxType={referencess?.[173]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[174]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[175]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      thirdBoxDis2={referencess?.[176]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes1?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes1bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection 
      firstBoxTitle={referencess?.[177]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[178]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[179]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[180]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[181]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[182]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[183]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[184]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[185]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[186]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[187]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[188]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection 
      Box3Title={referencess?.[189]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[190]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[191]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[192]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[193]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[194]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[195]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[196]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[197]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[198]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[199]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[200]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[201]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection 
      Box3Title={referencess?.[202]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[203]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[204]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[205]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[206]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[207]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[208]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[209]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[210]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[211]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[212]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[213]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[214]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
        </Stack>
      </Page>
      <Page srcPage={P8}>
          <Header name={name} date={date}  headerTitle={referencess?.[170]?.[lang === 'en' ? 'en content' : 'Ar content']}/>
          <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
            <FirstSection 
            firstBoxType={referencess?.[216]?.[lang === 'en' ? 'en content' : 'Ar content']} 
        firstBoxDis1={referencess?.[217]?.[lang === 'en' ? 'en content' : 'Ar content']} 
        scoBoxType={referencess?.[218]?.[lang === 'en' ? 'en content' : 'Ar content']}
        scoBoxDis={referencess?.[219]?.[lang === 'en' ? 'en content' : 'Ar content']}
        thirdBoxDis1={referencess?.[220]?.[lang === 'en' ? 'en content' : 'Ar content']} 
        thirdBoxDis2={referencess?.[221]?.[lang === 'en' ? 'en content' : 'Ar content']} 
        colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxesbench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
                )}
                />
                <ScoSection 
                firstBoxTitle={referencess?.[222]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem1={referencess?.[223]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem2={referencess?.[224]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem3={referencess?.[225]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem4={referencess?.[226]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem5={referencess?.[227]?.[lang === 'en' ? 'en content' : 'Ar content']}
scoBoxTitle={referencess?.[228]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box2listItem1={referencess?.[229]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box2listItem2={referencess?.[230]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box2listItem3={referencess?.[231]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box2listItem4={referencess?.[232]?.[lang === 'en' ? 'en content' : 'Ar content']}
                Box2listItem5={referencess?.[233]?.[lang === 'en' ? 'en content' : 'Ar content']}
                />
                <ThirdSection 
                Box3Title={referencess?.[234]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item1={referencess?.[235]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item2={referencess?.[236]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item3={referencess?.[237]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item1={referencess?.[238]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item2={referencess?.[239]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item3={referencess?.[240]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item1={referencess?.[241]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item2={referencess?.[242]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item3={referencess?.[243]?.[lang === 'en' ? 'en content' : 'Ar content']}
line4item1={referencess?.[244]?.[lang === 'en' ? 'en content' : 'Ar content']}
line4item2={referencess?.[245]?.[lang === 'en' ? 'en content' : 'Ar content']}
                line4item3={referencess?.[246]?.[lang === 'en' ? 'en content' : 'Ar content']}
                />
                <ForthSection 
                Box3Title={referencess?.[247]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item1={referencess?.[248]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item2={referencess?.[249]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item3={referencess?.[250]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item1={referencess?.[251]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item2={referencess?.[252]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item3={referencess?.[253]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item1={referencess?.[254]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item2={referencess?.[255]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item3={referencess?.[256]?.[lang === 'en' ? 'en content' : 'Ar content']}
line4item1={referencess?.[257]?.[lang === 'en' ? 'en content' : 'Ar content']}
line4item2={referencess?.[258]?.[lang === 'en' ? 'en content' : 'Ar content']}
                line4item3={referencess?.[259]?.[lang === 'en' ? 'en content' : 'Ar content']}
                />
            </Stack>
      </Page>
      <Page srcPage={P9}>
          <Header name={name} date={date}  headerTitle={referencess?.[260]?.[lang === 'en' ? 'en content' : 'Ar content']}/>
          <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
            <FirstSection 
            firstBoxType={referencess?.[261]?.[lang === 'en' ? 'en content' : 'Ar content']} 
        firstBoxDis1={referencess?.[262]?.[lang === 'en' ? 'en content' : 'Ar content']} 
        scoBoxType={referencess?.[263]?.[lang === 'en' ? 'en content' : 'Ar content']}
        scoBoxDis={referencess?.[264]?.[lang === 'en' ? 'en content' : 'Ar content']}
        thirdBoxDis1={referencess?.[265]?.[lang === 'en' ? 'en content' : 'Ar content']} 
        thirdBoxDis2={referencess?.[266]?.[lang === 'en' ? 'en content' : 'Ar content']} 
        colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes2?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes2bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
                )}
                />
                <ScoSection 
                firstBoxTitle={referencess?.[267]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem1={referencess?.[268]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem2={referencess?.[269]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem3={referencess?.[270]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem4={referencess?.[271]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box1listItem5={referencess?.[272]?.[lang === 'en' ? 'en content' : 'Ar content']}
scoBoxTitle={referencess?.[273]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box2listItem1={referencess?.[274]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box2listItem2={referencess?.[275]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box2listItem3={referencess?.[276]?.[lang === 'en' ? 'en content' : 'Ar content']}
Box2listItem4={referencess?.[277]?.[lang === 'en' ? 'en content' : 'Ar content']}
                Box2listItem5={referencess?.[278]?.[lang === 'en' ? 'en content' : 'Ar content']}
                />
                <ThirdSection 
                Box3Title={referencess?.[279]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item1={referencess?.[280]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item2={referencess?.[281]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item3={referencess?.[282]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item1={referencess?.[283]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item2={referencess?.[284]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item3={referencess?.[285]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item1={referencess?.[286]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item2={referencess?.[287]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item3={referencess?.[288]?.[lang === 'en' ? 'en content' : 'Ar content']}
line4item1={referencess?.[289]?.[lang === 'en' ? 'en content' : 'Ar content']}
line4item2={referencess?.[290]?.[lang === 'en' ? 'en content' : 'Ar content']}
                line4item3={referencess?.[291]?.[lang === 'en' ? 'en content' : 'Ar content']}
                />
                <ForthSection 
                Box3Title={referencess?.[292]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item1={referencess?.[293]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item2={referencess?.[294]?.[lang === 'en' ? 'en content' : 'Ar content']}
line1item3={referencess?.[295]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item1={referencess?.[296]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item2={referencess?.[297]?.[lang === 'en' ? 'en content' : 'Ar content']}
line2item3={referencess?.[298]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item1={referencess?.[299]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item2={referencess?.[300]?.[lang === 'en' ? 'en content' : 'Ar content']}
line3item3={referencess?.[301]?.[lang === 'en' ? 'en content' : 'Ar content']}
line4item1={referencess?.[302]?.[lang === 'en' ? 'en content' : 'Ar content']}
line4item2={referencess?.[303]?.[lang === 'en' ? 'en content' : 'Ar content']}
                line4item3={referencess?.[304]?.[lang === 'en' ? 'en content' : 'Ar content']}
                />
            </Stack>
      </Page>
    </Box>
  );
}

export default Firstpart;
