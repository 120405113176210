import React, { createContext, useContext, useEffect, useState } from 'react';
import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';
import Report1 from './JS/Report1';
import Report2 from './JS/Report2';
import Report3 from './JS/Report3';
import Report4 from './JS/Report4';
import Report5 from './JS/Report5';
import './CSS/Report1.css';
import './CSS/Report2.css';
import './CSS/Report3.css';
import './CSS/Report4.css';
import './CSS/Report5.css';
import './CSS/GlopalReport.css';
import { saveAs } from 'file-saver';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router';
import { appContext } from '../../App';
import ManCode from './ManCode';
import ManCodePDFs from './ManCode/ManCodeReport';
import { useSearchParams } from 'react-router-dom';

export const repoCon = createContext(null);

 const GenerateAndMergePDFs = () => {
  const [referencess, setReferences] = useState();
  const [answers, setAnswers] = useState();
  const [references, setReferencess] = useState([]);



  
  const [currentDate, setCurrentDate] = useState('');
                
  useEffect(() => {
      // Function to update the current date
      const updateDate = () => {
          const dateObj = new Date();
          const day = dateObj.getDate();
          const month = dateObj.getMonth() + 1; // Months are zero-based
          const year = dateObj.getFullYear();
          const formattedDate = `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}-${year}`;
          setCurrentDate(formattedDate);
      };

      // Initial call to update the date
      updateDate();
      
      // Set interval to update the date every day
      const intervalId = setInterval(updateDate, 86400000); // 24 hours in milliseconds
      
      // Clean up the interval on component unmount
      return () => clearInterval(intervalId);
  }, []);

  
  let numsArray = [];

  function arrayCreator(ourReferences) {
    for (let i = 1; i <= 400; i++) {
      ourReferences['R' + i] = 0;
    }
    setReferencess(ourReferences);
  }
    let [enrolmentId, setenrolmentId] = useState("");
    let [ManCodeexamId, setManCodeexamId] = useState();
    
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const enroId = searchParams.get('enro_id');
    const examId = searchParams.get('exam_id');
    const isAdminPara = searchParams.get('isAdmin');
    setManCodeexamId(examId)
    arrayCreator(numsArray);
    setenrolmentId(enroId);
    if (isAdminPara == 1) {
      axios
        .get('https://api.twindix.com/dashboard/exams/' + examId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => {
          // console.log(res.data);
          setReferences(res.data.payload.template.references);
        });
      axios
        .get('https://api.twindix.com/dashboard/enrollments/' + enroId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => setAnswers(res.data.payload.answers));
    } else {
      axios
        .get('https://api.twindix.com/exams/' + examId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => {
          console.log(res)
          setReferences(res.data.payload.template.references);
        });
      axios
        .get('https://api.twindix.com/enrollments/' + enroId, {
          headers: {
            Authorization: 'Bearer ' + localStorage.token,
          },
        })
        .then((res) => setAnswers(res.data.payload.answers));
    }
  }, []);
  const [enroll, setEnroll] = useState();
  const [links, setLinks] = useState();
  useEffect(() => {
    if(!enroll) {
      axios.get("https://api.twindix.com/enrollments", {headers: {Authorization: "Bearer " + localStorage.token}}).then(res => {
      setEnroll(res.data.payload.data);
      setLinks(res.data.payload.links)
    })
    }

}, [enroll, ])
const today = new Date();

// Format the date as dd/mm/yyyy
const day = String(today.getDate()).padStart(2, '0'); // Ensure two digits for day
const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
const year = today.getFullYear();

const formattedDate = `${day}/${month}/${year}`;
const isLoggedIn = useContext(appContext);

const [searchParams] = useSearchParams();
    const lang = searchParams.get("lang") || "ar";

  return (
    <div style={{margin: '0', overflow: 'hidden', width: '1105px'}}>
{ManCodeexamId == 65 ? (
  <ManCodePDFs lang={lang} name={isLoggedIn?.userData?.name} date={formattedDate} />
) : (
  <>
    {references ? (
      <div style={{margin: '0'}}>
        <repoCon.Provider value={{ referencess, answers, references }}>
          {answers && repoCon ? (
            <div className='PDF'>
              <div id='PDF' className='BackGroundImge'>
                <div className=''>
                  <Report1 lang={lang} date={formattedDate} />
                </div>
                <div className=''>
                  <Report2 lang={lang} />
                </div>
                <div className=''>
                  <Report3 lang={lang} />
                </div>
                <div className=''>
                  <Report4 lang={lang} />
                </div>
                <div className=''>
                  <Report5 lang={lang} />
                </div>
              </div>
            </div>
          ) : (
            ''
          )}
        </repoCon.Provider>
      </div>
    ) : (
      ''
    )}
  </>
)}

    </div>
  );
};

export default GenerateAndMergePDFs;
