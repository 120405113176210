import { Badge, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, Menu, MenuItem, Stack, Typography } from "@mui/material"
import DensityMediumIcon from '@mui/icons-material/DensityMedium';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NotificationsIcon from '@mui/icons-material/Notifications';
import PersonIcon from '@mui/icons-material/Person';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useContext, useEffect, useState } from "react";
import { appContext } from "../../../../../App";
import { useNavigate } from "react-router";
import axios from "axios";
import logo from "../../../../../Media/Logo/NavBarLogo.png"
import CloseIcon from "@mui/icons-material/Close";
import Swal from "sweetalert2";
    function NavBar ({toggleDrawer, toggleCart}) {
        const [anchorEl, setAnchorEl] = useState(null);
        const [open, setOpen] = useState(false);
        const [open1, setOpen1] = useState(false);
        const isLoggedIn = useContext(appContext);
      

                // Editable states
                const [phone, setPhone] = useState(isLoggedIn?.userData?.phone || "");
                const [email, setEmail] = useState(isLoggedIn?.userEmail || "");
                const [userName, setUserName] = useState(isLoggedIn?.userName || "");
                const [companyName, setCompanyName] = useState(isLoggedIn?.company_name || "");
                const [jobTitle, setJobTitle] = useState(isLoggedIn?.job_title || "");
                const [password, setPassword] = useState("");
                const [passwordConfirmation, setPasswordConfirmation] = useState("");
              
                // Handle dialog open/close
                const handleClickOpen = () => {
                    handleClose()
                    setOpen(true)};
                const handleClose = () => setAnchorEl(null);
                const handleOpen = (event) => {
                    setAnchorEl(event.currentTarget); 
                  };
                  const handleOpen1 = () => {
                    setOpen1(true)
                    setAnchorEl(null)
                  };
                
                // Update user data function
                const updateUserData = async () => {
                  try {
                    const response = await axios.post(
                      "https://api.twindix.com/auth/me",
                      {
                        phone,
                        email,
                        userName,
                        companyName,
                        jobTitle,
                        password,
                        password_confirmation: passwordConfirmation,
                      },
                      {headers: {
                        Authorization: 'Bearer ' + localStorage.token
                    }
                }
                    );
                    alert("User data updated successfully!");
                    Swal.fire({
                        icon: 'success',
                        title: 'data updated',
                        text: 'User data updated successfully!',
                        timer: 3000,
                        showConfirmButton: true, 
                    });
                    handleClose();
                  } catch (error) {
                    console.error("Error updating data:", error);
                    Swal.fire({
                        icon: 'error',
                        title: 'data not updated',
                        text: 'Failed to update user data.',
                        timer: 3000,
                        showConfirmButton: true, 
                    });
                  }
                };
              
        let navigate = useNavigate();
        function logout(e) {
            e.preventDefault();
            
            axios.delete("https://api.twindix.com/auth/logout", {headers:{
                Authorization:"Bearer " + localStorage.token
            }}).then(res => {
                console.log("logged out successfully");
                localStorage.removeItem("token");
                localStorage.removeItem("isAdmin");
                localStorage.removeItem("isLogged");
                localStorage.removeItem("userType");
                isLoggedIn.setUserToken(null);
                isLoggedIn.setLoggedin(null);
                isLoggedIn.setUserType(null);
                isLoggedIn.setIsAdmin(null);
                navigate('/')
                window.location.reload()
            }).catch(err => console.log(err))
        
        }

        const [anchorNotfication, setanchorNotfication] = useState(null);

        const handleClickNotfication = (event) => {
          setanchorNotfication(event.currentTarget);
        };
        const handleCloseNotfication = () => {
          setanchorNotfication(null);
        };

        const [enroll, setEnroll] = useState([]);

        useEffect(() => {
          if (!enroll.length) {
            axios
              .get("https://api.twindix.com/enrollments", {
                headers: { Authorization: "Bearer " + localStorage.token },
              })
              .then((res) => {
                const data = res.data.payload.data;
                const lastFiveElements = data.slice(-5);
                setEnroll(lastFiveElements);
              });
          }
        }, []);


        const [reminder, setReminder] = useState([]);

useEffect(() => {
  axios
    .get("https://api.twindix.com/benchmark-questions/4", {
      headers: { Authorization: "Bearer " + localStorage.token },
    })
    .then((res) => {
      setReminder(res.data.payload.benchmark_questions[0].Reminder);  
    })
    .catch((err) => console.error(err));
}, []);  

const badgeLength = enroll?.length + reminder?.filter((filter) => filter.userId === isLoggedIn?.userData?.id).length
        return (
            <Stack          
                sx={{
                    width: "100%",
                    background: "linear-gradient(45deg, rgb(0, 102, 102), rgb(0, 200, 202))",
                    padding: '12px'
                }}
                direction='row' spacing={4} alignItems='center' justifyContent='space-between'>
                <Stack direction='row' spacing={4}>
                    <IconButton sx={{color : 'white'}} onClick={toggleDrawer}>
                        <DensityMediumIcon />
                    </IconButton>
                    <Box  component='h4' variant="h4">
                      <img src={logo} alt="dd" />
                        </Box>
                </Stack>
                <Stack bgcolor='red' position='absolute' left='45%' ><Typography fontSize={25} fontWeight='bold' sx={{color: 'white'}}>نسخه تجريبيه</Typography></Stack>
                <Stack   direction='row' alignItems='center' justifyContent='space-around'>
                    {/* <Stack direction='row' justifyContent='center' alignItems='center' component='span' variant="span">    
                        <Typography>
                        English (US) 
                        </Typography>
                        <IconButton >
                            <ArrowDropDownIcon/>
                        </IconButton>
                    </Stack> */}
                    <Badge badgeContent={badgeLength} color="error">
                    <IconButton onClick={handleClickNotfication}>
                        <NotificationsIcon/>
                    </IconButton>
                    </Badge>
                    <Menu
                                anchorEl={anchorNotfication}
                                open={Boolean(anchorNotfication)}
                                onClose={handleCloseNotfication}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                      >

                        {reminder && reminder?.filter((filter) => filter.userId === isLoggedIn?.userData?.id)
                        .map((rem) => {
                          return (
                            <MenuItem>You Are Received Reminder to Take the {rem?.exam_id === 1 ? 'Twindex Assessment' : 'Man Code Assessment'}</MenuItem>
                          )
                        })}
                        {enroll?.map((enrol) => {
                          return (
                            <MenuItem      style={{
                              maxWidth: "400px",
                              whiteSpace: "normal",
                              wordWrap: "break-word",
                            }}>• You have new invitation for  {enrol.exam_id === 1 ? "Twindix Executive" : 'Job Bar'}  with code {enrol.code}</MenuItem>
                          )
                        })}
                        {reminder.length <= 0 && enroll.length <= 0 && <Typography m={1}>There are no notifications at the moment.</Typography> }
                      </Menu>
                        <Box>
                        <IconButton onClick={handleOpen}>
                                <PersonIcon />
                              </IconButton>
                              <Menu
                                anchorEl={anchorEl}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',
                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}
                              >
                                <MenuItem onClick={handleClose} sx={{textDecoration : 'underline', marginLeft: '-6px'}}>
                                {isLoggedIn?.userType == '1' ? "HR" :isLoggedIn?.userType == '2' ?  'Indivdual' : 'Admin'} 
                                </MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn?.userName}</MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn?.userData?.name}</MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn?.userEmail}</MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn?.userData?.phone}</MenuItem>
                                <MenuItem onClick={handleClose}>{isLoggedIn?.userData?.job_title}</MenuItem>
                                <MenuItem onClick={handleOpen1}>My Account</MenuItem>
                                <MenuItem onClick={logout}>Logout</MenuItem>
                              </Menu>
                        </Box>

                </Stack>
                <Dialog maxWidth="lg" open={open1} onClose={() => setOpen1(false)}>
      <Box
        sx={{
          bgcolor: "#929292",
          width: "500px",
          position: "relative",
        }}
      >
        <IconButton
          onClick={() => setOpen1(false)}
          sx={{ position: "absolute", top: "8px", right: "8px", color: "white" }}
        >
          <CloseIcon />
        </IconButton>
        <DialogTitle>
          <Typography
            sx={{
              width: "50%",
              margin: "auto",
              color: "white",
              textDecoration: "underline",
            }}
          >
            Confirm Your Data
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Grid2 container direction="column" spacing={4}>
          <Grid2 item xs={12}>
                <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
                  ID:
                </Typography>
                <input
                disabled
                  value={isLoggedIn?.userData?.id}
                  style={{
                    padding: "10px",
                    fontSize: "10px",
                    border: "0",
                    borderRadius: "4px",
                    width: "100%",
                    height: "30px",
                  }}
                />
              </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Phone:
              </Typography>
              <input
                value={isLoggedIn?.userData?.phone || ""}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                readOnly
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Email:
              </Typography>
              <input
                value={isLoggedIn?.userEmail || ""}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                readOnly
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                User Name:
              </Typography>
              <input
                value={isLoggedIn?.userName || ""}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                readOnly
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Company Name:
              </Typography>
              <input
                value={isLoggedIn?.company_name || ""}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                readOnly
              />
            </Grid2>
            <Grid2 item xs={12}>
              <Typography
                sx={{
                  textDecoration: "underline",
                  color: "white",
                  mb: "20px",
                }}
              >
                Job Title:
              </Typography>
              <input
                value={isLoggedIn?.job_title || ""}
                style={{
                  padding: "10px",
                  fontSize: "10px",
                  border: "0",
                  borderRadius: "4px",
                  width: "100%",
                  height: "30px",
                }}
                readOnly
              />
            </Grid2>
          </Grid2>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              borderRadius: "10px",
              margin: "auto",
            }}
            onClick={updateUserData}
            variant="contained"
          >
            Update
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
            </Stack>
        )
    }

    export default NavBar