import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  List,
  ListItem,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import { appContext } from "../../../../../App";
import Swal from "sweetalert2";
import ApiContext from "../../Context/context";

export default function Package() {
  const {
    packages,
    addOns,
  } = useContext(ApiContext);
  const [isPurchased, setIsPurchased] = useState(null);
  const [purchasedPack, setPurchasedPack] = useState({ package_id: "" });
  const [coupon, setCoupon] = useState({});
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [couponError, setCouponError] = useState("");

  const [open, setOpen] = useState(false);
  const [open1, setOpen1] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleClick1 = () => {
    setOpen1(true);
  };

  const handleClose1 = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const handleChange = (e, item) => {
    const newValue = e.target.value;
    setCoupon((prevValues) => ({
      ...prevValues,
      [item]: newValue,
    }));
    console.log(coupon)
    };
    const [FinalPrice, setFinalPrice] = useState();

    const checkCoupon = (packId) => {
      const couponCode = coupon[packId] ?? '';

      axios
        .post(
          "https://api.twindix.com/coupon/package",
          { package_id: packId, code: couponCode ?? '', qty: '1' },
          { 
            headers: { Authorization: "Bearer " + localStorage.token},
          }
        )
        .then((res) => {
          if (res.data.success) {
            setFinalPrice(res.data.payload)
            console.log(res)
            setIsCouponValid(true);
            setCouponError("");
            // Optionally, you can handle discount value here
          } else {
            setCouponError("Invalid coupon.");
            setIsCouponValid(false);
          }
        })
        .catch(() => {
          handleClick()  
          setIsCouponValid(false);
        });
    };


  // Handle package purchase
  useEffect(() => {
    if (purchasedPack.package_id) {
      const couponCode = coupon[purchasedPack.package_id] ?? '';
      axios
        .post(
          "https://api.twindix.com/payment/package",
          { package_id: purchasedPack.package_id, code: couponCode ?? '', qty: '1', is_debriefing : 0 },
          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((res) => {
          const transactionId = res?.data?.payload?.transaction_id;
          localStorage.setItem('transaction_id', transactionId ?? 'no package transaction Id') ;
          localStorage.setItem('is_debriefing',0);
          localStorage.setItem('exam_Id', purchasedPack.package_id);
          setIsPurchased(
            "Redirecting to Gateway to complete payment in 5 seconds"
          );
          setTimeout(() => {
            window.location.replace(res.data.payload.redirect);
          }, 3000);
        })
        .catch((err) => handleClick1());
    }
  }, [purchasedPack]);

  // Handle package purchase button click
  function handlePackagePurchase(packId) {
    setPurchasedPack({ package_id: packId });
  }

  const [openEditDialog, setopenEditDialog] = useState(false)
  const [packegeId, setpackegeId] = useState()
  const [specifiedPackege, setspecifiedPackege] = useState([])
  
  const handleCloseEditDialog= () => setopenEditDialog(false)
  const handleOpenEditDialog= () => setopenEditDialog(true)

  const specifiedPackegeFun = (packId) => {

    axios
      .get(
        `https://api.twindix.com/packages/${packId}`)
      .then((res) => {
        setspecifiedPackege(res?.data?.payload)
        console.log(res)
      })
  };
  const [showAddons, setShowAddons] = useState(false);

  const handleAddOnsButtonClick = () => {
    setShowAddons((prev) => !prev); // Toggle the value of showAddons
  };

                let isLoggedIn = useContext(appContext);
              const hasRoleCreatePermission = isLoggedIn?.userData?.roles.some(
                (role) => role.name !== 'package-payment'
              );
            
              const hasPermission = () => { 
                if (isLoggedIn?.userData?.managed_by !== null) {
                  if (hasRoleCreatePermission) {
                    Swal.fire({
                      icon: 'error',
                      title: 'Access Denied',
                      text: 'You do not have permission to access this.',
                      timer: 3000,
                      showConfirmButton: true,
                    });
                  }
                }
              };
  return (
    <Box>
    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: 2,
        padding: 3,
      }}
    >

      {isPurchased && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            m: 3,
            padding: 2,
            backgroundColor: "green",
            color: "white",
            borderRadius: "8px",
          }}
        >
          {isPurchased}
        </Box>
      )}

      <>
  {/* Section for Packages */}
  <section>
    <Typography
      variant="h4"
      sx={{ textAlign: "center", margin: "20px 0", fontWeight: "bold", color: "#006d6d !important" }}
    >
      Packages
    </Typography>

    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "20px",
      }}
    >
{packages?.length > 0 ? 
  packages?.filter((ele) => ele.name !== 'Twindix Executives' && ele.name !== 'Job Bar')
  .map((pack) => (
          <Card
              key={pack.id}
              sx={{
                width: "230px",
                height: '286px',
                position: "relative",
                borderRadius: "15%",
                background: "linear-gradient(-60deg, #00c3c3 ,#006d6d )",
                overflow: "hidden",
                padding: "10px",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "#fff",
                  borderRadius: "15%",
                  margin: "4px",
                  zIndex: 1,
                },
              }}
            >
              <Box
                sx={{
                  margin: "auto",
                  width: "95%",
                  height: '65px',
                  borderRadius: "40px",
                  background: "linear-gradient(45deg, #006d6d, #00c3c3)",
                  zIndex: 1,
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <Typography fontSize={13} sx={{ padding: '6px 2px', height: '100%', alignContent: 'center', color: "white", fontWeight: "bold" }}>
                   {pack.name}
                </Typography>
              </Box>

              <CardContent sx={{padding: '3px 16px', zIndex: 2, position: "relative" }}>
                <List>
                <ListItem sx={{ padding: "0" }}>
                    <Typography sx={{ margin: "auto", fontSize: "18px", fontWeight: "bold", color: "#2c6264" }}>
                      ${pack.price}/ Year
                    </Typography>
                  </ListItem>
                {/* <ListItem sx={{ padding: "4px 0" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }}>
                      • {pack.exams_count} / Assessment
                    </Typography>
                  </ListItem> */}
                  <ListItem sx={{ padding: "4px 0" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }}>
                      • Annual Access to {pack.exam_id === 1 ? "Twindix Executive" : 'job bar'} Assessment
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: "4px 0" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }}>
                      • Count: {pack.exams_count} Assessments
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: "0" }}>
                    <Typography sx={{ margin: "auto", fontSize: "18px", fontWeight: "bold", color: "#2c6264" }}>
                      ${(pack.price / pack.exams_count).toFixed(2)}/ Assessment
                    </Typography>
                  </ListItem>
                  <Box
                sx={{
                  margin:'auto',
                  width: '108%',
                  height: '45px',
                  borderRadius: '40px',
                  background: ' #00c3c3',
                  zIndex: '1',
                  textAlign: 'center',
                  position: 'relative',
                  bottom: '-11px',
                  left: '-5px',
                }}
              >
                    <Button
                      sx={{ 
                        padding: '6px 2px', 
                        height: '100%', 
                        alignContent: 'center', 
                        color: "white", 
                        fontWeight: "bold" 
                        }}
                      onClick={() => {
                        hasPermission()
                        if(!hasRoleCreatePermission){
                        specifiedPackegeFun(pack.id);
                        setpackegeId(pack.id);
                        handleOpenEditDialog();
                        }
                      }}
                    >
                      Subscribe Now
                    </Button>
              </Box>
                </List>
              </CardContent>
            </Card>
        ))
        : <Typography>There Is No Packages Yet</Typography>}
    </Box>
  </section>

  {/* Section for Addons */}
  <section>
    <Typography
      variant="h4"
      sx={{ textAlign: "center", margin: "20px 0", fontWeight: "bold", color: "#2c6264 !important" }}
    >
      Addons
    </Typography>

    <Box
      sx={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "20px",
      }}
    >
{addOns?.length > 0 ? (
  addOns?.map((addon) => (
          <Card
              key={addon.id}
              sx={{
                width: "230px",
                height: '286px',
                position: "relative",
                borderRadius: "15%",
                background: "linear-gradient(-60deg, #00c3c3 ,#006d6d )",
                overflow: "hidden",
                padding: "10px",
                margin: "10px auto",
                "&::before": {
                  content: '""',
                  position: "absolute",
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  background: "#fff",
                  borderRadius: "15%",
                  margin: "4px",
                  zIndex: 1,
                },
              }}
            >
              <Box
                sx={{
                  margin: "auto",
                  width: "95%",
                  height: '65px',
                  borderRadius: "40px",
                  background: "linear-gradient(45deg, #006d6d, #00c3c3)",
                  zIndex: 1,
                  textAlign: "center",
                  position: "relative",
                }}
              >
                <Typography fontSize={13} sx={{ padding: '6px 2px', height: '100%', alignContent: 'center', color: "white", fontWeight: "bold" }}>
                  {addon.exams_count} {addon.name}
                </Typography>
              </Box>

              <CardContent sx={{padding: '3px 16px', zIndex: 2, position: "relative" }}>
              <List>
                <ListItem sx={{ padding: "0" }}>
                    <Typography sx={{ margin: "auto", fontSize: "18px", fontWeight: "bold", color: "#2c6264" }}>
                      ${addon.price}/ Annual
                    </Typography>
                  </ListItem>
                {/* <ListItem sx={{ padding: "4px 0" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }}>
                      • {pack.exams_count} / Assessment
                    </Typography>
                  </ListItem> */}
                  <ListItem sx={{ padding: "4px 0" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }}>
                      • Annual Access to {addon.exam_id === 1 ? "Twindix Executive" : 'job bar'} Assessment
                      <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }} ml={1}>
                       </Typography>
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: "4px 0" }}>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "#2c6264" }}>
                      • Count: {addon.exams_count} Assessments
                    </Typography>
                  </ListItem>
                  <ListItem sx={{ padding: "0" }}>
                    <Typography sx={{ margin: "auto", fontSize: "18px", fontWeight: "bold", color: "#2c6264" }}>
                      ${(addon.price / addon.exams_count).toFixed(2)}/ Assessment
                    </Typography>
                  </ListItem>
                  <Box
                sx={{
                  margin:'auto',
                  width: '108%',
                  height: '45px',
                  borderRadius: '40px',
                  background: ' #00c3c3',
                  zIndex: '1',
                  textAlign: 'center',
                  position: 'relative',
                  bottom: '-11px',
                  left: '-5px',
                }}
              >
                    <Button
                      sx={{ 
                        padding: '6px 2px', 
                        height: '100%', 
                        alignContent: 'center', 
                        color: "white", 
                        fontWeight: "bold" 
                        }}
                      onClick={() => {
                        hasPermission()
                        if(!hasRoleCreatePermission){
                        specifiedPackegeFun(addon.id);
                        setpackegeId(addon.id);
                        handleOpenEditDialog();
                        }
                      }}
                    >
                      Subscribe Now
                    </Button>
              </Box>
                </List>
              </CardContent>
            </Card>
        ))
      ) : (
        <Typography>Loading addons...</Typography>
      )}
    </Box>
  </section>
</>


      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Failed to check Coupon"
      />
      <Snackbar
        open={open1}
        autoHideDuration={6000}
        onClose={handleClose1}
        message="Failed to payment"
      />
            <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <Box sx={{ bgcolor: '#929292', width: '450px' }}>
          <DialogContent sx={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Stack>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}>package Name: {specifiedPackege?.name}</Typography>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}>package description: {specifiedPackege?.description}</Typography>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}>package Count: {specifiedPackege?.exams_count}</Typography>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}>package Price: {FinalPrice ? FinalPrice?.price : specifiedPackege.price  }$</Typography>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}>Exam Title: {specifiedPackege?.exam?.title}</Typography>
          </Stack>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
              <TextField
                label="Enter Coupon"
                variant="outlined"
                size="small"
                value={coupon[specifiedPackege.id] || ''}
                onChange={(e) => handleChange(e, specifiedPackege.id)}
                sx={{ flex: 1 }}
                name={`input-${specifiedPackege.id}`} 
                id={`input-${specifiedPackege.id}`} 
              />
              <Button
                variant="contained"
                
                onClick={() => checkCoupon(specifiedPackege.id)}
                sx={{fontSize: '12px', backgroundColor: "#4ca88f", color: "white", ml: 2 }}
              >
                Check Coupon
              </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
              <Stack justifyContent='center' alignItems='center' sx={{width: '100%'}}>
                <Button
                  variant="contained"
                  disabled={coupon[specifiedPackege.id] ? !isCouponValid : false}
                  onClick={() => handlePackagePurchase(specifiedPackege.id)}
                  sx={{width : '200px', fontSize: '12px',background: "linear-gradient(45deg, #2c6264, #5ac4cb)", color: "white"}}
                >
                  Subscribe Now
                </Button>
              </Stack>
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
    </Box>
  );
}
