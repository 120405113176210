import { Box, Stack } from '@mui/material';
import P10 from '../../../Media/ManCode/P10.jpg';
import P11 from '../../../Media/ManCode/P11.jpg';
import P12 from '../../../Media/ManCode/P12.jpg';
import P13 from '../../../Media/ManCode/P13.jpg';
import P14 from '../../../Media/ManCode/P14.jpg';
import P15 from '../../../Media/ManCode/P15.jpg';
import P16 from '../../../Media/ManCode/P16.jpg';
import P17 from '../../../Media/ManCode/P17.jpg';
import { FirstSection, ForthSection, Header, Page, ScoSection, ThirdSection } from './Share';
import { useManCode2 } from './Data2';

function Firstpart({benchmark, referencess, answers, lang, name, date}) {
    const {
      sortedBoxes3,
      sortedBoxes4,
      sortedBoxes5,
      sortedBoxes6,
      sortedBoxes7,
      sortedBoxes8,
      sortedBoxes9,
      sortedBoxes10,
      sortedBoxes4bench,
      sortedBoxes5bench,
      sortedBoxes3bench,
      sortedBoxes6bench,
      sortedBoxes7bench,
      sortedBoxes8bench,
      sortedBoxes9bench,
      sortedBoxes10bench,
    } = useManCode2({benchmark, referencess, answers})
  return (
    <Box sx={{direction: 'rtl'}}>
<Page srcPage={P10}>
  <Header name={name} date={date} headerTitle={referencess?.[305]?.[lang === 'en' ? 'en content' : 'Ar content']}/>
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[306]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      firstBoxDis1={referencess?.[307]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      scoBoxType={referencess?.[308]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[309]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[310]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      thirdBoxDis2={referencess?.[311]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes3?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes3bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection 
      firstBoxTitle={referencess?.[312]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[313]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[314]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[315]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[316]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[317]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[318]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[319]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[320]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[321]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[322]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[323]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[324]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[325]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[326]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[327]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[328]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[329]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[330]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[331]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[332]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[333]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[334]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[335]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[336]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection 
      Box3Title={referencess?.[337]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[338]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[339]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[340]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[341]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[342]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[343]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[344]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[345]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[346]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[347]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[348]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[349]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P11}>
  <Header name={name} date={date} headerTitle={referencess?.[350]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection 
    yellow={true}
      firstBoxType={referencess?.[351]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      firstBoxDis1={referencess?.[352]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      scoBoxType={referencess?.[353]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[354]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[355]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      thirdBoxDis2={referencess?.[356]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes4?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes4bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection 
      firstBoxTitle={referencess?.[357]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[358]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[359]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[360]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[361]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[362]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[363]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[364]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[365]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[366]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[367]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[368]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection 
      Box3Title={referencess?.[369]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[370]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[371]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[372]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[373]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[374]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[375]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[376]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[377]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[378]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[379]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[380]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[381]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection 
    yellow={true}
      Box3Title={referencess?.[382]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[383]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[384]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[385]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[386]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[387]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[388]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[389]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[390]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[391]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[392]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[393]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[394]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P12}>
  <Header name={name} date={date} headerTitle={referencess?.[395]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
    yellow={true}
      firstBoxType={referencess?.[396]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[397]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[398]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[399]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[400]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[401]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes5?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes5bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[402]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[403]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[404]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[405]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[406]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[407]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[408]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[409]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[410]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[411]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[412]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[413]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[414]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[415]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[416]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[417]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[418]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[419]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[420]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[421]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[422]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[423]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[424]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[425]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[426]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
    yellow={true}
      Box3Title={referencess?.[427]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[428]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[429]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[430]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[431]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[432]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[433]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[434]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[435]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[436]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[437]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[438]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[439]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P13}>
  <Header name={name} date={date} headerTitle={referencess?.[440]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
    yellow={true}
      firstBoxType={referencess?.[441]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[442]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[443]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[444]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[445]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[446]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes6?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes6bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[447]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[448]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[449]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[450]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[451]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[452]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[453]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[454]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[455]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[456]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[457]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[458]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[459]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[460]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[461]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[462]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[463]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[464]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[465]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[466]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[467]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[468]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[469]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[470]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[471]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
    yellow={true}
      Box3Title={referencess?.[472]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[473]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[474]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[475]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[476]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[477]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[478]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[479]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[480]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[481]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[482]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[483]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[484]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P14}>
  <Header name={name} date={date} headerTitle={referencess?.[485]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
    yellow={true}
      firstBoxType={referencess?.[486]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[487]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[488]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[489]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[490]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[491]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes7?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes7bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[492]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[493]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[494]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[495]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[496]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[497]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[498]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[499]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[500]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[501]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[502]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[503]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[504]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[505]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[506]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[507]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[508]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[509]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[510]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[511]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[512]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[513]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[514]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[515]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[516]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
    yellow={true}
      Box3Title={referencess?.[517]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[518]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[519]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[520]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[521]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[522]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[523]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[524]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[525]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[526]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[527]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[528]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[529]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P15}>
  <Header name={name} date={date} headerTitle={referencess?.[530]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection 
      firstBoxType={referencess?.[531]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      firstBoxDis1={referencess?.[532]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      scoBoxType={referencess?.[533]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[534]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[535]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      thirdBoxDis2={referencess?.[536]?.[lang === 'en' ? 'en content' : 'Ar content']} 
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes8?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes8bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection 
      firstBoxTitle={referencess?.[537]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[538]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[539]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[540]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[541]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[542]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[543]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[544]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[545]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[546]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[547]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[548]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection 
      Box3Title={referencess?.[549]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[550]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[551]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[552]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[553]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[554]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[555]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[556]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[557]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[558]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[559]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[560]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[561]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection 
      Box3Title={referencess?.[562]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[563]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[564]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[565]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[566]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[567]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[568]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[569]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[570]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[571]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[572]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[573]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[574]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P16}>
  <Header name={name} date={date} headerTitle={referencess?.[575]?.[lang === 'en' ? 'en content' : 'Ar content']} />
  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
      firstBoxType={referencess?.[576]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[577]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[578]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[579]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[580]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[581]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes9?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes9bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />
    <ScoSection
      firstBoxTitle={referencess?.[582]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[583]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[584]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[585]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[586]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[587]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[588]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[589]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[590]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[591]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[592]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[593]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ThirdSection
      Box3Title={referencess?.[594]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[595]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[596]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[597]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[598]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[599]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[600]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[601]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[602]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[603]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[604]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[605]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[606]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
    <ForthSection
      Box3Title={referencess?.[607]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[608]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[609]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[610]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[611]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[612]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[613]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[614]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[615]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[616]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[617]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[618]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[619]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

<Page srcPage={P17}>
  <Header name={name} date={date} headerTitle={referencess?.[620]?.[lang === 'en' ? 'en content' : 'Ar content']} />

  <Stack sx={{fontFamily: 'var(--Bold-Font-Family)'}} mt='85.4px' spacing={4} height='84%' mr={3}>
    <FirstSection
      firstBoxType={referencess?.[621]?.[lang === 'en' ? 'en content' : 'Ar content']}
      firstBoxDis1={referencess?.[622]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxType={referencess?.[623]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxDis={referencess?.[624]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis1={referencess?.[625]?.[lang === 'en' ? 'en content' : 'Ar content']}
      thirdBoxDis2={referencess?.[626]?.[lang === 'en' ? 'en content' : 'Ar content']}
      colors={() => (
<Stack direction="column" sx={{ position: 'relative', left: '-36px', top : '6px' }}  height='89%' width='198px' > 
<Stack direction='row' height='100%'>
  {sortedBoxes10?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibility }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColor}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
<Stack direction='row' height='100%'>
  {sortedBoxes10bench?.map((box, index) => (
    <Box key={index} width={10} height='90%'>
      <Box
        mr={index === 0 ? '0' : index === 1 ? '27px' : index === 2 ? '54px' : index === 3 ? '81px' : '109px'}
        sx={{ visibility: box.visibilityBench }}
        width='15px'
        height="100%"
        bgcolor={box.backgroundColorBench}
        borderRadius={3}
      ></Box>
    </Box>
  ))}
</Stack>
</Stack>
      )}
    />

    <ScoSection
      firstBoxTitle={referencess?.[627]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem1={referencess?.[628]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem2={referencess?.[629]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem3={referencess?.[630]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem4={referencess?.[631]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box1listItem5={referencess?.[632]?.[lang === 'en' ? 'en content' : 'Ar content']}
      scoBoxTitle={referencess?.[633]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem1={referencess?.[634]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem2={referencess?.[635]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem3={referencess?.[636]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem4={referencess?.[637]?.[lang === 'en' ? 'en content' : 'Ar content']}
      Box2listItem5={referencess?.[638]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />

    <ThirdSection
      Box3Title={referencess?.[639]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[640]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[641]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[642]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[643]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[644]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[645]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[646]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[647]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[648]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[649]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[650]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[651]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />

    <ForthSection
      Box3Title={referencess?.[652]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item1={referencess?.[653]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item2={referencess?.[654]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line1item3={referencess?.[655]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item1={referencess?.[656]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item2={referencess?.[657]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line2item3={referencess?.[658]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item1={referencess?.[659]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item2={referencess?.[660]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line3item3={referencess?.[661]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item1={referencess?.[662]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item2={referencess?.[663]?.[lang === 'en' ? 'en content' : 'Ar content']}
      line4item3={referencess?.[664]?.[lang === 'en' ? 'en content' : 'Ar content']}
    />
  </Stack>
</Page>

    </Box>
  );
}

export default Firstpart;
