import { createContext, useEffect, useRef, useState } from 'react';
import './App.css';
import CodeField from './Componants/CodeField/CodeField';
import Dashboard from './Pages/Dache/Dashboard/Dashboard.js';
import LogIn from './Pages/LogIn/LogIn';
import Payment from './Pages/Payment/Payment';
import Qustion from './Pages/Qustion/Qustion';
import Register from './Pages/Register/Register';
import ReportPage from './Pages/Report/Report';
import {HashRouter as Router,Route,Routes, Link, useNavigate, Navigate} from 'react-router-dom';
import ResetPassword from './Componants/ResetPassword/ResetPass';
import PasswordReset from './Componants/ResetPassword/password-reset';
import ResetPasswordForAdmin from './Componants/ResetPasswordForAdmin/ResetPassForAdmin';
import LogInForAdmin from './Pages/LogInForAdmin/LogInForAdmin';
import HrPayment from "./Componants/hrPayment/HrPayment"
import axios from 'axios';
import AddEmployee from './Componants/Dsche/HR/List/AddEmployee.js';
import EditOldTest from './Componants/Dsche/Admin/List/EditOldTest.js';
import AddTemp from './Componants/Dsche/Admin/List/AddTemp.js';
import EditTemp from './Componants/Dsche/Admin/List/EditTemp.js';
import Invite from './Componants/Dsche/HR/Invite.js';
import AddAdmin from './Componants/Dashboard/AdminDashboard/AddAdmin';
import AddPacks from './Componants/Dsche/Admin/List/AddPacks.js';
import Dashboard_v2 from './Pages/Dashboard-V2/index.js';
import PuppeteerTrigger from './test.js';
import BenchMarkQustions from './Componants/Dashboard-V2/HR/Pages/Component/BenchMarkQustions.js';


export let appContext = createContext(null);

function getInitialStateFromQustion() {
  let loaclFromQus = window.localStorage.getItem('test');
  if (loaclFromQus !== 'undefined') {
    return loaclFromQus ? JSON.parse(loaclFromQus) : ""
  }
}

function getInitialStateFromAdmin() {
  
  const localFromAdmin = localStorage.getItem('box')
  if (localFromAdmin !== 'undefined'){
    return localFromAdmin ? JSON.parse(localFromAdmin) : ""
  }
}

function App() {


    let [test, settest] = useState(getInitialStateFromQustion);
    let [isLoggedin, setLoggedin] = useState(true);
    let [isAdmin, setIsAdmin] = useState(false);
    let [userType, setUserType] = useState(null);
    let [userToken, setUserToken] = useState(null);
    let [userName, setUserName] = useState(null);
    let [userEmail, setUserEmail] = useState(null);
    let [userData, setUserData] = useState(null);
    let [examNumber, setExamNumber] = useState(null);
    let [homePage, setHomePage] = useState(null);
      useEffect(() => {
        if(localStorage.token && !localStorage.isAdmin) {

        setLoggedin(true);
        setUserToken(localStorage.token);
            axios.get("https://api.twindix.com/auth/me", {headers: {
              Authorization: 'Bearer ' + localStorage.token
          }
      }).then(res => {
        if(res.data.code == 200) {
                  setUserType(res.data.payload.type);
                  setUserName(res.data.payload.name);
                  setUserEmail(res.data.payload.email);
                  setUserData(res.data.payload)
                localStorage.setItem("userType", res.data.payload.type);
                  if(localStorage.userType == 2) {
                    setHomePage("/individual")
                  } else if(localStorage.userType == 1) {
                    setHomePage("/hr")
                  } else if(localStorage.userType == 3) {
                    setHomePage("/admin")
                  }
                  }
                  }).catch((res) => {
                    
                    if(res.response.data.code == 401) {
                      localStorage.removeItem("isAdmin")
                      localStorage.removeItem("token")
                      localStorage.removeItem("userType")
                      localStorage.removeItem("isLogged")
            }
          });

        } else if(localStorage.token && localStorage.isAdmin) {
          axios.get("https://api.twindix.com/dashboard/auth/me", {headers: {
            Authorization: 'Bearer ' + localStorage.token
        }

    }).then(res => {
            if(res.data.code == 200) {
              setHomePage("/admin")
                setUserName(res.data.payload.name);
                setUserEmail(res.data.payload.email);
                setUserType(null)
                localStorage.setItem("isAdmin", true);
                localStorage.isLogged = true;
            }
        }).catch(() => {});
        } else {
          setLoggedin(false);
          setUserToken(null);
          localStorage.removeItem("isLogged");
          localStorage.removeItem( "token" );
          localStorage.removeItem( "userType" );
          localStorage.removeItem( "isLoggedIn" );
        }
      }, [isLoggedin])
      useEffect(() => {
        localStorage.setItem('test', JSON.stringify(test))
      }, [test])
  return (
          <appContext.Provider value={{isLoggedin,examNumber,localStorage,  setExamNumber, setLoggedin, setUserToken, setUserType,userData, userName,setUserName,userEmail, userType, isAdmin, setIsAdmin}}>
      <div className="App">
      <Routes>
          <Route path='/Register' element={!localStorage.token && !localStorage.isLogged ? <Register /> : <Navigate to={homePage} /> } /> 
          <Route path='/' element={!localStorage.token && !localStorage.isLogged ? <LogIn /> : <Navigate to={homePage} /> } />
          <Route path='/Payment' element={isLoggedin && userType == 2 ? <Payment /> : <Navigate to="/" replace={true} />} />
          <Route path='/HRPayment' element={isLoggedin && userType == 1 ? <HrPayment /> : <Navigate to="/" replace={true} />} />
          <Route path='/AddTemp' element={<AddTemp />} />
          <Route path='/editTemplate' element={<EditTemp />} />
          <Route path='/addadmin' element={<AddAdmin />} />
          <Route path='/editexam' element={localStorage.token && localStorage.isAdmin ?  <EditOldTest /> : <LogIn />} />
          <Route path='/addEmployee' element={<AddEmployee />} />
          <Route path='/LogInForAdmin' element={<LogInForAdmin /> } />
          <Route path='/Qustion/:examid/:enroid' element={1 ? <Qustion/> : <Navigate to="/individual" replace={true} /> } />
          <Route path='/Business' element={localStorage.token && localStorage.userType == 1 ? <Dashboard_v2/> : <Navigate to="/" replace={true} /> } />
          <Route path='/Individual' element={localStorage.token && localStorage.userType == 2 ? <Dashboard_v2/> : <Navigate to="/" replace={true} /> } />
          <Route path='/Admin' element={<Dashboard_v2 /> } />
          <Route path='/verify-payment' element={localStorage.token && localStorage.userType ? <CodeField /> : <Navigate to="/" replace={true} />} />
          <Route className="PDF" id='PDF' path='/ReportPage' element={<ReportPage /> } />
          <Route path='/ResetPassword' element={<ResetPassword  /> } /> 
          <Route path='/password-reset' element={<PasswordReset  /> } /> 
          <Route path='/LogInForAdmin/ResetPasswordForAdmin' element={<ResetPasswordForAdmin  /> } />
          <Route path='/invitee' element={<Invite />} />
          <Route path='/AddPacks' element={<AddPacks />} />
          <Route path='/test' element={<PuppeteerTrigger />} />
          <Route path='/BenchMarkQustion/:id' element={<BenchMarkQustions />} />
      </Routes>
      </div>
      </appContext.Provider>
  );
}

export default App;


