import { Chip, Stack, useMediaQuery } from "@mui/material"


function ActionsChips ({downloadAllReport, downloadSelectedReport}) {
    const isMobile = useMediaQuery("(max-width:600px)");
return (
    <Stack mt={4} mb={4} direction={isMobile ? 'column' : 'row'} justifyContent='left' alignItems='center'>
        <div style={{cursor: 'pointer'}} onClick={() => downloadAllReport()}><Chip sx={{m: 2,color : 'white',backgroundColor : "#3f8d90", padding : '20px'}} variant="filled" label='Download All Reports' /></div>
        <div style={{cursor: 'pointer'}} onClick={() => downloadSelectedReport()}><Chip sx={{m:2, color : 'white',backgroundColor : "#3f8d90", padding : '20px'}} variant="filled" label='Download Selected Reports' /></div>
    </Stack>
)
}

export default ActionsChips