import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import logo from '../../../../../Media/Logo/Logo-big.png'
import logo2 from '../../../../../Media/Logo/Job-Bar-Logo.jpg'
import ApiContext from "../../Context/context";
function Statistics ({setSubscriptions, btn}) {
  const {
    sentEnrollments,
    packages,
    orders,
    addOns
  } = useContext(ApiContext);
      const twindixOrder = useMemo(() => {
        const result = orders.flatMap((order) =>
          packages?.find((pack) => order.orderable_id === pack.id && pack.exam_id === 1)
        ).filter(Boolean);
        return result.length > 0 ? result : 0
      }, [orders, packages]);
    
      // Memoize jopBarOrder
      const jopBarOrder = useMemo(() => {
        const result = orders.flatMap((order) =>
          packages?.find((pack) => order.orderable_id === pack.id && pack.exam_id === 65)
        ).filter(Boolean);
        return result.length > 0 ? result : 0
      }, [orders, packages]);
    
      // Memoize jopBarOrderAddOne
      const jopBarOrderAddOne = useMemo(() => {
        const result = orders.flatMap((order) =>
          addOns?.find((addOn) => order.orderable_id === addOn.id && addOn.exam_id === 65)
        ).filter(Boolean);
        return result.length > 0 ? result : 0
      }, [orders, addOns]);
    
      // Memoize twindixEnrollmentNum
      const twindixEnrollmentNum = useMemo(() => {
        const result = sentEnrollments?.filter((ele) => ele.exam_id === 1);
        return result?.length > 0 ? result : 0
      }, [sentEnrollments]);
    
      // Memoize jopBarEnrollmentNum
      const jopBarEnrollmentNum = useMemo(() => {
        const result = sentEnrollments?.filter((ele) => ele.exam_id === 65);
        return result?.length > 0 ? result : 0
      }, [sentEnrollments]);
    
      // Memoize formattedDate
      const originalDate = jopBarOrder?.[0]?.created_at?.slice(0, 10);
      const formattedDate = useMemo(() => {
        if (originalDate) {
          const newDate = new Date(originalDate);
          newDate.setFullYear(newDate.getFullYear() + 1);
          return newDate.toISOString().slice(0, 10);
        }
        return 0;
      }, [originalDate]);
    return (
<>
<Box sx={{ 
      background: '#2d6969', // Gradient background
      borderRadius: '30px', // Rounded corners
      display: 'flex',
      alignItems: 'center',
      color: 'white', 
      fontFamily: 'Arial, sans-serif', 
      p: '4px',
      height: '80px',
      border : '2px solid #82bebf',
     }}>

      <Box sx={{width:'20%', textAlign :'center',pl: '20px', mr: '32px' }}> {/* Spacing to the right of the Job Bar section */}
        <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '22px', mb: '4px' }}>Job Bar</Typography> {/* Margin bottom */}
        <Typography variant="body2" sx={{width :'110%', fontSize: '18px' }}>Active subscription</Typography>
      </Box>

      <Stack justifyContent='center' direction='center' sx={{border : '2px solid #82bebf',width: '80%', p: '12px',borderRadius :'30px', bgcolor :'#ffffff', display: 'flex',height: '98%', alignItems: 'center', gap: '24px' }}> {/* Spacing between the sections */}
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h6" sx={{color: '#2d6969', fontWeight: 500, fontSize: '21px' }}>{jopBarOrder?.[0]?.exams_count} Assessments</Typography>
          <Typography variant="body2" sx={{ fontSize: '16px', color: '#2d6969', textDecoration: 'underline' }}>Annual subscription</Typography> {/* Underline and light blue color */}
        </Box>
        <Divider 
              orientation="vertical" 
              flexItem 
              sx={{bgcolor:'#439699', width: '4px', height: 'auto', margin: '0 10px' }}
            />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography variant="h6" sx={{color: '#2d6969', fontWeight: 500, fontSize: '21px' }}>{jopBarOrderAddOne.length > 0 ? `${jopBarOrderAddOne?.[0].exams_count} Assessments` : 'no Add Ons'}</Typography>
          <Typography variant="body2" sx={{ fontSize: '16px', color: '#2d6969', textDecoration: 'underline' }}>Add Ons</Typography> 
        </Box>
        <Divider 
              orientation="vertical" 
              flexItem 
              sx={{bgcolor:'#439699', width: '4px', height: 'auto', margin: '0 10px' }}
            />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}> {/* Align to right */}
          <Typography variant="body1" sx={{color: '#2d6969', fontSize: '15px' }}>Start Date -   {originalDate}</Typography>
          <Typography variant="body1" sx={{color: '#2d6969', fontSize: '15px' }}>End Date -   {formattedDate}</Typography>
        </Box>
      </Stack>
    </Box>
    <Box sx={{width:'100%', display: 'flex',justifyContent:'space-between', alignItems: 'center', fontFamily: 'Arial, sans-serif' }}>

<Box sx={{width:'150px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', m: '24px 24px 0 0' }}> {/* Adjust spacing as needed */}
    <Box>
        <img style={{width: '100%'}} src={logo} alt="dd" />
    </Box>
</Box>

<Box sx={{ 
  background: 'linear-gradient(to right, #2e6294, #6bb4ed)', // Gradient background
  borderRadius: '28px', 
  p: '12px 16px', // Adjust padding
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent:'space-around',
  color: 'white',
  mr: '16px' ,
  width:'300px'
 }}>
  <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{twindixOrder?.[0]?.exams_count}</Typography>
  <Typography variant="body2" sx={{ fontSize: '14px' }}>Total Assessments</Typography>
</Box>

{/* Red Section */}
<Box sx={{
  background: 'linear-gradient(to right, #9c3526, #73adde)', // Gradient background
  borderRadius: '28px',
  p: '12px 16px', // Adjust padding
  display: 'flex',
  flexDirection: 'row',
  justifyContent:'space-around',
  alignItems: 'center',
  color: 'white',
  mr: '16px' ,
  width:'300px'
}}>
  <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{twindixEnrollmentNum?.length}</Typography>
  <Typography variant="body2" sx={{ fontSize: '14px' }}>Used Assessments</Typography>
</Box>

{/* Green Section */}
<Box sx={{
  background: 'linear-gradient(to right, #387a32, #6ebae8)', // Gradient background
  borderRadius: '18px',
  p: '12px 16px', 
  display: 'flex',
  flexDirection: 'row',
  justifyContent:'space-around',
  alignItems: 'center',
  color: 'white',
  width:'300px'
}}>
  <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{twindixOrder?.[0]?.exams_count - twindixEnrollmentNum?.length }</Typography>
  <Typography variant="body2" sx={{ fontSize: '14px' }}>Available Assessments</Typography>
</Box>

</Box>
    <Box sx={{marginBottom:'20px', width:'100%', display: 'flex', alignItems: 'center',justifyContent:'space-between', fontFamily: 'Arial, sans-serif' }}>

<Box sx={{width:'150px', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', mr: '24px' }}> {/* Adjust spacing as needed */}
    <Box>
        <img style={{width: '95%'}} src={logo2} alt="dd" />
    </Box>
</Box>

<Box sx={{ 
  background: 'linear-gradient(to right, #2e6294, #6bb4ed)', // Gradient background
  borderRadius: '28px', 
  p: '12px 16px', // Adjust padding
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent:'space-around',
  color: 'white',
  mr: '16px' ,
  width:'300px'
 }}>
  <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{jopBarOrder?.[0]?.exams_count}</Typography>
  <Typography variant="body2" sx={{ fontSize: '14px' }}>Total Assessments</Typography>
</Box>

{/* Red Section */}
<Box sx={{
  background: 'linear-gradient(to right, #9c3526, #73adde)', // Gradient background
  borderRadius: '28px',
  p: '12px 16px', // Adjust padding
  display: 'flex',
  flexDirection: 'row',
  justifyContent:'space-around',
  alignItems: 'center',
  color: 'white',
  mr: '16px' ,
  width:'300px'
}}>
  <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{jopBarEnrollmentNum?.length}</Typography>
  <Typography variant="body2" sx={{ fontSize: '14px' }}>Used Assessments</Typography>
</Box>

{/* Green Section */}
<Box sx={{
  background: 'linear-gradient(to right, #387a32, #6ebae8)', // Gradient background
  borderRadius: '18px',
  p: '12px 16px', 
  display: 'flex',
  flexDirection: 'row',
  justifyContent:'space-around',
  alignItems: 'center',
  color: 'white',
  width:'300px'
}}>
  <Typography variant="h5" sx={{ fontWeight: 600, fontSize: '20px' }}>{ jopBarOrder?.[0]?.exams_count - jopBarEnrollmentNum?.length }</Typography>
  <Typography variant="body2" sx={{ fontSize: '14px' }}>Available Assessments</Typography>
</Box>

</Box>
{btn && 
      <Box onClick={() => setSubscriptions()} sx={{marginBottom:'20px', display: 'flex', justifyContent: 'center' }}>
        <Button sx={{borderRadius : '30px'}} variant="contained" color="warning">
          + Add More Assessments
        </Button>
      </Box>
}
</>
    )
}

export default Statistics