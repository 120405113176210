import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useSearch } from "../../Context/SearchContext";

function SearchInput() {
  const { searchQuery, setSearchQuery } = useSearch();

  return (
    <TextField
      id="Search"
      label="Search"
      variant="outlined"
      sx={{
        borderRadius: "10px",
        backgroundColor: "white",
        "& .MuiOutlinedInput-root": {
          borderRadius: "10px",
        },
      }}
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
    />
  );
}

export default SearchInput;
