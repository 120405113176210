import React, { useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';

const CustemTypography = ({ label }) => {
  return (
    <Typography
      sx={{
        fontSize: '22px',
        textDecoration: 'underline',
        color: 'white',
        mb: '20px',
      }}
    >
      {label}:
    </Typography>
  );
};

export default function EditExam({editId,  open, setOpen }) {
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    price: "",
    template_id: "",
    questions: null,
    question_algorithms: null,
    combined_algorithms: null,
    cover_image: null,
  });

  const [fileNames, setFileNames] = useState({
    questionsname: "",
    question_algorithms: "",
    combined_algorithms: "",
    cover_image: "",
  });

  const [exams, setExams] = useState(null);
  const [allexams, setAllExams] = useState([]);
  const [loading, setLoading] = useState(false);


  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    const isFileInput = type === "file";

    if (isFileInput) {
      setFileNames((prevFileNames) => ({
        ...prevFileNames,
        [name]: files[0]?.name || "",
      }));
      setFormData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      const isModified = value !== exams?.[name];
      setFormData((prevData) => ({
        ...prevData,
        [name]: isModified ? value : exams?.[name],
      }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("description", formData.description);
    formDataToSend.append("price", formData.price);
    formDataToSend.append("template_id", formData.template_id);

    if (formData.questions) {
      formDataToSend.append("questions", formData.questions);
    }
    if (formData.question_algorithms) {
      formDataToSend.append("question_algorithms", formData.question_algorithms);
    }
    if (formData.combined_algorithms) {
      formDataToSend.append("combined_algorithms", formData.combined_algorithms);
    }
    if (formData.cover_image) {
      formDataToSend.append("cover_image", formData.cover_image);
    }

    // Log formDataToSend to the console
    for (let pair of formDataToSend.entries()) {
      console.log(pair[0] + ': ' + pair[1]);
    }
    axios
      .post(
        `https://api.twindix.com/dashboard/exams/${editId}?_method=put`,
        formDataToSend,
        {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
            "Content-Type": "multipart/form-data",
          },
          maxBodyLength: Infinity,
        }
      )
      .then((res) => {
        sessionStorage.removeItem("editId");
        setOpen(false)
        setLoading(false);
        setFormData({
          title: '',
          description: '',
          price: '',
          template_id: '',
          questions: '',
          question_algorithms: '',
          combined_algorithms: '',
          cover_image: '',
      });
      })
      .catch((error) => {
        setFormData({
          title: '',
          description: '',
          price: '',
          template_id: '',
          questions: '',
          question_algorithms: '',
          combined_algorithms: '',
          cover_image: '',
      });
        setLoading(false);
      });
  };


  useEffect(() => {
    if (editId) {
      axios
        .get(`https://api.twindix.com/dashboard/exams/${editId}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Use sessionStorage for token
          },
        })
        .then((res) => {
          setExams(res.data.payload);
          setFormData({
            title: res.data.payload.title || "",
            description: res.data.payload.description || "",
            price: res.data.payload.price || "",
            template_id: res.data.payload.template_id || "",
          });
          setFileNames({
            questionsname: res.data.payload.questionsname || "",
            question_algorithms: res.data.payload.question_algorithms || "",
            combined_algorithms: res.data.payload.combined_algorithms || "",
            cover_image: res.data.payload.cover_image || "",
          });
        })
        .catch((error) => {
          console.error("Error fetching exam data:", error);
        });
    }
    axios
        .get(`https://api.twindix.com/dashboard/exams`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Use sessionStorage for token
          },
        })
        .then((res) => {
          setAllExams(res.data.payload.data);
          console.log(res)
        })
        .catch((error) => {
          console.error("Error fetching exam data:", error);
        });
  }, [editId]); // Refetch data whenever editI

    const [templates, settemplates] = useState([]);

  useEffect(() => {
    if (!templates.length) {
      axios.get("https://api.twindix.com/dashboard/templates", {
        headers: { Authorization: "Bearer " + localStorage.token },
      }).then(res => {
        settemplates(res.data.payload.data);
      });
    }
  }, [templates]);

  return (
    <Dialog maxWidth="md" open={open} onClose={() => setOpen(false)}>
      <Box sx={{ bgcolor: '#929292', width: '450px' }}>
        <DialogTitle>
          <Typography sx={{ textAlign: 'center', color: 'white', textDecoration: 'underline' }}>
            Edit {formData.title}
          </Typography>
        </DialogTitle>
        <form onSubmit={handleSubmit} style={{border: '0'}}>
          <DialogContent
                                    sx={{
                maxHeight: "500px", 
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  width: "6px",
                },
                "&::-webkit-scrollbar-thumb": {
                  borderRadius: "10px",
                  background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                },
                "&::-webkit-scrollbar-track": {
                  background: "#929292",
                  borderRadius: "10px",
                },
              }}
          >
                <CustemTypography label='Exam Name' />
                  <input
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    style={{
                      padding: '10px',
                      fontSize: '10px',
                      border: '0',
                      borderRadius: '4px',
                      width: '100%',
                      height: '40px',
                    }}
                  />
                  {/* <select
                    name="title"
                    value={formData.title}
                    onChange={handleChange}
                    style={{
                      padding: '10px',
                      fontSize: '10px',
                      border: '0',
                      borderRadius: '4px',
                      width: '100%',
                      height: '40px',
                    }}
                  >
                    <option value="" disabled>
                      Select exam title
                    </option>
                    {allexams?.map((ele) => {
                      return (
                        <option value={ele.id}>{ele.title}</option>
                      )
                    })}
                  </select> */}
                  <CustemTypography label='Price' />
                  <input
                    type="text"
                    name="price"
                    value={formData.price}
                    onChange={handleChange}
                    placeholder="Insert Price"
                    style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
                  />
                  <CustemTypography label='description' />
                  <input
                    type="text"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    placeholder="Insert description"
                    style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
                  />
                  <CustemTypography label='template' />
                  <select
                    type="text"
                    name="template_id"
                    value={formData.template_id}
                    onChange={handleChange}
                    placeholder="Insert template_id"
                    style={{ padding: '10px', fontSize: '10px', border: '0', borderRadius: '4px', width: '100%', height: '40px' }}
                  >
                    <option value="" disabled>
                      Select template
                    </option>
                    {templates?.map((ele) => {
                      return (
                        <option value={ele.id}>{ele.name}</option>
                      )
                    })}
                  </select>
              <React.Fragment> 
              <CustemTypography label='Questions' />
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                   
                  onChange={handleChange}
                  type="file"
                  name="questions" />
                  {fileNames.questions? (
                    <Typography>{fileNames.questions}</Typography>
                  ) : (
                    <Typography>Drag and drop a file, or click to select one</Typography>
                  )}
                </Box>
                <CustemTypography label=' Question Algorithms' />
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                   
                  onChange={handleChange}
                  type="file"
                  name="question_algorithms" />
                  {fileNames.question_algorithms? (
                    <Typography>{fileNames.question_algorithms}</Typography>
                  ) : (
                    <Typography>Drag and drop a file, or click to select one</Typography>
                  )}
                </Box>
                <CustemTypography label='Combined Algorithms' />
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                   
                  onChange={handleChange}
                  type="file"
                  name="combined_algorithms" />
                  {fileNames.combined_algorithms? (
                    <Typography>{fileNames.combined_algorithms}</Typography>
                  ) : (
                    <Typography>Drag and drop a file, or click to select one</Typography>
                  )}
                </Box>
                <CustemTypography label='Cover Image' />
                <Box
                  sx={{
                    border: '2px dashed white',
                    borderRadius: '4px',
                    padding: '20px',
                    textAlign: 'center',
                    color: 'white',
                    cursor: 'pointer',
                    backgroundColor : 'transparent',
                    margin : '12px'
                  }}
                >
                  <input                   
                  onChange={handleChange}
                  type="file"
                  name="cover_image" />
                  {fileNames.cover_image? (
                    <Typography>{fileNames.cover_image}</Typography>
                  ) : (
                    <Typography>Drag and drop a file, or click to select one</Typography>
                  )}
                </Box>
              </React.Fragment>
          </DialogContent>
          <DialogActions>
            <Button type="submit" variant="contained" sx={{ background: '#5ac4cb', margin: 'auto' }}>
              Send
            </Button>
          </DialogActions>
        </form>
      </Box>
    </Dialog>
  );
}
