import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import { Box, Grid, TextField, Typography, Stack, DialogActions, Snackbar } from '@mui/material';
import ApiContext from '../../../Context/context';

export default function AddRole({setrefrech, open, setOpen }) {
  const [permissions, setPermissions] = React.useState([]); // Selected permissions
  const [name, setName] = React.useState(''); // Role name
  const {
    allPermissions
  } = React.useContext(ApiContext);

  // Handle checkbox change
  const handleCheckboxChange = (permissionId) => {
    setPermissions((prev) =>
      prev.includes(permissionId)
        ? prev.filter((id) => id !== permissionId) // Remove if unchecked
        : [...prev, permissionId] // Add if checked
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    const payload = {
      name,
      permission: permissions, // Send selected permission IDs
    };

    try {
      const response = await fetch('https://api.twindix.com/roles', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      const data = await response.json();
      console.log('Response:', data);
      handleClickSnackbar()
      setPermissions([])
      setName('')
      setrefrech()
      setOpen(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClickSnackbar = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };


  return (
    <React.Fragment>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <Box
          sx={{
            bgcolor: '#929292',
            width: '450px',
          }}
        >
          <DialogContent
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              },
              '&::-webkit-scrollbar-track': {
                background: '#929292',
                borderRadius: '10px',
              },
            }}
          >
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: '22px',
                    textDecoration: 'underline',
                    color: 'white',
                    mb: '20px',
                  }}
                >
                  Name:
                </Typography>
                <TextField
                  fullWidth
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Insert role name"
                  variant="outlined"
                  size="small"
                  sx={{ backgroundColor: 'white', borderRadius: 1 }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontSize: '22px',
                    textDecoration: 'underline',
                    color: 'white',
                    mb: '20px',
                  }}
                >
                  Permissions:
                </Typography>
                <Stack>
                  <Grid container spacing={2}>
                    {allPermissions.map((permission) => (
                      <Grid item xs={6} key={permission.id} sx={{ color: 'white' }}>
                        <label>
                          <input
                            type="checkbox"
                            value={permission.id}
                            checked={permissions.includes(permission.id)}
                            onChange={() => handleCheckboxChange(permission.id)}
                            style={{ marginRight: '8px' }}
                          />
                          {permission.name}
                        </label>
                      </Grid>
                    ))}
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
                borderRadius: '10px',
                margin: 'auto',
              }}
              onClick={handleSubmit}
              variant="contained"
            >
              Create Role
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Role Added"
      />
    </React.Fragment>
  );
}
