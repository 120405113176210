import { useContext, useState } from "react";
import Badel from "../../../../Media/imges/WhatsApp Image 2024-01-09 at 12.28.12_84c2f948.jpg";
import { Box, Stack, Typography, Button, Divider } from "@mui/material";
import Statistics from "./Component/statistics";
import ApiContext from "../Context/context";

function Dashboard({setSubscriptions, setshowBackBtn}) {
    const { exams } = useContext(ApiContext)
    return (
        <>
        <Statistics btn={true} setSubscriptions={setSubscriptions} /> 
        <Box sx={{height: '850px'}}>
            <Box>
                <Stack spacing={6}>
                    {exams && exams?.map((exam, index) => (
                        <Stack
                            key={exam.id}
                            direction={index % 2 === 0 ? "row" : "row-reverse"}
                            spacing={4}
                            alignItems="center"
                        >
                            <Box sx={{width: '50%'}}>
                                <img
                                    src={exam.cover_image || Badel}
                                    alt="Exam Cover"
                                    style={{ borderRadius: 8 }}
                                />
                            </Box>
                            <Box height='100%' margin='auto 32px'>
                                <Typography textAlign='justify'  fontSize='18px' fontWeight='400' sx={{color: '#929292', letterSpacing: '-1px', lineHeight: '25px'}} variant="body2">
                                    {window.innerWidth < 750
                                        ? exam.description
                                        : exam.description}
                                </Typography>
                            </Box>
                        </Stack>
                    ))}
                </Stack>
            </Box>
        </Box>
        </>
    );
}

export default Dashboard;
