import { useContext, useEffect, useState } from "react";
import Header from "./Component/Header/Index";
import NavBar from "./Component/NavBar";
import TableCom from "./Component/Table";
import axios from "axios";
import { Container } from "@mui/material";
import Payment from "../../../Pages/Payment/Payment";

function IndividualComponent({ setshowBackBtn, BackToLastPage, toggleDrawer, isAssessment, isBuyAssessment, toggleCart }) {
  const [enroll, setEnroll] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");


  // Fetch enrollment data
  useEffect(() => {
    if (!enroll.length) {
      axios
        .get("https://api.twindix.com/enrollments", {
          headers: { Authorization: "Bearer " + localStorage.token },
        })
        .then((res) => {
          setEnroll(res.data.payload.data);
        });
    }
  }, []);
  const examNameToIdMap = {
    "Twindix Executive": 1,
    "Jop Bar": 65,
    // Add more exam names and IDs as needed
  };
  // Filter enrollments based on search query
  const matchedExamId = Object.keys(examNameToIdMap).find((examName) =>
    examName.toLowerCase().includes(searchQuery.toLowerCase())
  );
  const examIdToFilter = matchedExamId ? examNameToIdMap[matchedExamId] : null;

  // Filter enrollments based on `code` or `exam_id`
  const filteredEnroll = searchQuery !== ""
    ? enroll?.filter((item) =>
        item?.code?.toLowerCase().includes(searchQuery.toLowerCase()) || 
        (examIdToFilter && item?.exam_id === examIdToFilter)
      )
    : enroll;

  useEffect(() => {
    setshowBackBtn(false);
  }, []);

  return (
    <>
      <NavBar toggleDrawer={toggleDrawer} toggleCart={toggleCart} />
      {!isAssessment && BackToLastPage()}
      <Container maxWidth="lg">
        <Header searchQuery={searchQuery} setSearchQuery={setSearchQuery} headerTilte={isAssessment ? "Assessment" : isBuyAssessment ? "Buy Assessment" : "Assessment"} />
        {isBuyAssessment ? <Payment /> : <TableCom displayedRows={filteredEnroll} />}
      </Container>
    </>
  );
}

export default IndividualComponent;
