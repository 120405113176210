import { Chip, Stack } from "@mui/material"


function BigChips ({UserName , FinishedAssessment,PendingAssessment}) {
    return (
        <Stack mt={4} mb={4} direction='row' justifyContent='left' alignItems='center' >
            <Chip sx={{width : 'calc(100% / 3)', mr: 2, padding: '30px 70px', borderRadius : "140px", border: '3px solid #3e7e72' , color: '#3e7e72', fontSize :"18px", fontWeight: '700'}} label={UserName} variant="outlined" />
            <Chip                 
            sx={{
                width : 'calc(100% / 3)',
                mr: 2,
                    background: "linear-gradient(45deg, #77c853, #347461)",
                    color : 'white',
                   padding: '30px 70px',
                    fontSize :"18px",
                     fontWeight: '700',
                     borderRadius : "140px"
                }}
                dire label={`${FinishedAssessment} Finished Assessments`}  />
            <Chip                 
            sx={  {
                width : 'calc(100% / 3)',
                mr: 2,
                    background: "linear-gradient(45deg, #ad301a, #d87631)",
                    color : 'white',
                   padding: '30px 70px',
                    fontSize :"18px",
                     fontWeight: '700',
                     borderRadius : "140px"
                }}
                dire label={`${PendingAssessment} Pending Assessments`}  />
        </Stack>
    )
}

export default BigChips