import React, { useContext, useEffect, useState, useMemo, useCallback } from "react";
import {
  Checkbox,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  Box,
  Typography,
  Snackbar,
  IconButton,
  DialogTitle,
  DialogContent,
  Dialog,
  Grid2,
  DialogActions,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
import { appContext } from "../../../../../App";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import BigChips from "../BigChips/Index";
import ActionsChips from "../ActionsChips";
import LoadingSpinner from "../../../../../FemilerCom/loading/spinner";
import CloseIcon from '@mui/icons-material/Close';

function MyTableWithPagination({ displayedRows }) {
  const isLoggedIn = useContext(appContext);
  const name = isLoggedIn?.userName?.replaceAll(" ", "-")
  const [open, setOpen] = useState();
  const [msg, setmsg] = useState('');
  const [downloadDetails, setDownloadDetails ] = useState({});
  const [selectLanguage, setSelectLanguage ] = useState(false);

  const [selectedValue, setSelectedValue] = useState('ar');

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const navigate = useNavigate();

  const finishedAssessment = useMemo(
    () => displayedRows.filter((row) => row.finished === 1).length,
    [displayedRows]
  );

  const pendingAssessment = useMemo(
    () => displayedRows.filter((row) => row.finished === 0).length,
    [displayedRows]
  );

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleRowSelect = (rowId) => {
    setSelectedRows((prev) =>
      prev.includes(rowId) ? prev.filter((id) => id !== rowId) : [...prev, rowId]
    );
  };

  const handleSelectAll = () => {
    if (selectAll) {
      setSelectedRows([]);
    } else {
      setSelectedRows(displayedRows.map((row) => row.finished === 1 && row.id));
    }
    setSelectAll(!selectAll);
  };

  const downloadReports = async (ids) => {
    setLoader(true)
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/reports-download",
        {  enrollment_ids: ids } 
      );
      if (response) {
        setmsg('report is Downloading')
    handleClick()
        setLoader(false)
        console.log(response.data.payload.reports_zip_file)
        window.open(response.data.payload.reports_zip_file, "_blank");
      }
    } catch ( response) {
      setmsg('report is generating')
    handleClick()

      setLoader(false)
    }
  };
  const reGenerateReports = async (id, exam_id) => {
    setLoader(true)
    try {
      const response = await axios.post(
        "https://vmi2353814.contaboserver.net/api/report-generate",
        {
          "url": `https://assessment.twindix.com/reportPage?lang=${selectedValue}&enro_id=${id}&exam_id=${exam_id}&name=${name}`,
          "isLogged": true,
          "regenerate": true,
          "token": localStorage.token,
          "userType": "2",
          "enrollment_id":id
      }
      );
      if (response) {
        setmsg('report is Downloading')
    handleClick()
        setLoader(false)
        console.log(response.data.payload.reports_zip_file)
        window.open(response.data.payload.reports_zip_file, "_blank");
      }
    } catch ( response) {
      setmsg('report is generating')
    handleClick()

      setLoader(false)
    }
  };

  const handleDownloadAll = () => {
    const ids = displayedRows
      .filter((row) => row.finished === 1)
      .map((row) => row.id);
  
    downloadReports(ids);
  };
  const [rowsToShow, setRowsToShow] = useState(25);
  const [scrollLoading, setScrollLoading] = useState(false);

  const handleScroll = useCallback(() => {
    const scrollableDiv = document.getElementById("table-container");
    if (
      scrollableDiv.scrollTop + scrollableDiv.clientHeight >=
      scrollableDiv.scrollHeight - 50
    ) {
      if (rowsToShow < displayedRows.length) {
        setScrollLoading(true);
        setTimeout(() => {
          setRowsToShow((prev) => Math.min(prev + 25, displayedRows.length));
          setScrollLoading(false);
        }, 500);
      }
    }
  }, [rowsToShow, displayedRows.length]);

  useEffect(() => {
    const scrollableDiv = document.getElementById("table-container");
    scrollableDiv?.addEventListener("scroll", handleScroll);
    return () => {
      scrollableDiv?.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

    const [openDownload, setopenDownload] = React.useState(false);
  
    const handleClickOpen = () => {
      setopenDownload(true);
    };
  
    const handleCloseDownload = () => {
      setopenDownload(false);
      setSelectLanguage(false)
    };
  return (
    <Box>
    {loader &&  <LoadingSpinner /> }
      <BigChips
        UserName={isLoggedIn.userName}
        FinishedAssessment={finishedAssessment}
        PendingAssessment={pendingAssessment}
      />
      <ActionsChips
        downloadAllReport={handleDownloadAll}
        downloadSelectedReport={() => downloadReports(selectedRows)}
      />
      <TableContainer id="table-container" style={{ maxHeight: 500, overflowY: "auto" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  checked={selectAll}
                  onChange={handleSelectAll}
                  aria-label="Select all rows"
                />
              </TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Assessment Name</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {displayedRows.map((row) => (
              <TableRow key={row.id}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(row.id)}
                    onChange={() => handleRowSelect(row.id)}
                    aria-label={`Select row ${row.id}`}
                  />
                </TableCell>
                <TableCell>{row.code}</TableCell>
                <TableCell>
                  {row.finished === 1 ? <Typography color="success">Finished</Typography> : <Typography color="error">Not Finished</Typography>}
                </TableCell>
                <TableCell>{row.exam_id === 1 ? "Twindix Executive" : "Job Bar"}</TableCell>
                <TableCell>
                <Button
                    variant="contained"
                    style={{
                      background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                      color: "white",
                    }}
                    onClick={() => {
                      if (row.finished === 1) {
                        setDownloadDetails(row);
                        handleClickOpen();
                      } else {
                        navigate(`/Qustion/${row.exam_id}/${row.id}`);
                      }
                    }}
                  >
                    {row.finished === 1 ? "Download Report" : "Take Assessment"}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {scrollLoading && <Typography>Loading more rows...</Typography>}
      <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={msg}
      />
      <Dialog
        maxWidth="lg"
        open={openDownload}
        onClose={handleCloseDownload}
      >
        <Box
          sx={{
            bgcolor: '#929292',
            width: '600px',
          }}
        >
        <IconButton onClick={handleCloseDownload}><CloseIcon/></IconButton>
          <DialogTitle>
            <Typography sx={{width: '50%', margin: 'auto', color: 'white', textDecoration: 'underline' }}>
            It may take 2-5 minutes to generate the report in the new language, and it will be set as the default report.
            </Typography>
          </DialogTitle>
          <DialogContent>
          {selectLanguage ? 
          <Grid2 container direction="column" spacing={4}>
          <Grid2 item xs={12}>
        <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
        language:
        </Typography>
        <Select
                  sx={{
            padding: "10px",
            fontSize: "10px",
            borderRadius: "4px",
            width: "100%",
            height: "40px",
            backgroundColor: "white",
          }}
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
          displayEmpty
          >
      <MenuItem value="" disabled>
        Select Language
      </MenuItem>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ar">Arabic</MenuItem>
    </Select>
      </Grid2>
    <Button
                    variant="contained"
                    style={{
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: '10xp'
                        }}
                        onClick={() => {
                          if (!downloadDetails) return; 
                          // window.open(`/reportPage?enro_id=${downloadDetails.id}&exam_id=${downloadDetails.exam_id}`);
                          // downloadReports([downloadDetails.id]);
                          
                          handleCloseDownload()
                          // localStorage.setItem("language", selectedValue);
                          reGenerateReports(downloadDetails.id, downloadDetails.exam_id);
                        }}
                  >
                    Regenerate Report With {selectedValue === 'ar' ? "Arabic" : 'English'} Language
                  </Button>
            </Grid2>
            :
            <Stack justifyContent='space-between' direction='row' width='100%' margin='auto'>
            <Button
                    variant="contained"
                    style={{
                      padding: '6px',
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: 'auto'
                        }}
                        onClick={() => {
                          if (!downloadDetails) return; 
                          
                          downloadReports([downloadDetails.id]);
                          handleCloseDownload()
                        }}
                  >
                    Download Current Report
                  </Button>
          <Button
                    variant="contained"
                    style={{
                          padding: '6px',
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: 'auto'
                        }}
                        onClick={() => {setSelectLanguage(true)}}
                  >
                    Regenerate Another language
                  </Button>
            </Stack>
            }
          </DialogContent>
          <DialogActions> 
          {/* <Button onClick={() => window.open(`/reportPage?lang=${selectedValue}&enro_id=${downloadDetails.id}&exam_id=${downloadDetails.exam_id}&name=${name}`)}>open</Button> */}
          </DialogActions>
        </Box>
      </Dialog>
    </Box>
  );
}

export default MyTableWithPagination;
