import { Button, Stack, Typography } from "@mui/material";
import axios from "axios";
import { useContext, useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import AddCompanyForm from "./Forms/AddCompanyForm";
import { appContext } from "../../../../../App";
import Swal from "sweetalert2";
import ApiContext from "../../Context/context";


function CompanyComponent ({ setSelectedCompany, setshowPostions}) {
  const [open, setOpen] = useState(false);
  const {
    employees,
    companies,
  } = useContext(ApiContext);
          let isLoggedIn = useContext(appContext);
          const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
            (acc, role) => {
              if (role.name === 'company-create') acc.companyCreate = true;
              return acc;
            },
            { companyCreate: false}
          );
      
        const hasPermission = () => { 
          if (isLoggedIn?.userData?.managed_by !== null) {
            if (!hasActionPermission.companyCreate) {
              Swal.fire({
                icon: 'error',
                title: 'Access Denied',
                text: 'You do not have permission to access this.',
                timer: 3000,
                showConfirmButton: true,
              });
            }
          }
        };
    return (
        <>
            <Button
              onClick={() => {
                hasPermission()
                if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.companyCreate){
                  setOpen(true)
                }
                }}
              variant="contained"
              sx={{
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              }}
            >
            <AddIcon />
              Add Company
            </Button>
            {companies.filter((ele) => ele.exam_id === 65)
            ?.map((company) => {
                  const employeeCount = employees?.filter((emp) => emp.company_id === company.id).length;
                return (
                  <Stack
                    key={company.id}
                    p={2}
                    borderBottom="1px solid rgb(192, 192, 192)"
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography
                      onClick={() => {
                        setshowPostions(true)
                        console.log(setSelectedCompany)
                        setSelectedCompany(company)
                        }}
                      sx={{
                        width :'200px',
                        cursor: "pointer",
                        color: "#5e5e5e",
                        textDecoration: "underline",
                        fontWeight: "bold",
                      }}
                    >
                      {company.name}
                    </Typography>
                    <Typography sx={{width:'150px', color: "#5e5e5e", textDecoration: "underline" }}>{company.phone}</Typography>
                    <Typography sx={{width:'120px', color: "#5e5e5e", textDecoration: "underline" }}>{company.mail}</Typography>
                    <Typography sx={{width:'100px', color: "#5e5e5e", textDecoration: "underline" }}>
                      {company.updated_at.slice(0, 10)}
                    </Typography>
                    <Typography sx={{ color: "#3f8e8f", border: '2.5px solid #3f8e8f', borderRadius: '12px', padding: '6px', fontWeight: 'bold' }}>
                      {employeeCount} {employeeCount === 1 ? "Participant" : "Participant"}
                    </Typography>
                </Stack>
                );
              })
        
    }
    <AddCompanyForm exam_id={65} open={open} setOpen={setOpen} />

    </>
    )
}

export default CompanyComponent