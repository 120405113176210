import React, { useContext, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Typography } from "@mui/material";
import axios from "axios";
import ApiContext from "../../../Context/context";

export default function AddCompanyForm({ open, setOpen , exam_id}) {
  const {refetchData} = useContext(ApiContext)
  // States for form fields
  const [companyName, setCompanyName] = useState("");
  const [positionName, setpositionName] = useState("");
  const [contactPerson, setContactPerson] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [mail, setMail] = useState("");
  const [error, setError] = useState(null);

  // Close dialog
  const handleClose = () => {
    setOpen(false);
  };

  // Handle "Add Company" submission
  const handleAddCompany = () => {
    const payload = {
      name: companyName,
      position : positionName,
      contact_person: contactPerson,
      phone: phoneNumber,
      mail: mail,
      exam_id:exam_id,
    };

    axios
      .post("https://api.twindix.com/companies", payload, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((response) => {
        refetchData()
        console.log("Company added successfully:", response.data);
        setOpen(false); // Close the dialog on success
      })
      .catch((error) => {
        console.error("Error adding company:", error);
        setError("Failed to add company. Please try again.");
      });
  };

  return (
    <React.Fragment>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: "450px",
          }}
        >
          <DialogTitle>
            <Typography sx={{color: 'white'}}>Add New Company</Typography>
          </DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Company Name:</Typography>
                <input
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                  placeholder="Enter company name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>position Name:</Typography>
                <input
                  value={positionName}
                  onChange={(e) => setpositionName(e.target.value)}
                  placeholder="Enter position name"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Contact Person:</Typography>
                <input
                  value={contactPerson}
                  onChange={(e) => setContactPerson(e.target.value)}
                  placeholder="Enter contact person"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Phone Number:</Typography>
                <input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  placeholder="Enter phone number"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Mail:</Typography>
                <input
                  value={mail}
                  onChange={(e) => setMail(e.target.value)}
                  placeholder="Enter mail"
                  style={{ padding: "10px", width: "100%", borderRadius: "4px", border: "0", height: "40px" }}
                />
              </Grid>
            </Grid>
            {error && (
              <Typography color="red" sx={{ mt: 2 }}>
                {error}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              variant="contained"
              onClick={handleAddCompany}
            >
              Add Company
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
