import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    Snackbar,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import AddIcon from "@mui/icons-material/Add";
  import RemoveIcon from "@mui/icons-material/Remove";
  import { useContext, useEffect, useState } from "react";
  import axios from "axios";
import { appContext } from "../../../../../App";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Swal from "sweetalert2";
import ApiContext from "../../Context/context";
  export default function FlextPurch() {
    const {
    packages,
  } = useContext(ApiContext);
    // State for quantities
    const [count, setCount] = useState(1);
    const [count1, setCount1] = useState(1);
  
    // State for purchase feedback
    const [purchaseExam, setPurchaseExam] = useState(null);
  
    // State for coupon
    const [coupon, setCoupon] = useState({});
    const [isCouponValid, setIsCouponValid] = useState(false);
    const [couponError, setCouponError] = useState("");
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [FinalPrice, setFinalPrice] = useState();
    const [debriefing, setdebriefing] = useState();
    const [examId, setexamId] = useState();
    const [isDebriefing, setisDebriefing] = useState();

    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };

    const handleClick1 = () => {
      setOpen1(true);
    };
  
    const handleClose1 = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpen(false);
    };
    const handleChange = (e, item) => {
        const newValue = e.target.value;
        setCoupon((prevValues) => ({
          ...prevValues,
          [item]: newValue,
        }));
      };
  
    // State for showing additional text
    const [showText, setShowText] = useState(false);
    const toggleText = () => {
      setShowText((prev) => !prev);
    };
  
  
    // Function to check coupon validity
    const checkCoupon = (examId) => {
        const couponCode = coupon[examId] ?? '';
        setexamId(examId)
      axios
        .post(
          "https://api.twindix.com/coupon/package",
          { package_id: examId, code: couponCode, qty: count },
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        )
        .then((res) => {
          setFinalPrice(res.data.payload)
          if (res.data.success) {
            setIsCouponValid(true);
            setCouponError("");
          } else {
            setCouponError("Invalid coupon.");
            setIsCouponValid(false);
          }
        })
        .catch(() => {
          setIsCouponValid(false);
          handleClick()
        });
    };
  
    // Function to handle purchase
    const buyExam = (examId) => {
  
      axios
        .post(
          "https://api.twindix.com/payment/package",
          { package_id: examId, qty: count, code: coupon?.[1] ?? '', is_debriefing: debriefing === true ? 1 : 0},
          {
            headers: { Authorization: "Bearer " + localStorage.getItem("token") },
          }
        )
        .then((res) => {
          const transactionId = res?.data?.payload?.transaction_id;
          localStorage.setItem('transaction_id', transactionId ?? 'no exam transaction Id');
          localStorage.setItem('is_debriefing', debriefing === true ? 1 : 0);
          localStorage.setItem('exam_Id', examId);
          setPurchaseExam("Redirecting to complete payment in 5 seconds");
          setTimeout(() => {
            window.location.replace(res.data.payload.redirect);
          }, 3000);
        })
        .catch((error) => {
            handleClick1()
          console.error("Error during purchase:", error);
        });
    };

    

    const [openEditDialog, setopenEditDialog] = useState(false)
    const handleCloseEditDialog= () => setopenEditDialog(false)
    const handleOpenEditDialog= () => setopenEditDialog(true)
    const [specifiedPackege, setspecifiedPackege] = useState([])

    const specifiedPackegeFun = (packId) => {
      axios
        .get(
          `https://api.twindix.com/packages/${packId}`)
        .then((res) => {
          setspecifiedPackege(res?.data?.payload)
        })
    };    
              let isLoggedIn = useContext(appContext);
            const hasRoleCreatePermission = isLoggedIn?.userData?.roles.some(
              (role) => role.name !== 'package-payment'
            );
          
            const hasPermission = () => { 
              if (isLoggedIn?.userData?.managed_by !== null) {
                if (hasRoleCreatePermission) {
                  Swal.fire({
                    icon: 'error',
                    title: 'Access Denied',
                    text: 'You do not have permission to access this.',
                    timer: 3000,
                    showConfirmButton: true,
                  });
                }
              }
            };
    return (
        <>
      {packages &&
        packages?.filter((ele) => ele.name === "Twindix Executives" || ele.name === "Job Bar")
      ?.map((exam) => (
        <Stack
          key={exam.id}
          width="100%"
          direction="row"
          spacing={4}
          m={4}
          sx={{ borderBottom: "3px solid #4ca88f", position: "relative" }}
        >
          {/* Purchase Feedback */}
          {purchaseExam && (
            <div className="alert alert-success position-absolute top-0 m-3">
              {purchaseExam}
            </div>
          )}
  
          {/* Exam Image */}
          <Box>
            <img
              src={exam.cover_image}
              alt="Exam Cover"
            />
          </Box>
  
          {/* Exam Details */}
          <Stack flex={1}>
            {/* Exam Title */}
            <Typography
              variant="h4"
              component="h4"
              m="auto"
              mb={2}
              sx={{ color: "#2b6565", textDecoration: "underline" }}
            >
              {exam.name}
            </Typography>
  
            {/* Exam Description */}
            <Typography
              maxWidth={700}
              textAlign="justify"
              ml={8}
              sx={{ color: "#787878" }}
            >
              {exam.description}
            </Typography>
  
            {/* Note */}
            <Typography m={3} ml={8}>
              <b>Please Note:</b>This product is delivered digitally. Payment & Delivery
            </Typography>
  
            {/* Coupon Error Message */}
            {couponError && (
              <Typography ml={10} color="red">
                {/* {couponError} */}
              </Typography>
            )}
  
            {/* Assessment Only Section */}
            <Stack
              spacing={3}
              m={2}
              direction="row"
              width={500}
              justifyContent="space-between"
              alignItems='center'
            >
              <Stack width='100%' ml={3} justifyContent="center" alignItems="center">
                <Typography
                
                  sx={{ fontSize: "16px", fontWeight: "bold", color: "#2b5b5b" }}
                >
                  {FinalPrice ? FinalPrice?.price  : exam.price }$
                </Typography>
                <Typography
                ml='84px'
                  sx={{width: '100%', fontSize: "16px", fontWeight: "bold", color: "#2b5b5b" }}
                >
                  Assessment {exam.id === 1 ? 'Only' : ''}
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="row"
              >
                <Typography>Qty:</Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      color: "white",
                      background: "#2b5b5b",
                      "&:hover": {
                        background: "#2b5b5b",
                      },
                    }}
                    onClick={() => setCount(count > 1 ? count - 1 : 1)}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography variant="body2">{count}</Typography>
                  </Box>
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      color: "white",
                      background: "#2b5b5b",
                      "&:hover": {
                        background: "#2b5b5b",
                      },
                    }}
                    onClick={() => setCount(count + 1)}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
              <Button
              onClick={() => {
                hasPermission()
                if(!hasRoleCreatePermission){
                setisDebriefing(false)
                specifiedPackegeFun(exam.id);
                setdebriefing(false)
                handleOpenEditDialog();
                }
                }}
              variant="contained"
              sx={{
                color: "white",
                width: "250px",
                height: '50%',
                margin: "20px auto",
                background: "linear-gradient(45deg, #77c853, #347461)",
              }}
            >
              Buy Now
            </Button>
            </Stack>
  
            {/* Assessment + Debriefing Section */}
            {exam.exam_id === 1 && 
            <Stack
              spacing={3}
              m={2}
              direction="row"
              width={500}
              alignItems='center'
              justifyContent="space-between"
            >
              <Stack  width='100%' justifyContent="center" alignItems="center">
              <Typography sx={{ fontSize: "16px", fontWeight: "bold", color: "#2b5b5b" }}>
                {(() => {
                  const price = (FinalPrice ? FinalPrice?.price : exam.price) * 3.8 ;
                
                  // If price is less than 15, round to 10
                  if (price < 15) {
                    return 10;
                  }
                
                  // Otherwise, apply dynamic rounding logic
                  const baseMultiple = Math.floor(price / 10) * 10;
                  const remainder = price % 10;
                  if (remainder <= 5) {
                    return baseMultiple + 5;
                  } else {
                    return baseMultiple + 10;
                  }
                })()}
                $
              </Typography>
                <Typography
                ml='12px'
                textAlign='center'
                  sx={{width:'128%', fontSize: "16px", fontWeight: "bold", color: "#2b5b5b" }}
                >
                  Assessment<br/> + Debriefing               
                  <Box display='inline' sx={{ cursor: 'pointer',width:'10px', color: '#2b6565'}} onClick={toggleText} variant="text">
                <HelpOutlineIcon />
              </Box>
                </Typography>
              </Stack>
              <Stack
                alignItems="center"
                justifyContent="center"
                spacing={2}
                direction="row"
              >
                <Typography>Qty:</Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  spacing={1}
                >
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      color: "white",
                      background: "#2b5b5b",
                      "&:hover": {
                        background: "#2b5b5b",
                      },
                    }}
                    onClick={() => setCount1(count1 > 1 ? count1 - 1 : 1)}
                  >
                    <RemoveIcon fontSize="small" />
                  </IconButton>
                  <Box
                    sx={{
                      width: 30,
                      height: 30,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: "1px solid #ccc",
                      borderRadius: "4px",
                    }}
                  >
                    <Typography variant="body2">{count1}</Typography>
                  </Box>
                  <IconButton
                    size="small"
                    sx={{
                      borderRadius: "10px",
                      color: "white",
                      background: "#2b5b5b",
                      "&:hover": {
                        background: "#2b5b5b",
                      },
                    }}
                    onClick={() => setCount1(count1 + 1)}
                  >
                    <AddIcon fontSize="small" />
                  </IconButton>
                </Stack>
              </Stack>
              <Button
              onClick={() => {
                hasPermission()
                if(!hasRoleCreatePermission){
                setisDebriefing(true)
                specifiedPackegeFun(exam.id);
                setdebriefing(true)
                handleOpenEditDialog();
                }
               }}
              variant="contained"
              sx={{
                color: "white",
                width: "250px",
                height: '24px',
                margin: "20px auto",
                background: "linear-gradient(45deg, #77c853, #347461)",
                "&:disabled": {
                  background: "#ccc",
                },
              }}
            >
              Buy Now
            </Button>
            </Stack>
            }
            {showText && (
                <Box mb={3}>
                  The “Twindix Executives” Report’s Debriefing Session is a
                  structured consultation conducted by one of our certified
                  experts. This session provides an in-depth analysis and
                  comprehensive explanation of the report, ensuring a clear
                  understanding of its findings. The objective is to maximize the
                  value of the report by enabling informed decision-making and
                  actionable insights, empowering executives to excel and adopt
                  best practices in management and organizational excellence.
                </Box>
              )}
          </Stack>
<Snackbar
  open={open}
  autoHideDuration={6000}
  onClose={handleClose}
  message="Failed to check Coupon"
/>
<Snackbar
  open={open1}
  autoHideDuration={6000}
  onClose={handleClose1}
  message="Failed to payment"
/>
        </Stack>
      ))}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <Box sx={{ bgcolor: '#929292', width: '450px' }}>
          <DialogContent sx={{ maxHeight: '500px', overflowY: 'auto' }}>
          <Stack>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}>Assessment Name: {specifiedPackege?.name}</Typography>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}>Assessment description: {specifiedPackege?.description?.slice(0, 48)}...</Typography>
            <Typography sx={{ fontSize: '16px', margin: '6px', color: 'white' }}>
            Total Price:{
  isDebriefing
    ? (() => {
        const basePrice = FinalPrice?.price || specifiedPackege.price;
        const multiplier = isDebriefing ? count1 : count;
        const price = basePrice * 3.8 * multiplier;

        // Logic for rounding the price
        if (price < 15) {
          return 10; // Round to 10 if less than 15
        }

        const baseMultiple = Math.floor(price / 10) * 10;
        const remainder = price % 10;

        return remainder <= 5 ? baseMultiple + 5 : baseMultiple + 10;
      })()
    :  FinalPrice ? FinalPrice.price * count : specifiedPackege.price * count
}
 $
            </Typography>
            <Typography sx={{fontSize : '16px', margin: '6px', color : 'white'}}>Type: {isDebriefing? 'With Debriefing' : 'With No Debriefing'}</Typography>
          </Stack>
            <Grid container direction="column" spacing={4}>
              <Grid item xs={12}>
              <TextField
                label="Enter Coupon"
                variant="outlined"
                size="small"
                value={coupon[specifiedPackege.id] || ''}
                onChange={(e) => handleChange(e, specifiedPackege.id)}
                sx={{ flex: 1 }}
                name={`input-${specifiedPackege.id}`} 
                id={`input-${specifiedPackege.id}`} 
              />
              <Button
                variant="contained"
                
                onClick={() => checkCoupon(specifiedPackege.id)}
                sx={{fontSize: '12px', backgroundColor: "#4ca88f", color: "white", ml: 2 }}
              >
                Check Coupon
              </Button>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
              <Stack justifyContent='center' alignItems='center' sx={{width: '100%'}}>
                <Button
                  variant="contained"
                  disabled={coupon[specifiedPackege.id] ? !isCouponValid : false}
                  onClick={() =>  buyExam(specifiedPackege.id)}
                  sx={{width : '200px', fontSize: '12px',background: "linear-gradient(45deg, #2c6264, #5ac4cb)", color: "white"}}
                >
                  Subscribe Now
                </Button>
              </Stack>
          </DialogActions>
        </Box>
      </Dialog>
      </>
    )
  }
