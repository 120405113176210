
 export  const useManCode2 = ({ referencess = [], answers = [], benchmark = [] }) => {

const boxes3 = [
    {
      benchmark: benchmark[15],
      answerKey: [14161, 14162, 14163],
    },
    {
      benchmark: benchmark[16],
      answerKey: [14171, 14172, 14173],
    },
    {
      benchmark: benchmark[17],
      answerKey: [14181, 14182, 14183],
    },
    {
      benchmark: benchmark[18],
      answerKey: [14191, 14192, 14193],
    },
    {
      benchmark: benchmark[19],
      answerKey: [14201, 14202, 14203],
    },
  ];

  const mappedBoxes3 = boxes3.map((box, index) => {
    const answerSum = box.answerKey.reduce(
      (sum, key) => sum + Math.trunc(Number(answers?.[key])),
      0
    );
  
    return {
      ...box,
      display: answerSum >= 2 ? 'block' : 'none',
      backgroundColor: answerSum >= 2 ? '#25225b' : 'transparent',
      index: index,
      visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
      backgroundColorBench: box.benchmark === 1 ? '#25225b' : 'transparent'
    };
  });
  
  // Separate boxes into two arrays based on backgroundColor
  const boxesWithBackground3 = mappedBoxes3.filter((box) => box.backgroundColor === '#25225b');
  const boxesWithTransparentBg3 = mappedBoxes3.filter((box) => box.backgroundColor === 'transparent');
  
  // Separate boxes into two arrays based on backgroundColorBench
  const boxesWithBackgroundbench3 = mappedBoxes3.filter((box) => box.backgroundColorBench === '#25225b');
  const boxesWithTransparentBgbench3 = mappedBoxes3.filter((box) => box.backgroundColorBench === 'transparent');
  
  // Concatenate arrays to prioritize boxes with background color
  const sortedBoxes3 = [...boxesWithBackground3, ...boxesWithTransparentBg3];
  const sortedBoxes3bench = [...boxesWithBackgroundbench3, ...boxesWithTransparentBgbench3];
  

const boxes4 = [
    {
      benchmark: benchmark[20],
      answerKey: [25211, 25212, 25213],
    },
    {
      benchmark: benchmark[21],
      answerKey: [25221, 25222, 25223],
    },
    {
      benchmark: benchmark[22],
      answerKey: [25232, 25233, 25234],
    },
    {
      benchmark: benchmark[23],
      answerKey: [25241, 25242, 25243],
    },
    {
      benchmark: benchmark[24],
      answerKey: [25251, 25252, 25253],
    },
  ];

  const mappedBoxes4 = boxes4.map((box, index) => {
    const answerSum = box.answerKey.reduce(
      (sum, key) => sum + Math.trunc(Number(answers?.[key])),
      0
    );
  
    return {
      ...box,
      display: answerSum >= 2 ? 'block' : 'none',
      backgroundColor: answerSum >= 2 ? '#f7e560' : 'transparent',
      index: index,
      visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
      backgroundColorBench: box.benchmark === 1 ? '#f7e560' : 'transparent'
    };
  });
  
  // Separate boxes into two arrays based on backgroundColor
  const boxesWithBackground4 = mappedBoxes4.filter((box) => box.backgroundColor === '#f7e560');
  const boxesWithTransparentBg4 = mappedBoxes4.filter((box) => box.backgroundColor === 'transparent');
  
  // Separate boxes into two arrays based on backgroundColorBench
  const boxesWithBackgroundbench4 = mappedBoxes4.filter((box) => box.backgroundColorBench === '#f7e560');
  const boxesWithTransparentBgbench4 = mappedBoxes4.filter((box) => box.backgroundColorBench === 'transparent');
  
  // Concatenate arrays to prioritize boxes with background color
  const sortedBoxes4 = [...boxesWithBackground4, ...boxesWithTransparentBg4];
  const sortedBoxes4bench = [...boxesWithBackgroundbench4, ...boxesWithTransparentBgbench4];
  
const boxes5 = [
    {
      benchmark: benchmark[25],
      answerKey: [26261, 26262, 26263],
    },
    {
      benchmark: benchmark[26],
      answerKey: [26271, 26272, 26273],
    },
    {
      benchmark: benchmark[27],
      answerKey: [26281, 26282, 26283],
    },
    {
      benchmark: benchmark[28],
      answerKey: [26291, 26292, 26293],
    },
    {
      benchmark: benchmark[29],
      answerKey: [26301, 26302, 26303],
    },
  ];

  const mappedBoxes5 = boxes5.map((box, index) => {
    const answerSum = box.answerKey.reduce(
      (sum, key) => sum + Math.trunc(Number(answers?.[key])),
      0
    );
  
    return {
      ...box,
      display: answerSum >= 2 ? 'block' : 'none',
      backgroundColor: answerSum >= 2 ? '#f7e560' : 'transparent',
      index: index,
      visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
      backgroundColorBench: box.benchmark === 1 ? '#f7e560' : 'transparent'
    };
  });
  
  // Separate boxes into two arrays based on backgroundColor
  const boxesWithBackground5 = mappedBoxes5.filter((box) => box.backgroundColor === '#f7e560');
  const boxesWithTransparentBg5 = mappedBoxes5.filter((box) => box.backgroundColor === 'transparent');
  
  // Separate boxes into two arrays based on backgroundColorBench
  const boxesWithBackgroundbench5 = mappedBoxes5.filter((box) => box.backgroundColorBench === '#f7e560');
  const boxesWithTransparentBgbench5 = mappedBoxes5.filter((box) => box.backgroundColorBench === 'transparent');
  
  // Concatenate arrays to prioritize boxes with background color
  const sortedBoxes5 = [...boxesWithBackground5, ...boxesWithTransparentBg5];
  const sortedBoxes5bench = [...boxesWithBackgroundbench5, ...boxesWithTransparentBgbench5];
  

const boxes6 = [
    {
      benchmark: benchmark[30],
      answerKey: [27311, 27312, 27313],
    },
    {
      benchmark: benchmark[31],
      answerKey: [27321, 27322, 27323],
    },
    {
      benchmark: benchmark[32],
      answerKey: [27331, 27332, 27333],
    },
    {
      benchmark: benchmark[33],
      answerKey: [27341, 27342, 27343],
    },
    {
      benchmark: benchmark[34],
      answerKey: [27351, 27352, 27353],
    },
];

const mappedBoxes6 = boxes6.map((box, index) => {
  const answerSum = box.answerKey.reduce(
    (sum, key) => sum + Math.trunc(Number(answers?.[key])),
    0
  );

  return {
    ...box,
    display: answerSum >= 2 ? 'block' : 'none',
    backgroundColor: answerSum >= 2 ? '#f7e560' : 'transparent',
    index: index,
    visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
    backgroundColorBench: box.benchmark === 1 ? '#f7e560' : 'transparent'
  };
});

// Separate boxes into two arrays based on backgroundColor
const boxesWithBackground6 = mappedBoxes6.filter((box) => box.backgroundColor === '#f7e560');
const boxesWithTransparentBg6 = mappedBoxes6.filter((box) => box.backgroundColor === 'transparent');

// Separate boxes into two arrays based on backgroundColorBench
const boxesWithBackgroundbench6 = mappedBoxes6.filter((box) => box.backgroundColorBench === '#f7e560');
const boxesWithTransparentBgbench6 = mappedBoxes6.filter((box) => box.backgroundColorBench === 'transparent');

// Concatenate arrays to prioritize boxes with background color
const sortedBoxes6 = [...boxesWithBackground6, ...boxesWithTransparentBg6];
const sortedBoxes6bench = [...boxesWithBackgroundbench6, ...boxesWithTransparentBgbench6];


const boxes7 = [
    {
      benchmark: benchmark[35],
      answerKey: [28361, 28362, 28363],
    },
    {
      benchmark: benchmark[36],
      answerKey: [28371, 28372, 28373],
    },
    {
      benchmark: benchmark[37],
      answerKey: [28381, 28382, 28383],
    },
    {
      benchmark: benchmark[38],
      answerKey: [28391, 28392, 28393],
    },
    {
      benchmark: benchmark[39],
      answerKey: [28401, 28402, 28403],
    },
];

const mappedBoxes7 = boxes7.map((box, index) => {
  const answerSum = box.answerKey.reduce(
    (sum, key) => sum + Math.trunc(Number(answers?.[key])),
    0
  );

  return {
    ...box,
    display: answerSum >= 2 ? 'block' : 'none',
    backgroundColor: answerSum >= 2 ? '#f7e560' : 'transparent',
    index: index,
    visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
    backgroundColorBench: box.benchmark === 1 ? '#f7e560' : 'transparent'
  };
});

// Separate boxes into two arrays based on backgroundColor
const boxesWithBackground7 = mappedBoxes7.filter((box) => box.backgroundColor === '#f7e560');
const boxesWithTransparentBg7 = mappedBoxes7.filter((box) => box.backgroundColor === 'transparent');

// Separate boxes into two arrays based on backgroundColorBench
const boxesWithBackgroundbench7 = mappedBoxes7.filter((box) => box.backgroundColorBench === '#f7e560');
const boxesWithTransparentBgbench7 = mappedBoxes7.filter((box) => box.backgroundColorBench === 'transparent');

// Concatenate arrays to prioritize boxes with background color
const sortedBoxes7 = [...boxesWithBackground7, ...boxesWithTransparentBg7];
const sortedBoxes7bench = [...boxesWithBackgroundbench7, ...boxesWithTransparentBgbench7];

const boxes8 = [
    {
      benchmark: benchmark[40],
      answerKey: [39411, 39412, 39413],
    },
    {
      benchmark: benchmark[41],
      answerKey: [39421, 39422, 39423],
    },
    {
      benchmark: benchmark[42],
      answerKey: [39431, 39432, 39433],
    },
    {
      benchmark: benchmark[43],
      answerKey: [39441, 39442, 39443],
    },
    {
      benchmark: benchmark[44],
      answerKey: [39451, 39452, 39453],
    },
];

const mappedBoxes8 = boxes8.map((box, index) => {
  const answerSum = box.answerKey.reduce(
    (sum, key) => sum + Math.trunc(Number(answers?.[key])),
    0
  );

  return {
    ...box,
    display: answerSum >= 2 ? 'block' : 'none',
    backgroundColor: answerSum >= 2 ? '#e33f4c' : 'transparent',
    index: index,
    visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
    backgroundColorBench: box.benchmark === 1 ? '#e33f4c' : 'transparent'
  };
});

// Separate boxes into two arrays based on backgroundColor
const boxesWithBackground8 = mappedBoxes8.filter((box) => box.backgroundColor === '#e33f4c');
const boxesWithTransparentBg8 = mappedBoxes8.filter((box) => box.backgroundColor === 'transparent');

// Separate boxes into two arrays based on backgroundColorBench
const boxesWithBackgroundbench8 = mappedBoxes8.filter((box) => box.backgroundColorBench === '#e33f4c');
const boxesWithTransparentBgbench8 = mappedBoxes8.filter((box) => box.backgroundColorBench === 'transparent');

// Concatenate arrays to prioritize boxes with background color
const sortedBoxes8 = [...boxesWithBackground8, ...boxesWithTransparentBg8];
const sortedBoxes8bench = [...boxesWithBackgroundbench8, ...boxesWithTransparentBgbench8];

    const boxes9 = [
        {
          benchmark: benchmark[45],
          answerKey: [310461, 310462, 310463],
        },
        {
          benchmark: benchmark[46],
          answerKey: [310471, 310472, 310473],
        },
        {
          benchmark: benchmark[47],
          answerKey: [310481, 310482, 310483],
        },
        {
          benchmark: benchmark[48],
          answerKey: [310491, 310492, 310493],
        },
        {
          benchmark: benchmark[49],
          answerKey: [310501, 310502, 310503],
        },
    ];
    
    const mappedBoxes9 = boxes9.map((box, index) => {
      const answerSum = box.answerKey.reduce(
        (sum, key) => sum + Math.trunc(Number(answers?.[key])),
        0
      );
    
      return {
        ...box,
        display: answerSum >= 2 ? 'block' : 'none',
        backgroundColor: answerSum >= 2 ? '#e33f4c' : 'transparent',
        index: index,
        visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
        backgroundColorBench: box.benchmark === 1 ? '#e33f4c' : 'transparent'
      };
    });
    
    // Separate boxes into two arrays based on backgroundColor
    const boxesWithBackground9 = mappedBoxes9.filter((box) => box.backgroundColor === '#e33f4c');
    const boxesWithTransparentBg9 = mappedBoxes9.filter((box) => box.backgroundColor === 'transparent');
    
    // Separate boxes into two arrays based on backgroundColorBench
    const boxesWithBackgroundbench9 = mappedBoxes9.filter((box) => box.backgroundColorBench === '#e33f4c');
    const boxesWithTransparentBgbench9 = mappedBoxes9.filter((box) => box.backgroundColorBench === 'transparent');
    
    // Concatenate arrays to prioritize boxes with background color
    const sortedBoxes9 = [...boxesWithBackground9, ...boxesWithTransparentBg9];
    const sortedBoxes9bench = [...boxesWithBackgroundbench9, ...boxesWithTransparentBgbench9];
    
const boxes10 = [
    {
      benchmark: benchmark[50],
      answerKey: [311511, 311512, 311513],
    },
    {
      benchmark: benchmark[51],
      answerKey: [311521, 311522, 311523],
    },
    {
      benchmark: benchmark[52],
      answerKey: [311531, 311532, 311533],
    },
    {
      benchmark: benchmark[53],
      answerKey: [311541, 311542, 311543],
    },
    {
      benchmark: benchmark[54],
      answerKey: [311551, 311552, 311553],
    },
];

const mappedBoxes10 = boxes10.map((box, index) => {
  const answerSum = box.answerKey.reduce(
    (sum, key) => sum + Math.trunc(Number(answers?.[key])),
    0
  );

  return {
    ...box,
    display: answerSum >= 2 ? 'block' : 'none',
    backgroundColor: answerSum >= 2 ? '#e33f4c' : 'transparent',
    index: index,
    visibilityBench: box.benchmark === 1 ? 'visible' : 'hidden',
    backgroundColorBench: box.benchmark === 1 ? '#e33f4c' : 'transparent'
  };
});

// Separate boxes into two arrays for backgroundColor
const boxesWithBackground10 = mappedBoxes10.filter((box) => box.backgroundColor === '#e33f4c');
const boxesWithTransparentBg10 = mappedBoxes10.filter((box) => box.backgroundColor === 'transparent');

// Separate boxes into two arrays for backgroundColorBench
const boxesWithBackgroundbench10 = mappedBoxes10.filter((box) => box.backgroundColorBench === '#e33f4c');
const boxesWithTransparentBgbench10 = mappedBoxes10.filter((box) => box.backgroundColorBench === 'transparent');

// Concatenate the arrays to have all background colored boxes on the left and transparent ones on the right
const sortedBoxes10 = [...boxesWithBackground10, ...boxesWithTransparentBg10];
const sortedBoxes10bench = [...boxesWithBackgroundbench10, ...boxesWithTransparentBgbench10];

    return {
        sortedBoxes3,
        sortedBoxes4,
        sortedBoxes5,
        sortedBoxes6,
        sortedBoxes7,
        sortedBoxes8,
        sortedBoxes9,
        sortedBoxes10,
        sortedBoxes3bench,
        sortedBoxes4bench,
        sortedBoxes5bench,
        sortedBoxes6bench,
        sortedBoxes7bench,
        sortedBoxes8bench,
        sortedBoxes9bench,
        sortedBoxes10bench,
    }

}
