import { Button, Container } from "@mui/material"
import Individual from "./Individual"
import TemporaryDrawerInd from "./DrawerInd"
import React, { useContext, useState } from "react";
import HR from "./HR";
import { appContext } from "../../App";
import TemporaryDrawerAdmin from "./DrawerAdmin";
import TemporaryDrawerHR from "./DrawerHR";
import Cart from "./Cart";
import Admin from "./Admin";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
function Dashboard_v2 () {
  const isLoggedIn = useContext(appContext);

    const [isHome, setisHome] = useState(false)
    const [isAssessment, setisAssessment] = useState(false)
    const [isBuyAssessment, setisBuyAssessment] = useState(false)
    const [open, setOpen] = React.useState(false);
    const [toggleCart, settoggleCart] = React.useState(false);
    
    const [isDashboardHR, setisDashboardHR] = React.useState(false);
    const [isTwindixIndivdual, setisTwindixIndivdual] = React.useState(false);
    const [isJopBar, setisJopBar] = React.useState(false);
    const [isBenchmarksShelve, setisBenchmarksShelve] = React.useState(false);
    const [isTeamAccess, setisTeamAccess] = React.useState(false);
    const [isMangeTwindixAssessments, setisMangeTwindixAssessments] = React.useState(false);
    const [isMangeJopBarAssessments, setisMangeJopBarAssessments] = React.useState(false);
    const [isSubscriptions, setisSubscriptions] = React.useState(false);
    const [isHetInTouch, setisHetInTouch] = React.useState(false);
    const [isMyPackages, setisMyPackages] = React.useState(false);
    const [Roles, setRoles] = React.useState(false);
    const [Company, setCompany] = React.useState(false);
    const [Employee, setEmployee] = React.useState(false);
    const [MyEnrollments, setMyEnrollments] = React.useState(false);


    const [isHomeAdmin, setisHomeAdmin] = React.useState(false);
    const [isEnrollments, setisEnrollments] = React.useState(false);
    const [isUsers, setisUsers] = React.useState(false);
    const [isPackages, setisPackages] = React.useState(false);
    const [isExams, setisExams] = React.useState(false);
    const [isTemplates, setisTemplates] = React.useState(false);
    const [isBenchmark, setisBenchmark] = React.useState(false);
    const [isAdmins, setisAdmins] = React.useState(false);
    const [isCoupons,  setisCoupons] = React.useState(false);
    const [AdminRoles,  setAdminRoles] = React.useState(false);
    
    const [showBackBtn,  setshowBackBtn] = React.useState(true);

    const [history, setHistory] = useState([])
    const addToArray = (value) => {
        setHistory((prevArray) => [...prevArray, value]);
      };
    
    const toggleDrawer = (isOpen) => () => {
        setOpen(isOpen);
    };
    const toggleCartFun = (isOpen) => () => {
        settoggleCart(isOpen);
    };


        const resetHrDashboard = () => {
            setisDashboardHR(false)
            setisTwindixIndivdual(false)
            setisJopBar(false)
            setisBenchmarksShelve(false)
            setisTeamAccess(false)
            setisMangeTwindixAssessments(false)
            setisMangeJopBarAssessments(false)
            setisSubscriptions(false)
            setisHetInTouch(false)
            setisMyPackages(false)
            setRoles(false)
            setCompany(false)
            setEmployee(false)
            setMyEnrollments(false)
        }
        const resetAdminDashboard = () => {
            setisHomeAdmin(false)
            setisEnrollments(false)
            setisUsers(false)
            setisPackages(false)
            setisExams(false)
            setisTemplates(false)
            setisBenchmark(false)
            setisAdmins(false)
            setisCoupons(false)
            setAdminRoles(false)
            
        }
        const resetIndDashboard = () => {
            setisBuyAssessment(false)
            setisHome(false)
            setisAssessment(false)
        }
        const resetToLastValue = () => {
            setHistory((prevArray) => {
              if (prevArray.length > 0) {
                // Get the second-to-last value (or last if there's only one)
                const lastValue = prevArray[prevArray.length - 2];
                
                
                resetHrDashboard();
                resetAdminDashboard()
                resetIndDashboard()
                switch (lastValue) {
                    case 'isDashboardHR':
                      setisDashboardHR(true);
                      break;
                    case 'isTwindixIndivdual':
                      setisTwindixIndivdual(true);
                      break;
                    case 'isJopBar':
                      setisJopBar(true);
                      break;
                    case 'isBenchmarksShelve':
                      setisBenchmarksShelve(true);
                      break;
                    case 'isTeamAccess':
                      setisTeamAccess(true);
                      break;
                    case 'isMangeTwindixAssessments':
                      setisMangeTwindixAssessments(true);
                      break;
                    case 'isMangeJopBarAssessments':
                      setisMangeJopBarAssessments(true);
                      break;
                    case 'isSubscriptions':
                      setisSubscriptions(true);
                      break;
                    case 'isHetInTouch':
                      setisHetInTouch(true);
                      break;
                    case 'isMyPackages':
                      setisMyPackages(true);
                      break;
                    case 'Roles':
                      setRoles(true);
                      break;
                    case 'Company':
                      setCompany(true);
                      break;
                    case 'Employee':
                      setEmployee(true);
                      break;
                    case 'isHome':
                      setisHome(true);
                      break;
                    case 'isAssessment':
                      setisAssessment(true);
                      break;
                    case 'isBuyAssessment':
                      setisBuyAssessment(true);
                      break;
                    case 'open':
                      setOpen(true);
                      break;
                    case 'toggleCart':
                      settoggleCart(true);
                      break;
                    case 'isHomeAdmin':
                      setisHomeAdmin(true);
                      break;
                    case 'isEnrollments':
                      setisEnrollments(true);
                      break;
                    case 'isUsers':
                      setisUsers(true);
                      break;
                    case 'isPackages':
                      setisPackages(true);
                      break;
                    case 'isExams':
                      setisExams(true);
                      break;
                    case 'isTemplates':
                      setisTemplates(true);
                      break;
                    case 'isBenchmark':
                      setisBenchmark(true);
                      break;
                    case 'isAdmins':
                      setisAdmins(true);
                      break;
                    case 'isCoupons':
                      setisCoupons(true);
                      break;
                    default:
                      console.log("Unknown state name");
                  }                  return prevArray.slice(0, -1);
              }
              return prevArray;
            });
          };
          const subscriptionFun = () => {
            console.log('ss');
            resetHrDashboard();
            setisSubscriptions(true);
            addToArray('isSubscriptions');
          };

        const drawerComponents = {
            1: <TemporaryDrawerHR 
            setisDashboard={() => {
                resetHrDashboard()
                setisDashboardHR(true)
                addToArray('isDashboardHR')
            }}
            setisTwindixIndivdual={() => {
                resetHrDashboard()
                setisTwindixIndivdual(true)
                addToArray('isTwindixIndivdual')
            }}
            setisJopBar={() => {
                resetHrDashboard()
                setisJopBar(true)
                addToArray(isJopBar)
            }}
            setisBenchmarksShelve={() => {
                resetHrDashboard()
                setisBenchmarksShelve(true)
                addToArray('isBenchmarksShelve')
            }}
            setisTeamAccess={() => {
                resetHrDashboard()
                setisTeamAccess(true)
                addToArray('isTeamAccess')
            }}
            setisMangeTwindixAssessments={() => {
                resetHrDashboard()
                setisMangeTwindixAssessments(true)
                addToArray('isMangeTwindixAssessments')
            }}
            setisMangeJopBarAssessments={() => {
                resetHrDashboard()
                setisMangeJopBarAssessments(true)
                addToArray('isMangeJopBarAssessments')
            }}
            setisSubscriptions={() => {
                resetHrDashboard()
                setisSubscriptions(true)
                addToArray('isSubscriptions')
            }}
            setisMyPackages={() => {
                resetHrDashboard()
                setisMyPackages(true)
                addToArray('isMyPackages')
            }}
            setisHetInTouch={() => {
                resetHrDashboard()
                setisHetInTouch(true)
                addToArray('isHetInTouch')
            }}
            setRoles={() => {
                resetHrDashboard()
                setRoles(true)
                addToArray('Roles')
            }}
            setCompany={() => {
                resetHrDashboard()
                setCompany(true)
                addToArray('Company')
            }}
            setEmployee={() => {
                resetHrDashboard()
                setEmployee(true)
                addToArray('Employee')
            }}
            setMyEnrollments={() => {
                resetHrDashboard()
                setMyEnrollments(true)
                addToArray('MyEnrollments')
            }}
            open={open} toggleDrawer={toggleDrawer} />,
            2: <TemporaryDrawerInd 
            setisBuyAssessment={() => {
                resetIndDashboard()
                setisBuyAssessment(true)
                addToArray('isBuyAssessment')
            }} setisHome={() =>{
                resetIndDashboard()
                setisHome(true)
                addToArray('isHome')
            } } setisAssessment={() => {
                resetIndDashboard()
                setisAssessment(true)
                addToArray('isAssessment')
            }} open={open} toggleDrawer={toggleDrawer} />,
            3: <TemporaryDrawerAdmin
            setisHomeAdmin={() => {
                resetAdminDashboard()
                setisHomeAdmin(true)
                addToArray('isHomeAdmin')
            }}
            setisEnrollments={() => {
                resetAdminDashboard()
                setisEnrollments(true)
                addToArray('isEnrollments')
            }}
            setisUsers={() => {
                resetAdminDashboard()
                setisUsers(true)
                addToArray('isUsers')
            }}
            setisPackages={() => {
                resetAdminDashboard()
                setisPackages(true)
                addToArray('isPackages')
            }}
            setisExams={() => {
                resetAdminDashboard()
                setisExams(true)
                addToArray('isExams')
            }}
            setisTemplates={() => {
                resetAdminDashboard()
                setisTemplates(true)
                addToArray('isTemplates')
            }}
            setisBenchmark={() => {
                resetAdminDashboard()
                setisBenchmark(true)
                addToArray('isBenchmark')
            }}
            setisAdmins={() => {
                resetAdminDashboard()
                setisAdmins(true)
                addToArray('isAdmins')
            }}
            setisCoupons={() => {
                resetAdminDashboard()
                setisCoupons(true)
                addToArray('isCoupons')
            }}
            setAdminRoles={() => {
                resetAdminDashboard()
                setAdminRoles(true)
                addToArray('AdminRoles')
            }}
            open={open} toggleDrawer={toggleDrawer} />,
        };
        return (
        <>

        {isLoggedIn?.userType === 1 ?
            <HR 
            toggleDrawer={toggleDrawer(true)}  
            toggleCart={toggleCartFun(true)} 
            isDashboardHR={isDashboardHR}
            isTwindixIndivdual={isTwindixIndivdual}
            isJopBar={isJopBar}
            isBenchmarksShelve={isBenchmarksShelve}
            isTeamAccess={isTeamAccess}
            isMangeTwindixAssessments={isMangeTwindixAssessments}
            isMangeJopBarAssessments={isMangeJopBarAssessments}
            isSubscriptions={isSubscriptions}
            isHetInTouch={isHetInTouch}
            isMyPackages={isMyPackages}
            Roles={Roles}
            Company={Company}
            Employee={Employee}
            MyEnrollments={MyEnrollments}
            setshowBackBtn={setshowBackBtn}
            BackToLastPage={() => (
                        showBackBtn && (
                          <Button onClick={resetToLastValue}>
                            <ArrowBackIcon />Back
                          </Button>
                        )
                      )}
                      setSubscriptions={subscriptionFun}
            />
        : isLoggedIn?.userType === 2 ?
            <Individual toggleCart={toggleCartFun(true)} 
                        toggleDrawer={toggleDrawer(true)}   
                        isBuyAssessment={isBuyAssessment} 
                        isHome={isHome} 
                        isAssessment={isAssessment} 
                        setshowBackBtn={setshowBackBtn}
                        BackToLastPage={() => (
                        showBackBtn && (
                          <Button onClick={resetToLastValue}>
                            <ArrowBackIcon />Back
                          </Button>
                        )
                      )}
                        />
        : isLoggedIn?.localStorage?.isAdmin === 'true' ?
            <Admin toggleCart={toggleCartFun(true)} 
                    toggleDrawer={toggleDrawer(true)} 
                    isHomeAdmin={isHomeAdmin}
                    isEnrollments={isEnrollments}
                    isUsers={isUsers}
                    isPackages={isPackages}
                    isExams={isExams}
                    isTemplates={isTemplates} 
                    isBenchmark={isBenchmark} 
                    isAdmins={isAdmins}
                    isCoupons={isCoupons}
                    AdminRoles={AdminRoles}
                    BackToLastPage={() => (
              <Button onClick={resetToLastValue}>
                <ArrowBackIcon />Back
              </Button>
            )}
          /> : null
        }
        {isLoggedIn.userType == 1 ? 
            drawerComponents[1]
        : isLoggedIn.userType == 2 ?
            drawerComponents[2]
        :
            drawerComponents[3]
           }
        <Cart open={toggleCart} toggleCart={toggleCartFun}  />
        </>
    )
}

export default Dashboard_v2