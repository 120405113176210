import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, Grid, Snackbar, Typography } from "@mui/material";
import axios from "axios";

export default function AddPositionForm({open, setOpen, companyId, refetchData }) {
  // States for form fields
  const [positionName, setPositionName] = useState("");

  // Close dialog
  const handleClose = () => {
    setOpen(false);
  };


  // Handle "Add Position" submission
  const handleAddPosition = () => {
    const payload = {
      title: positionName,
    };

    axios
      .post(`https://api.twindix.com/companies/${companyId}/positions`, payload, {
        headers: { Authorization: "Bearer " + localStorage.token },
      })
      .then((response) => {
        refetchData()
        setOpen(false)
        setPositionName('')
        handleClose()
      })
      .catch((error) => {
        console.error("Error adding position:", error);
      });
  };



  const [openSnackbar, setOpenSnackbar] = React.useState(false);

  const handleClick = () => {
    setOpenSnackbar(true);
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackbar(false);
  };

  return (
    <React.Fragment>
      <Dialog sx={{zIndex: '2'}} maxWidth="md" open={open} onClose={handleClose}>
        <Box
          sx={{
            bgcolor: "#929292",
            width: "450px",
          }}
        >
          <DialogTitle sx={{ color: "white" }}>Add New Position</DialogTitle>
          <DialogContent
            sx={{
              maxHeight: "500px",
              overflowY: "auto",
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-thumb": {
                borderRadius: "10px",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
              },
              "&::-webkit-scrollbar-track": { background: "#929292", borderRadius: "10px" },
            }}
          >
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Typography sx={{ fontSize: "18px", color: "white", mb: "10px" }}>Position Name:</Typography>
                <input
                  value={positionName}
                  onChange={(e) => setPositionName(e.target.value)}
                  placeholder="Enter position name"
                  style={{
                    padding: "10px",
                    width: "100%",
                    borderRadius: "4px",
                    border: "0",
                    height: "40px",
                  }}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                borderRadius: "10px",
                margin: "auto",
              }}
              variant="contained"
              onClick={handleAddPosition}
            >
              Add Position
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Cannot add another position with the same name."
      />
    </React.Fragment>
  );
}
