import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Box, Grid2, TextField, Typography, Autocomplete } from '@mui/material';
import axios from 'axios';
import ApiContext from '../../../Context/context';

export default function AddTeamForm({refrech, setrefrech, open, setOpen }) {
  const [selectedRole, setSelectedRole] = React.useState(null); // For storing selected role
  const [name, setName] = React.useState('');
  const [email, setEmail] = React.useState('');
  const {
    roles
  } = React.useContext(ApiContext);
  // Handle form submission
  const handleSubmit = async () => {
    const payload = {
      name,
      email,
      role_id: selectedRole ? selectedRole.id : null, 
    };

    try {
      const response = await fetch('https://api.twindix.com/editors', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.token,
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Error: ' + response.statusText);
      }

      const data = await response.json();
      setrefrech()
      console.log('Response:', data);
      setOpen(false); // Close the dialog on successful submission
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Dialog maxWidth="md" open={open} onClose={handleClose}>
        <Box sx={{ bgcolor: '#929292', width: '450px' }}>
          <DialogContent
            sx={{
              maxHeight: '500px',
              overflowY: 'auto',
              '&::-webkit-scrollbar': {
                width: '6px',
              },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: '10px',
                background: 'linear-gradient(45deg, #2c6264, #5ac4cb)',
              },
              '&::-webkit-scrollbar-track': {
                background: '#929292',
                borderRadius: '10px',
              },
            }}
          >
            <Grid2 container direction="column" spacing={4}>
              <Grid2 item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Name:
                </Typography>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  placeholder="Insert name"
                  style={{
                    padding: '10px',
                    fontSize: '14px',
                    border: '0',
                    borderRadius: '4px',
                    width: '100%',
                    height: '40px',
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Email:
                </Typography>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Insert email"
                  style={{
                    padding: '10px',
                    fontSize: '14px',
                    border: '0',
                    borderRadius: '4px',
                    width: '100%',
                    height: '40px',
                  }}
                />
              </Grid2>
              <Grid2 item xs={12}>
                <Typography sx={{ fontSize: '22px', textDecoration: 'underline', color: 'white', mb: '20px' }}>
                  Role:
                </Typography>
                <Autocomplete
                  options={roles}
                  getOptionLabel={(option) => option.name || ''}
                  value={selectedRole}
                  onChange={(event, newValue) => setSelectedRole(newValue)} // Set the selected role
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" placeholder="Select role" sx={{ backgroundColor: 'white', borderRadius: 1 }} />
                  )}
                />
              </Grid2>
            </Grid2>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ background: 'linear-gradient(45deg, #2c6264, #5ac4cb)', borderRadius: '10px', margin: 'auto' }}
              onClick={handleSubmit}
              variant="contained"
            >
              Send Member
            </Button>
          </DialogActions>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
