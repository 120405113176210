import React, { useContext, useEffect, useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Grid2, IconButton, MenuItem, Select, Snackbar, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import axios from "axios";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadForOfflineIcon from '@mui/icons-material/ArrowDownward';
import { appContext } from "../../../../App";
import Swal from "sweetalert2";
import BigChips from "../Component/BigChips/Index";
import ActionsChips from "../Component/ActionsChips";
import AccesCompanyComponent from "./Component/AccesCompany";
import AccesPositionComponent from "./Component/AccesPosition";
import ApiContext from "../Context/context";
import CloseIcon from '@mui/icons-material/Close';
import { useSearch } from "../Context/SearchContext";

function AccessJopBar({
  setshowBackBtn,
}) {
  const {  employees, sentEnrollments, companies, selectedCompany, setSelectedCompany, positions, refetchData} = useContext(ApiContext);
    const { searchQuery } = useSearch();
  const [showPosition, setshowPostions] = useState(false);
  const [ShowUsers, setShowUsers] = useState(false);
  const [showCompany, setshowCompany] = useState(true);
  const [SelectedPositions, setSelectedPositions] = useState();
  const [showUser, setShowUser] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const isLoggedIn = useContext(appContext);
  useEffect(() => {
    if(!showCompany) {
      setshowBackBtn(false)
    } else {
      setshowBackBtn(true)
    }
    if(showUser) {
      setshowBackBtn(false)
    } else {
      setshowBackBtn(true)
    }
  }, [showCompany, setshowBackBtn, showUser]); 

  const handleBackButtonClick = () => {
    if (ShowUsers) {
      setShowUsers(false);  
      setshowPostions(true);
    } else if (showPosition) {
      setshowPostions(false); 
      setshowCompany(true);
    }
  };

  const handleShowUsers = () => {
    setShowUsers(true);
    setshowPostions(false);
    setshowCompany(false);
  };

    const handleRowSelect = (rowId) => {
      setSelectedRows((prev) =>
        prev.includes(rowId) ? prev?.filter((id) => id !== rowId) : [...prev, rowId]
      );
    };
  
    const handleSelectAll = () => {
      if (selectAll) {
        setSelectedRows([]);
      } else {
        const filteredEnrollments = sentEnrollments?.filter((enrol) =>
            employees.some(
              (emp) =>
                emp.company_id === selectedCompany.id &&
                emp.id === enrol.user_id &&
                enrol.exam_id === 65
            )
          )
          .map((enrol) => enrol.id); // Extract only the IDs
        
        setSelectedRows(filteredEnrollments); // Set only the filtered IDs
      }
      setSelectAll(!selectAll);

    };
    const downloadReports = async (ids) => {
      console.log(ids)
      try {
        const response = await axios.post(
          "https://vmi2353814.contaboserver.net/api/reports-download",
          { enrollment_ids: ids }
        );
        if (response) {
          window.open(response.data.payload.reports_zip_file, "_blank");
        }
      } catch (error) {
        console.error("Error downloading reports:", error);
      }
    };
  
    const handleDownloadAll = () => {
      const filteredEnrollments = sentEnrollments
        ?.filter((enrol) =>
          employees.some(
            (emp) =>
              emp.company_id === selectedCompany.id &&
              emp.id === enrol.user_id &&
              enrol.exam_id === 65 &&
              enrol.finished === 1
          )
        )
        .map((enrol) => (
          enrol.id
        ));
    
      downloadReports(filteredEnrollments);
    };  
    
      const hasRoleCreatePermission = !isLoggedIn?.userData?.roles?.[0]?.permissions?.some(
        (role) => role.name !== 'employee-show' 
      );
    
      useEffect(() => {
        if (isLoggedIn?.userData?.managed_by !== null ) {
          if(hasRoleCreatePermission){
          Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You do not have permission to access this page.',
            timer: 3000,
            showConfirmButton: true, 
            willClose: () => {
              // Refresh the window when the alert closes automatically
              window.location.reload();
            },
          });
        }}
      }, [hasRoleCreatePermission]);
  
      const finishedAssessments = sentEnrollments?.filter((enro) => {
        const employee = employees.find((emp) => emp.id === enro.user_id);
        return employee?.company_id === selectedCompany?.id && enro.finished == 1;
      }).length?? "0";
      
      const pendingAssessments = sentEnrollments?.filter((enro) => {
        const employee = employees.find((emp) => emp.id === enro.user_id);
        return employee?.company_id === selectedCompany?.id && enro.finished == 0;
      }).length?? "0";
      useEffect(() => {
        if (isLoggedIn?.userData?.managed_by !== null ) {
          if(hasRoleCreatePermission){
          Swal.fire({
            icon: 'error',
            title: 'Access Denied',
            text: 'You do not have permission to access this page.',
            timer: 3000,
            showConfirmButton: true, 
            willClose: () => {
              window.location.reload();
            },
          });
        }}
      }, [hasRoleCreatePermission]);

        const [selectLanguage, setSelectLanguage ] = useState(false);
        const [selectedValue, setSelectedValue] = useState('ar');
  const [loader, setLoader] = useState(false);
    const [openDownload, setopenDownload] = React.useState(false);
    const [msg, setmsg] = useState('');
    const [downloadDetails, setDownloadDetails ] = useState({});
  
    const handleClickOpen = () => {
      setopenDownload(true);
    };
  
    const handleCloseDownload = () => {
      setopenDownload(false);
      setSelectLanguage(false)
    };
    const username = () => {
      const user = employees.find((ele) => ele.id === downloadDetails.user_id);
      return user ? user.name?.replaceAll(" ", "-") : "User not found";
    };
      const reGenerateReports = async (id, exam_id) => {
        setLoader(true)
        try {
          const response = await axios.post(
            "https://vmi2353814.contaboserver.net/api/report-generate",
            {
              "url": `https://assessment.twindix.com/reportPage?lang=${selectedValue}&enro_id=${id}&exam_id=${exam_id}$name=${username()}`,
              "isLogged": true,
              "regenerate": true,
              "token": localStorage.token,
              "userType": "2",
              "enrollment_id":id
          }
          );
          if (response) {
            setmsg('report is Downloading')
            handleClick()
            setLoader(false)
            console.log(response.data.payload.reports_zip_file)
            window.open(response.data.payload.reports_zip_file, "_blank");
          }
        } catch ( response) {
          setmsg('report is generating')
          handleClick()
          setLoader(false)
        }
      };
        const [open, setOpen] = useState();
      
      const handleClick = () => {
        setOpen(true);
      };
    
      const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setOpen(false);
      };

      const filteredsentEnrollments = sentEnrollments?.filter((product) =>
        product.code?.toLowerCase().includes(searchQuery?.toLowerCase())
      );
  return (
    <Box>
      {ShowUsers && (
        <>
        <Button onClick={handleBackButtonClick} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back
      </Button>
            
          <BigChips finishedAssessments={`${finishedAssessments}`}
            pendingAssessments={`${pendingAssessments}`} userName={selectedCompany.name} />
          <ActionsChips
            benchmark={true}
            downloadAllBenchmarkReport={handleDownloadAll}
            downloadBenchmarkSelectedReport={() => downloadReports(selectedRows)}
          />
          {sentEnrollments.length > 0 ? 
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
            <TableCell width={20}>
            <Checkbox
            checked={selectAll}
            onChange={handleSelectAll}
            aria-label="Select all rows"
            />
            </TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Code</TableCell>
              <TableCell>Created At</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
        </Table>
        <Table>
          <TableBody>
          { filteredsentEnrollments
            ?.filter((enrol) => 
              employees.some((emp) =>emp.id === enrol.user_id && emp.company_id === selectedCompany?.id && emp.position_id === SelectedPositions && enrol.exam_id === 65)
            )
            .map((enrol) => (
                <TableRow key={enrol.id}>
                  <TableCell width={20}>
                    <Checkbox
                      checked={selectedRows.includes(enrol.id)}
                      onChange={() => handleRowSelect(enrol.id)}
                    />
                  </TableCell>
                  <TableCell sx={{width:'180px', color: "#5e5e5e", textDecoration: "underline" }}>
                  {employees.map((emp) => emp.id === enrol.user_id && emp.name)}
                  </TableCell>
                  <TableCell sx={{width:'180px', color: "#5e5e5e", textDecoration: "underline" }}>
                  {employees.map((emp) => emp.id === enrol.user_id && emp.email)}
                  </TableCell>
                  <TableCell sx={{width:'180px', color: "#5e5e5e", textDecoration: "underline" }}>
                  {enrol.code}
                  </TableCell>
                  <TableCell sx={{width:'180px', color: "#5e5e5e", textDecoration: "underline" }}>
                    {enrol.created_at.slice(0, 10)}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" spacing={2}>
                    {enrol.finished == 1 ?
                      <Button
                        variant="contained"
                        sx={{ background: "#2b6566" }}
                        onClick={() => {
                      if (enrol.finished === 1) {
                        setDownloadDetails(enrol);
                        handleClickOpen();
                      } 
                          }
                          }
                      >
                      <DownloadForOfflineIcon />
                        Download Report
                      </Button>
                    :
                      <Button
                        variant="contained"
                        sx={{ background: "#2b6566" }}>
                        No Report Yet
                      </Button>
                     }
                    </Stack>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer> :<Typography sx={{marginLeft: '350px'}}> No Reports Yet </Typography>}
        </>
      )}
      {showPosition ? (
        <>
        <Button onClick={handleBackButtonClick} sx={{ 
        position : 'absolute',
        top : '100px',
        left :'0'
      }}>
        <ArrowBackIcon />Back
      </Button>
        <AccesPositionComponent
        sentEnrollments={sentEnrollments}
        employees={employees}
          setShowUsers={handleShowUsers}
          setSelectedPositions={setSelectedPositions}
          selectedCompanyId={selectedCompany?.id}
          positions={positions}
          refetchData={refetchData}
        />
        </>
      ) : showCompany ? (
        <AccesCompanyComponent
          sentEnrollments={sentEnrollments}
          employees={employees}
          setshowPostions={setshowPostions}
          companies={companies}
          setSelectedCompany={setSelectedCompany}
        />
      ) : null}
      <Dialog
        maxWidth="lg"
        open={openDownload}
        onClose={handleCloseDownload}
      >
        <Box
          sx={{
            bgcolor: '#929292',
            width: '600px',
          }}
        >
        <IconButton onClick={handleCloseDownload}><CloseIcon/></IconButton>
          <DialogTitle>
            <Typography sx={{width: '50%', margin: 'auto', color: 'white', textDecoration: 'underline' }}>
            It may take 2-5 minutes to generate the report in the new language, and it will be set as the default report.
            </Typography>
          </DialogTitle>
          <DialogContent>
          {selectLanguage ? 
          <Grid2 container direction="column" spacing={4}>
          <Grid2 item xs={12}>
        <Typography sx={{ textDecoration: "underline", color: "white", mb: "20px" }}>
        language:
        </Typography>
        <Select
                  sx={{
            padding: "10px",
            fontSize: "10px",
            borderRadius: "4px",
            width: "100%",
            height: "40px",
            backgroundColor: "white",
          }}
          value={selectedValue}
          onChange={(e) => setSelectedValue(e.target.value)}
          displayEmpty
          >
      <MenuItem value="" disabled>
        Select Language
      </MenuItem>
      <MenuItem value="en">English</MenuItem>
      <MenuItem value="ar">Arabic</MenuItem>
    </Select>
      </Grid2>
    <Button
                    variant="contained"
                    style={{
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: '10xp'
                        }}
                        onClick={() => {
                          if (!downloadDetails) return; 
                          // window.open(`/reportPage?enro_id=${downloadDetails.id}&exam_id=${downloadDetails.exam_id}`);
                          // downloadReports([downloadDetails.id]);
                          
                          handleCloseDownload()
                          // localStorage.setItem("language", selectedValue);
                          reGenerateReports(downloadDetails.id, downloadDetails.exam_id);
                        }}
                  >
                    Regenerate Report With {selectedValue === 'ar' ? "Arabic" : 'English'} Language
                  </Button>
            </Grid2>
            :
            <Stack justifyContent='space-between' direction='row' width='100%' margin='auto'>
            <Button
                    variant="contained"
                    style={{
                      padding: '6px',
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: 'auto'
                        }}
                        onClick={() => {
                          if (!downloadDetails) return; 
                          
                          downloadReports([downloadDetails.id]);
                          handleCloseDownload()
                        }}
                  >
                    Download Current Report
                  </Button>
          <Button
                    variant="contained"
                    style={{
                          padding: '6px',
                          background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                          color: "white",
                          margin: 'auto'
                        }}
                        onClick={() => {setSelectLanguage(true)}}
                  >
                    Regenerate Another language
                  </Button>
            </Stack>
            }
          </DialogContent>
          <DialogActions> 
          <Button onClick={() => window.open(`/reportPage?lang=${selectedValue}&enro_id=${downloadDetails.id}&exam_id=${downloadDetails.exam_id}&name=${username()}`)}>open</Button>
          </DialogActions>
        </Box>
      </Dialog>
            <Snackbar
              open={open}
              autoHideDuration={4000}
              onClose={handleClose}
              message={msg}
            />
    </Box>
  );
}

export default AccessJopBar;
