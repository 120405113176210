import { Box, Button, Dialog, DialogContent, DialogTitle, Stack, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import AddPositionForm from "./Forms/AddPostionForm";
import { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { appContext } from "../../../../../App";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import axios from "axios";
import { useSearch } from "../../Context/SearchContext";


function AccesPositionComponent ({
  sentEnrollments, employees,
  setShowUsers, setSelectedPositions,
  selectedCompanyId, setSelectedCompanyId, positions,refetchData
    })  {
  const { searchQuery } = useSearch();
  const [openAddPosition, setOpenAddPosition] = useState(false); 
  const [openDialog, setOpenDialog] = useState(false);
  const [currentPosition, setCurrentPosition] = useState({
    id: "",
    name: "",
  });
  console.log(selectedCompanyId)
  let isLoggedIn = useContext(appContext);
    const handleEdit = async () => {
      if(selectedCompanyId !== undefined){
      try {
        await axios.put(
          `https://api.twindix.com/companies/${selectedCompanyId}/positions/${currentPosition.id}`,
          { name: currentPosition.name },
          {
            headers: {
              Authorization: `Bearer ${localStorage.token}`,
            },
          }
        );
        setOpenDialog(false);
        refetchData()
        setCurrentPosition({ id: "", name: "" });
      } catch (error) {
        console.error("Error saving position:", error);
      }
    }
    };

    const handleDelete = async (id) => {
      try {
        await axios.delete(`https://api.twindix.com/companies/${selectedCompanyId}/positions/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        });
        refetchData()
      } catch (error) {
        console.error("Error deleting position:", error);
      }
    };

    const hasActionPermission = isLoggedIn?.userData?.roles?.[0]?.permissions?.reduce(
      (acc, role) => {
        if (role.name === 'position-create') acc.positionCreate = true;
        if (role.name === 'position-update') acc.positionEdit = true;
        if (role.name === 'position-delete') acc.positiondelete = true;
        return acc;
      },
      { positionCreate: false, positionEdit: false, positiondelete: false }
    );
  
          const hasPermissioncreate = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.positionCreate) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };
          const hasPermissionupdate = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.positionEdit) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };
          const hasPermissiondelete = () => { 
            if (isLoggedIn?.userData?.managed_by !== null) {
              if (!hasActionPermission.positiondelete) {
                Swal.fire({
                  icon: 'error',
                  title: 'Access Denied',
                  text: 'You do not have permission to access this.',
                  timer: 3000,
                  showConfirmButton: true,
                });
              }
            }
          };
          const filteredcompanies = positions?.filter((product) =>
            product.title?.toLowerCase().includes(searchQuery?.toLowerCase())
          );
    return (
        <>
          <Button
              onClick={() => {
                hasPermissioncreate()
                if(isLoggedIn?.userData?.managed_by === null ||hasActionPermission.positionCreate){
                  setOpenAddPosition(true)
                }
                }}
              variant="contained"
              sx={{
                color: "white",
                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                marginTop: "16px",
              }}
            >
            <AddIcon />
              Add Position
            </Button>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Title</TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell>Participants</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
              {filteredcompanies
                ?.filter((position) => position.company_id === selectedCompanyId) 
                .map((position) => {
                  const companyEnrollments = sentEnrollments?.filter((enrollment) => {
                    const employee = employees.find((emp) => enrollment.exam_id === 65 && emp.id === enrollment.user_id && emp.position_id === position.id);
                    return employee 
                  });
                  const enrollmentCount = companyEnrollments?.length;
                  return (
                      <TableRow key={position.id}>
                        <TableCell>
                          <Typography
                            onClick={() => {
                              setSelectedPositions(position.id)
                              setShowUsers(true)
                              }}
                            sx={{
                              width: '200px',
                              cursor: "pointer",
                              color: "#2b6566",
                              textDecoration: "underline",
                            }}
                          >
                            {position.title}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Stack direction="row" spacing={1}>
                            <Button
                              variant="contained"
                              onClick={() => {
                                hasPermissionupdate();
                                if (isLoggedIn?.userData?.managed_by === null ||hasActionPermission.positionEdit) {
                                  setCurrentPosition(position);
                                  setOpenDialog(true);
                                }
                              }}
                              sx={{
                                borderRadius: "10px",
                                color: "white",
                                background: "linear-gradient(45deg, #2c6264, #5ac4cb)",
                              }}
                            >
                              Edit
                            </Button>
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                hasPermissiondelete();
                                if (isLoggedIn?.userData?.managed_by === null ||hasActionPermission.positiondelete) {
                                  handleDelete(position.id);
                                }
                              }}
                              sx={{
                                borderRadius: "10px",
                                color: "white",
                              }}
                            >
                              <DeleteIcon />
                            </Button>
                          </Stack>
                        </TableCell>
                        <TableCell>
                          <Typography
                            sx={{
                              margin: "4px",
                              color: "#3f8e8f",
                              border: "2.5px solid #3f8e8f",
                              borderRadius: "12px",
                              padding: "12px 6px",
                              fontWeight: "bold",
                              width : '130px',
                              textAlign : "center"
                            }}
                          >
                            {enrollmentCount}{" "}
                            {enrollmentCount === 1 ? "Assessment" : "Assessment"}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
            <AddPositionForm
            open={openAddPosition}
            setOpen={setOpenAddPosition}
            companyId={selectedCompanyId}
            setSelectedCompanyId={setSelectedCompanyId}
            refetchData={refetchData}
          />
          <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
            <Box sx={{background: '#929292', padding: '12px'}}>
              <DialogTitle>
              <Typography sx={{color: 'white'}}>
              Edit Position
              </Typography>
              </DialogTitle>
              <DialogContent>
                <TextField
                  label="Position Name"
                  value={currentPosition.name}
                  onChange={(e) =>
                    setCurrentPosition({ ...currentPosition, name: e.target.value })
                  }
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <Stack justifyContent='center' alignItems='center' direction='center'>
                <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
                <Button
                  onClick={handleEdit}
                  variant="contained"
                  color="primary"
                >
                  Save
                </Button>
              </Stack>
            </Box>
          </Dialog>
          </>
    )
}

export default AccesPositionComponent