import { Chip, Stack } from "@mui/material"
import DownloadForOfflineIcon from '@mui/icons-material/ArrowDownward';
function ActionsChips ({benchmark, ind, downloadAllBenchmarkReport
    ,downloadBenchmarkSelectedReport
    ,downloadAllIngReport
    ,downloadIngSelectedReport}) {
return (
    <Stack mt={4} mb={4} direction='row' justifyContent='left' alignItems='center'>
    {benchmark && 
    <>
    <div style={{ cursor: 'pointer'}} onClick={() => downloadAllBenchmarkReport()}><Chip sx={{ mr: 2, color : 'white',backgroundColor : "#3f8d90", padding : '20px'}} variant="filled" label='Download All Reports'  icon={<DownloadForOfflineIcon />} /></div>
    <div style={{cursor: 'pointer'}} onClick={() => downloadBenchmarkSelectedReport()}><Chip sx={{ mr: 2, color : 'white',backgroundColor : "#3f8d90", padding : '20px'}} variant="filled" label='Download Selected Reports' icon={<DownloadForOfflineIcon />} /></div>
    </>
    }
    {ind && 
    <>
    <div style={{cursor: 'pointer'}} onClick={() => downloadAllIngReport()}> <Chip sx={{ mr: 2, color : 'white',backgroundColor : "#3f8d90", padding : '20px'}} variant="filled" label='Download All Reports' icon={<DownloadForOfflineIcon />} /></div>
    <div style={{cursor: 'pointer'}} onClick={() => downloadIngSelectedReport()}><Chip sx={{ mr: 2, color : 'white',backgroundColor : "#3f8d90", padding : '20px'}} variant="filled" label='Download Selected Reports' icon={<DownloadForOfflineIcon />} /></div>
</>
    }

    </Stack>
)
}

export default ActionsChips