import { useContext, useEffect, useState } from "react"
import axios from "axios"
import Badel from "../../Media/imges/WhatsApp Image 2024-01-09 at 12.28.12_84c2f948.jpg"
import "./Payment.css"
import { appContext } from "../../App";
function PaymentCom () {
    const [exams, setExams] = useState(null);
    const [purchaseExam, setPurchaseExam] = useState(null);
    function buyExam(examId) {

        axios.post("https://api.twindix.com/payment/exam", {exam_id : examId} ,{headers: {
            Authorization: "Bearer " + localStorage.token
        }})
        .then((res) => {
            const transactionId = res?.data?.payload?.transaction_id;
            localStorage.setItem('transaction_id', transactionId ?? 'no exam transaction Id');
            localStorage.setItem('exam_Id', examId);
            setPurchaseExam("Redirecting to complete payment in 5 seconds");
            setTimeout(() => {
              window.location.replace(res.data.payload.redirect);
            }, 3000);
          })

    }
    useEffect(() => {
        if(exams == null) {
            axios.get("https://api.twindix.com/exams",{headers:{
                Authorization: "Bearer " + localStorage.token
            }, params:{
            }}).then(res => {
                console.log(res.data.payload.data);
                setExams(res.data.payload.data);
            })

        }
    }, [exams])

    return (
        <>
                {purchaseExam && <div className="alert alert-success position-absolute top-0 m-3">{purchaseExam}</div>}
            <div className="PayRightSection" >
                <div className="PaymentContaner">
                        <div className="exams">
                        {exams ? exams.map((exam, index) => {
    return (
            <div
                key={exam.id} 
                className="ExamContaner exam"
                style={index % 2 === 0 ? { flexDirection: "row", marginBottom:'10px' } : { flexDirection: "row-reverse", marginBottom:'10px' }}
            >
                <div>
                    <img
                        className="ExamImage"
                        src={exam.cover_image ? exam.cover_image : Badel}
                        alt="exam cover"
                    />
                </div>
                <div>
                    <h2 className="ExamTitle">{exam.title}</h2>
                    <h2 className="Examdescription">
                        {window.innerWidth < 750 ? exam.description.slice(0, 270) : exam.description.slice(0, 370)}
                        {window.innerWidth < 750 ? "..." : ""}
                    </h2>
                    <div className="PriceAndBtncon">
                        <div className="PriceAndBtn">
                            <span>{exam.price} $</span>
                            <div style={{margin :'auto'}}>
                                <button className="ExamBtn" onClick={() => buyExam(exam.id)}>
                                    Buy The Assessments
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> // Use null instead of empty string for React components
    );
}) : null}
                        </div>
                </div>
        </div>
        </>
    )
}

export default PaymentCom